import { pushNotification, Timeline, TimelineDetail, utils } from '@truenorthmortgage/olympus';
import { FunderService } from '../../services/funder.service';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SERVICES } from '../../services';
import ActionHeaderHelper from '../../components/action-header-helper/action-header-helper.component';
import TableHelper from '../../components/table-helper/table-helper.component';
import CorrespondenceItem from '../../components/correspondence-item/correspondence-item.component';
import { DealService } from '../../services/deal.service';
import { CorrespondenceItemData } from '../../models/deals/notes';
import { TableSchema } from '../../models/schemas/table-schema';
import { ActionHeaderSchema } from '../../models/schemas/action-header';
import { HistoryTimeline } from '../../models/deals/history';
import ComponentHelper from '../../components/component-helper/component-helper.component';
import { useDispatch } from 'react-redux';
import { CheckboxComponent } from '../../models/schemas/form-schema';
import { HttpService } from '../../services/http.service';
import { switchMap, tap, timer } from 'rxjs';

const FunderOverview = () => {
    const funderService = utils.injection.useInjection<FunderService>(SERVICES.FunderService);
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const [data, setData] = useState<[ActionHeaderSchema, TableSchema, HistoryTimeline] | null>(null);
    const dispatch = useDispatch();

    const loadData = useCallback(() => {
        return funderService.getFunderOverview().pipe(
            tap({
                next: setData,
                error: (e) => {
                    dispatch(
                        pushNotification({ class: 'error', message: e.toString() })
                    );
                }
            }));
    }, [setData]);

    const reloadData = useCallback((data?: {submit: boolean}) => {
        if (data?.submit) {
            HttpService.subscribe(loadData(), dispatch);
        }
    }, [loadData]);

    useEffect(() => {
        const subscription = HttpService.subscribe(timer(0, 60000).pipe(switchMap(loadData)), dispatch);

        return () => {
            subscription.unsubscribe();
        };
    }, [loadData, dispatch]);

    const timelineSchema = useMemo(() =>
        data ? data.filter(schema => schema !== null).find((schema) => schema.id === 'correspondence_history') as HistoryTimeline : null
    , [data]);
    const noTaskSchema = useMemo(() =>
        data ? data.filter(schema => schema !== null).find((schema) => schema.id === 'no_tasks') as ActionHeaderSchema : null
    , [data]);
    const fundingQuestionsSchema = useMemo(() =>
        data ? data.filter(schema => schema !== null).find((schema) => schema.id === 'funding_questions') as TableSchema : null
    , [data]);
    const tasksSchema = useMemo(() =>
        data ? data.filter(schema => schema !== null).find((schema) => schema.id === 'task') as TableSchema : null
    , [data]);
    const taskHistorySchema = useMemo(() =>
        data ? data.filter(schema => schema !== null).find((schema) => schema.id === 'task_history') as TableSchema : null
    , [data]);
    const reInstructNotes = useMemo(() =>
        data ? data.filter(schema => schema !== null).find((schema) => schema.id === 're_instruct_notes') as TableSchema : null
    , [data]);

    const onApprovedCheckboxChange = useCallback((checkbox: CheckboxComponent) => (value: string | null) => {
        if ((checkbox.value === null && value !== null) || (typeof checkbox.value !== 'undefined' && checkbox.value !== null && checkbox.value.toString() !== value)) {
            funderService.setDocumentApproved(checkbox.options.Approve, value !== null).subscribe({
                error: (e) => {
                    dispatch(
                        pushNotification({ class: 'error', message: e.toString() })
                    );
                }
            });
        }
    }, [funderService]);

    const documentsSchema = useMemo(() => {
        if (data) {
            const schema = data.filter(schema => schema !== null).find((schema) => schema.id === 'documents') as TableSchema | null;
            if (schema) {
                schema.rows.forEach(row => {
                    row.cells = row.cells.map(cell => {
                        if (cell.type === 'component' && cell.component.type === 'checkbox') {
                            return {
                                type: 'custom-jsx',
                                element: (
                                    <ComponentHelper schema={cell.component} onChange={onApprovedCheckboxChange(cell.component)} />
                                ),
                                className: cell.classes?.join(' '),
                                colspan: cell.colspan
                            };
                        }
                        return cell;
                    });
                });
            }
            return schema;
        }
        return null;
    }, [data, onApprovedCheckboxChange]);

    const itemButtons = useMemo(() => timelineSchema ? dealService.getTimelineItemButtons(timelineSchema, reloadData) : null, [dealService, timelineSchema]);

    return data ? (
        <>
            <ActionHeaderHelper schema={data[0]} />
            {noTaskSchema ? <ActionHeaderHelper schema={noTaskSchema} /> : null}
            {tasksSchema ? <TableHelper schema={tasksSchema} includeHeader={true} className={tasksSchema.border ? 'bordered' : ''} onModalClose={reloadData} /> : null}
            {reInstructNotes ? <TableHelper schema={reInstructNotes} includeHeader={true} className={reInstructNotes.border ? 'bordered' : ''} /> : null}
            {fundingQuestionsSchema ? <TableHelper schema={fundingQuestionsSchema} includeHeader={true} className={fundingQuestionsSchema.border ? 'bordered' : ''} /> : null}
            {documentsSchema ? <TableHelper schema={documentsSchema} includeHeader={true} className={documentsSchema.border ? 'bordered' : ''} /> : null}
            {timelineSchema ? (
                <Timeline title={timelineSchema.title} categoryLabel={timelineSchema.category_label} bordered={timelineSchema.settings.border}>
                    {timelineSchema.timeline_items.map((item: CorrespondenceItemData, index: number) => (
                        <CorrespondenceItem
                            key={index}
                            correspondenceHeading={item.correspondence_heading}
                            correspondenceHeadingClasses={item.correspondence_heading_classes}
                            actionButtons={itemButtons![index]}
                            category={item.category}
                            dateTime={new Date(item.datetime)}
                            icon={item.icon}
                            headline={item.headline}
                            expanded={item.details.expanded}>
                            <TimelineDetail copy={item.details.copy} raw={true} />
                        </CorrespondenceItem>
                    ))}
                </Timeline>
            ) : null}
            {taskHistorySchema ? <TableHelper schema={taskHistorySchema} includeHeader={true} className={taskHistorySchema.border ? 'bordered' : ''} /> : null}
        </>
    ) : null;
};

export default FunderOverview;
