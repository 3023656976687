import { TextareaComponent, BasicModalHandle, utils, pushNotification, Widget } from '@truenorthmortgage/olympus';
import { FC, RefObject, useCallback,  useState, useMemo } from 'react';
import { FormSchema } from '../models/schemas/form-schema';
import { FormattedMessage, useIntl } from 'react-intl';
import { SERVICES } from '../services';
import { CesService } from '../services/ces.service';
import { useDispatch } from 'react-redux';
import FormHelper from '../components/form-helper/form-helper.component';

export interface CesAgentFormProps {
    schema: FormSchema;
    onSubmit: (data: any) => void;
    parentRef?: RefObject<BasicModalHandle>;
}

const CesAgentNotes: FC<CesAgentFormProps> = ({ schema, onSubmit, parentRef }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [note, setNote] = useState<string | null>(null);
    const cesService = utils.injection.useInjection<CesService>(SERVICES.CesService);

    const submitSavedNote = useCallback(() => {
        if (!note) {
            dispatch(pushNotification({ class: 'error', message: 'CES Notes cannot be blank.' }));
        }

        cesService.saveNotes(schema.action, note).then(() => {
            dispatch(pushNotification({ class: 'success', message: intl.formatMessage({id: 'Notes added successfully.'}) }));
            parentRef?.current?.close();
        });
    }, [cesService, note]);

    const updatedComponents = useMemo(() => {
        return Object.assign({}, schema, {
            components: schema.components.map(component => {
                if (component.type === 'buttons') {
                    return {
                        type: 'react',
                        element: (
                            <div className="column buttons">
                                <div className="buttons">
                                    <button className="button cancel" onClick={() => parentRef?.current?.close()}>
                                        <FormattedMessage id="Cancel" />
                                    </button>
                                    <button className="button primary right form-trigger" onClick={submitSavedNote}>
                                        <FormattedMessage id="Save" />
                                    </button>
                                </div>
                            </div>
                        )
                    };
                } else if (component.type === 'textarea') {
                    return {
                        type: 'react',
                        element: <TextareaComponent label={intl.formatMessage({ id: 'Notes' })} value={note || ''} onChange={setNote} />
                    };
                }
                return component;
            })
        });
    }, [schema, note]);

    return updatedComponents 
        ? <FormHelper schema={updatedComponents} onSubmit={onSubmit} parentRef={parentRef} /> 
        : null;
};

export default CesAgentNotes;
