export const SERVICES = {
    AuthService: 'AuthService',
    ActionService: 'ActionService',
    DealService: 'DealService',
    CesService: 'CesService',
    ReportService: 'ReportService',
    ComponentService: 'ComponentService',
    LoginService: 'LoginService',
    CommitmentService: 'CommitmentService',
    UnderwritingService: 'UnderwritingService',
    DocumentSpecialistService: 'DocumentSpecialistService',
    CloserDealsService: 'CloserDealsService',
    FunderService: 'FunderService',
    AdminService: 'AdminService',
    UsersService: 'UsersService',
    SubmitToThinkService: 'SubmitToThinkService',
    ModalService: 'ModalService',
    NotificationService: 'NotificationService',
    HttpService: 'HttpService',
    VerbalerService: 'VerbalerService'
};
