import { BasicModalHandle, Column, Form, utils, Widget } from '@truenorthmortgage/olympus';
import { FC, RefObject, useMemo } from 'react';
import FormModal from '../../modals/form-modal.component';
import { ContainerItem, ContainerSchema } from '../../models/schemas/container-schema';
import ComponentHelper from '../component-helper/component-helper.component';
import TableHelper from '../table-helper/table-helper.component';

export interface ContainerModalProps {
    data?: ContainerSchema,
    parentRef?: RefObject<BasicModalHandle>,
}

function renderItem(schema: ContainerItem, parentRef?: RefObject<BasicModalHandle>, key?: number): JSX.Element | null {
    if (typeof schema === 'string') {
        return (<div key={key} dangerouslySetInnerHTML={utils.html.doSanitize(schema)} />);
    } else if ('__blob_data' in schema) {
        setTimeout(() => {
            parentRef?.current?.setModalBoxStyle('max-size');
        });
        return (<div key={key} className="modal-document"><iframe src={(schema as any).__blob_data} /></div>);
    } else if ('form_id' in schema) {
        return (<FormModal key={key} data={schema} parentRef={parentRef} />);
    } else if ('rows' in schema) {
        return (<TableHelper key={key} schema={schema} includeHeader={true} />);
    } else if ('type' in schema && schema.type === 'react') {
        return schema.element;
    } else if ('type' in schema) {
        return (
            <ComponentHelper key={key} schema={schema} />
        );
    } else if ('content' in schema) {
        return (
            <Widget key={key}>
                <Form>
                    <Column>
                        <div dangerouslySetInnerHTML={utils.html.doSanitize(schema.content)} />
                    </Column>
                </Form>
            </Widget>
        );
    }
    return null;
}

const ContainerModal: FC<ContainerModalProps> = ({ data, parentRef }) => {
    const elements = useMemo(() => {
        if (Array.isArray(data)) {
            return (
                <>
                    {data.map((s, k) => renderItem(s, parentRef, k))}
                </>
            );
        }
        return data ? renderItem(data, parentRef) : null;
    }, [data, parentRef]);

    return elements;
};

export default ContainerModal;
