import { BasicModalHandle, pushNotification, utils } from '@truenorthmortgage/olympus';
import { FC, RefObject, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import FormHelper from '../components/form-helper/form-helper.component';
import { DealUpgradePrice } from '../models/deals/deal';
import { FormSchema } from '../models/schemas/form-schema';
import { SERVICES } from '../services';
import { DealService } from '../services/deal.service';
import dayjs from 'dayjs';

export interface UpdatePremierCloserFormProps {
    schema: FormSchema | [{ content: string }, FormSchema];
    parentRef?: RefObject<BasicModalHandle>;
    onSubmit: (data: any) => void;
}

const UpdatePremierCloserForm: FC<UpdatePremierCloserFormProps> = ({ schema, onSubmit, parentRef }) => {
    const [price, setPrice] = useState<DealUpgradePrice | null>(null);
    const [remainder, setRemainder] = useState<number | null>(null);
    const [handle, setHandle] = useState<NodeJS.Timer | undefined>();
    const [enabled, setEnabled] = useState(false);
    const intl = useIntl();
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const dispatch = useDispatch();

    const [html, data] = useMemo(() => {
        const [html, data] = Array.isArray(schema) ? [schema[0].content, schema[1]] : [null, schema];
        for (const component of data.components) {
            if (component.type === 'buttons') {
                for (const button of component.buttons) {
                    if (button.class?.includes('form-trigger')) {
                        button.disabled = !enabled;
                        if (enabled && button.class.includes('disabled')) {
                            button.class = button.class.replace('disabled', '');
                        } else if (!enabled && !button.class.includes('disabled')) {
                            button.class += ' disabled';
                        }
                    }
                }
            }
        }
        return [html, Object.assign({}, data)];
    }, [schema, enabled]);

    const notUpgradeable = useMemo(() => {
        return data.components.length > 2
            && data.components[1].type === 'hidden'
            && data.components[1].name === 'not_upgradeable';
    }, [data]);

    useEffect(() => {
        const dealId = (data.components[0] as { value: string }).value;
        if (!notUpgradeable) {
            dealService.calculateCloserUpgradePrice(dealId)
                .then(price => {
                    const end = dayjs().add(price.timer_in_seconds, 'seconds');
                    setPrice(price);
                    setRemainder(price.timer_in_seconds);
                    setEnabled(true);

                    const newHandle = setInterval(() => {
                        const newRemainder = end.diff(new Date(), 'seconds');
                        if (newRemainder === 0) {
                            clearInterval(newHandle);
                            parentRef?.current?.close();
                            dispatch(pushNotification({ 
                                class: 'error', 
                                message: intl.formatMessage({id: 'Out of time to submit at the current Premier price, \'Upgrade\' again for a new price.'})
                            }));
                        }
                        setRemainder(newRemainder);
                    }, 1000);

                    setHandle(handle => {
                        if (handle) {
                            clearInterval(handle);
                        }
                        return newHandle;
                    });
                })
                .catch(e => {
                    dispatch(pushNotification({ class: 'error', message: e.toString() }));
                });

            return function cleanup() {
                clearInterval(handle);
            };
        }
    }, [data, parentRef, setPrice, setRemainder, setHandle, setEnabled]);

    const timer = useMemo(() => {
        if (price && remainder) {
            const totalMinutes = Math.round(price.timer_in_seconds / 60);
            const minutes = Math.floor(remainder % (60 * 60) / 60);
            const seconds = Math.floor(remainder % 60);

            return { minutes: totalMinutes, timer: `${minutes}m ${seconds}s` };
        }
        return null;
    }, [price, remainder]);

    return (
        <>
            {html ? <div><p dangerouslySetInnerHTML={utils.html.doSanitize(html)} /> </div>: null}
            {price && timer ? (<>
                <div><p><FormattedMessage id={'Upgrade price:'} /> {`$${price.price}`}</p></div>
                <div dangerouslySetInnerHTML={utils.html.doSanitize(price.content)} />
                <div>
                    <p>
                        <FormattedMessage
                            id={'(Upgrade price is valid for {minutes} minutes: {timer} remaining)'}
                            values={timer} />
                    </p>
                </div>
            </>) : (!notUpgradeable ? (
                <div><p><FormattedMessage id={'Calculating price upgrade...'} /></p></div>
            ) : null)}
            <FormHelper schema={data} onSubmit={onSubmit} parentRef={parentRef} />
        </>
    );
};

export default UpdatePremierCloserForm;
