import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BasicModalHandle, CheckboxComponent, CheckboxOption, CheckboxSelectComponent, CKEditorComponent, Column, Container, DateComponent, FieldGroupComponent, FileComponent, FileComponentValue, ModalActionButton, MoneyComponent, MultiSelectComponent, NoteComponent, RadioComponent, RadioOption, RateComponent, SelectComponent, TextareaComponent, TextComponent, utils } from '@truenorthmortgage/olympus';
import { CSSProperties, Dispatch, FC, RefObject, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Observable } from 'rxjs';
import { ComponentSchema, FormSchema } from '../../models/schemas/form-schema';
import { SERVICES } from '../../services';
import { ComponentService } from '../../services/component.service';
import { HttpService } from '../../services/http.service';
import ContainerModal from '../container-modal/container-modal.component';
import { ChangeHandler, OnChangeMap } from '../form-helper/form-helper.component';
import AccordionHelper from '../accordion-helper/accordion-helper.component';

export interface ComponentHelperProps {
    schema: ComponentSchema;
    onChange?: ChangeHandler;
    onChangeMap?: OnChangeMap;
    onSubmit?: (event?: any) => Observable<any>;
    parentRef?: RefObject<BasicModalHandle>;
    triggerOnLoad?: boolean;
    hasErrors?: boolean;
}

function getOnChange(
    component: ComponentSchema,
    onChange?: ChangeHandler,
    onChangeMap?: OnChangeMap) {
    if (onChange) {
        return onChange as (s: string | number | undefined | null) => void;
    } else if (onChangeMap && 'name' in component && component.name) {
        return onChangeMap[component.name] as (s: string | number | undefined | null) => void;
    }
    return undefined;
}

function getOnChangeStringArray(
    component: ComponentSchema,
    onChange?: ChangeHandler,
    onChangeMap?: OnChangeMap) {
    if (onChange) {
        return onChange  as (s: string[]) => void;
    } else if (onChangeMap && 'name' in component && component.name) {
        return onChangeMap[component.name] as (s: string[]) => void;
    }
    return undefined;
}

function getOnChangeMap(onChangeMap?: OnChangeMap): Record<string, (s: string | boolean | null) => void> {
    return onChangeMap as Record<string, (s: string | boolean | null) => void>;
}

function getOnChangeFile(
    component: ComponentSchema,
    onChange?: ChangeHandler,
    onChangeMap?: OnChangeMap) {
    if (onChange) {
        return onChange  as (f: FileComponentValue) => void;
    } else if (onChangeMap && 'name' in component && component.name) {
        return onChangeMap[component.name] as (f: FileComponentValue) => void;
    }
    return undefined;
}


function renderComponent(
    component: ComponentSchema,
    componentCallback: (value: string) => void,
    dispatch: Dispatch<any>,
    hasErrors: boolean,
    httpService: HttpService,
    componentService: ComponentService,
    onChange?: ChangeHandler,
    onChangeMap?: OnChangeMap,
    onSubmit?: (event?: any) => Observable<any>,
    parentRef?: RefObject<BasicModalHandle>,
    triggerOnLoad?: boolean) {

    if (component.type === 'render') {
        return component.renderComponent(hasErrors);
    } else if (component.type === 'react') {
        return component.element;
    } else if (component.type === 'select') {
        const { value, options, callback, disabled, label, style, type, onBlur } = component;
        return (
            <SelectComponent
                triggerOnLoad={triggerOnLoad}
                columnStyle={`${style ?? ''} ${type}`}
                onChange={getOnChange(component, onChange, onChangeMap) ?? (callback ? componentCallback : undefined)}
                onBlur={onBlur}
                defaultValue={value ?? ''}
                value={value ?? undefined}
                disabled={disabled}
                label={label}
                error={hasErrors}
                options={options}>
            </SelectComponent>
        );
    } else if (component.type === 'multi-select') {
        const { value, multiple, tags, options, disabled, label, style, type } = component;
        // add value as a choosable option if it is not there
        if (Array.isArray(value)) {
            value.map(item => {
                if (!options.includes(item)) {
                    options.push(item);
                }
            });
        } else if (value !== '') {
            if (!options.includes(value)) {
                options.push(value);
            }
        }

        return (
            <MultiSelectComponent
                columnStyle={`${style ?? ''} ${type}`}
                onChange={getOnChangeStringArray(component, onChange, onChangeMap)}
                defaultSelected={value}
                multiple={multiple}
                tags={tags}
                disabled={disabled}
                label={label}
                error={hasErrors}
                options={options} />
        );
    } else if (component.type === 'image') {
        return (
            <div className='column'>
                <label>{component.label}</label>
                <img className='max-width' src={'data:image/jpeg;base64,' + component.value} />
            </div>
        );
    } else if (component.type === 'radio') {
        const { label, value, name, style, type, options } = component;
        return (
            <RadioComponent label={label} name={name} value={value ?? undefined} columnStyle={`${style ?? ''} ${type}`} onChange={getOnChange(component, onChange, onChangeMap)}>
                {options.map((option, index) => (
                    <RadioOption key={index} value={option.value} label={option.label} error={hasErrors} />
                ))}
            </RadioComponent>
        );
    } else if (component.type === 'checkbox') {
        const { label, value, name, style, type, options } = component;
        return (
            <CheckboxComponent label={label} name={name} value={value ? value.toString() : undefined} columnStyle={`${style ?? ''} ${type}`} onChange={getOnChange(component, onChange, onChangeMap)}>
                {Object.keys(options).map((key: string) =>
                    <CheckboxOption key={key} label={key} value={options[key]} error={hasErrors} />
                )}
            </CheckboxComponent>
        );
    } else if (component.type === 'multi-selection-checkbox') {
        const { label, value, style, type, options } = component;
        return (
            <CheckboxSelectComponent label={label} values={value ?? undefined} columnStyle={`${style ?? ''} ${type}`} onChange={getOnChangeStringArray(component, onChange, onChangeMap)}>
                {Object.keys(options).map((key: string) =>
                    <CheckboxOption key={key} label={key} value={options[key]} />
                )}
            </CheckboxSelectComponent>
        );
    } else if (component.type === 'textarea') {
        return (
            <>
                <TextareaComponent
                    error={hasErrors}
                    triggerOnLoad={triggerOnLoad}
                    columnStyle={`${component.style ?? ''} ${component.type} ${component.classes ?? ''}`}
                    defaultValue={component.value ?? ''}
                    value={component.value ?? ''}
                    label={component.label}
                    onChange={getOnChange(component, onChange, onChangeMap)}
                    disabled={component.disabled}
                    onBlur={component.onBlur} />

                { component.footnote ?
                    <div className="column">
                        <p className="mice">{component.footnote}</p>
                    </div>
                    : null }
            </>
        );
    } else if (component.type === 'file') {
        return (
            <FileComponent
                name={component.name}
                error={hasErrors}
                onChange={getOnChangeFile(component, onChange, onChangeMap)}
                label={component.label}
                placeholder={component.placeholder}
                multiple={component.multiple}
                disabled={component.disabled} />
        );
    } else if (component.type === 'text') {
        return (
            <TextComponent
                error={hasErrors}
                triggerOnLoad={triggerOnLoad}
                columnStyle={`${component.style ?? ''} ${component.type}`}
                autoComplete={component.autocomplete}
                disabled={component.disabled}
                label={component.label}
                defaultValue={component.value ?? ''}
                readOnly={component.readonly}
                placeholder={component.placeholder}
                onChange={getOnChange(component, onChange, onChangeMap)}
                onBlur={component.onBlur}
                name={component.name} />
        );
    } else if (component.type === 'date') {
        return (
            <DateComponent error={hasErrors} label={component.label} name={component.name} minDate={component.min_date ? new Date(component.min_date) : undefined} maxDate={component.max_date ? new Date(component.max_date) : undefined} dateFormat={ component.date_format } value={component.value ?? undefined} columnStyle={`${component.style ?? ''} ${component.type}`} onChange={getOnChange(component, onChange, onChangeMap)} disabled={component.disabled} />
        );
    } else if (component.type === 'note') {
        return (
            <NoteComponent label={component.label} value={component.value ?? ''} columnStyle={`${component.style ?? ''} ${component.type}`} />
        );
    } else if (component.type === 'field-group') {
        return (
            <FieldGroupComponent columnStyle={`${component.style ?? ''} ${component.type}`}>
                {component.components.map((component, index) => (
                    <ComponentHelper key={index} schema={component} onChange={onChange} onChangeMap={onChangeMap} onSubmit={onSubmit} hasErrors={hasErrors} />
                ))}
            </FieldGroupComponent>
        );
    } else if (component.type === 'ckEditor') {
        return (
            <CKEditorComponent
                error={hasErrors}
                value={component.value}
                triggerOnLoad={triggerOnLoad}
                columnStyle={`${(component.classes ?? []).join(' ')} ${component.style ?? ''} ${component.type}`}
                disabled={component.disabled}
                label={component.label}
                defaultValue={component.value ?? ''}
                onChange={getOnChange(component, onChange, onChangeMap)}
            />
        );
    } else if (component.type === 'buttons') {
        let timer: NodeJS.Timeout;
        let throttle = false;
        return (
            <Column columnStyle={`${component.style ?? ''} ${component.type}`}>
                {component.buttons.map((button, btnIndex) => {
                    if (onSubmit && (button.class?.includes('form-trigger') || button.classes?.includes('form-trigger'))) {
                        return <button
                            key={btnIndex}
                            className={`button ${button.classes?.join(' ') ?? button.class}`}
                            onClick={e => {
                                clearTimeout(timer);

                                timer = setTimeout(async () => {
                                    HttpService.subscribe(onSubmit(e), dispatch);
                                }, 200);
                            }} disabled={button.disabled}>
                            {button.label}
                        </button>;
                    } else if (button.modal) {
                        return <ModalActionButton
                            key={btnIndex}
                            text={button.label}
                            icon={button.icon}
                            style={button.style as CSSProperties}
                            modalArgs={{
                                modalHeader: button.modal_title,
                                loadProps: async () => ({
                                    data: await httpService.fetchJson<FormSchema>(button.uri!)
                                })
                            }}
                            className={button.classes?.join(' ')}>
                            <ContainerModal />
                        </ModalActionButton>;
                    } else {
                        const callback = () => {
                            if (!button.callback && button.classes?.includes('link-trigger')) {
                                componentService.executeLinkTriggerClick(button.data_array?.uri ?? '', dispatch, onSubmit);
                            } else if (button.callback) {
                                if (button.new_window) {
                                    HttpService.subscribe(button.callback(onSubmit), dispatch, (data) => window.open(data.uri, '_blank'));
                                } else {
                                    HttpService.subscribe(button.callback(onSubmit), dispatch, () => parentRef?.current?.close(button.modalCloseData));
                                }
                            } else {
                                parentRef?.current?.close(button.modalCloseData);
                            }
                        };
                        return <a key={btnIndex} className={`button ${button.classes?.join(' ') ?? button.class}`} {...button.uri ? {href: button.uri} : {}} onClick={() => {
                            // Add throttle here so that only the first request will be executed disregard how many times the button is clicked within 3 seconds
                            if (!throttle) {
                                callback();
                                throttle = true;

                                setTimeout(async () => {
                                    throttle = false;
                                }, 3000);
                            }
                        }}>
                            {button.icon ? <i><FontAwesomeIcon icon={button.icon as IconProp}/></i> : ''}
                            {' ' + button.label}
                        </a>;
                    }
                })}
            </Column>
        );
    } else if (component.type === 'container') {
        return <Container className={`${component.class} ${component.margin ? '' : 'no-margin'}`}>
            <div dangerouslySetInnerHTML={{__html: component.content}} />
        </Container>;

    } else if (component.type === 'money') {
        return <MoneyComponent
            value={component.value}
            name={component.name}
            label={component.label}
            columnStyle={`${component.style ?? ''} ${component.type}`}
            placeholder={component.placeholder}
            decimalScale={component.decimal_scale}
            triggerOnLoad={triggerOnLoad}
            error={hasErrors}
            autoFocus={component.auto_focus}
            useParentheses={component.use_parentheses}
            padFractionalZeros={component.pad_fractional_zeros}
            allowNegatives={component.allow_negatives}
            disabled={component.disabled}
            forceNegatives={component.force_negatives}
            id={component.id}
            onChange={getOnChange(component, onChange, onChangeMap)}
        />;
    } else if (component.type === 'rate') {
        return <RateComponent
            value={component.value}
            name={component.name}
            label={component.label}
            columnStyle={`${component.style ?? ''} ${component.type}`}
            placeholder={component.placeholder}
            decimalScale={component.decimal_scale}
            triggerOnLoad={triggerOnLoad}
            error={hasErrors}
            autoFocus={component.auto_focus}
            padFractionalZeros={component.pad_fractional_zeros}
            allowNegatives={component.allow_negatives}
            disabled={component.disabled}
            id={component.id}
            onChange={getOnChange(component, onChange, onChangeMap)}
        />;
    } else if (component.type === 'accordions') {
        return (
            <>
                {
                    component.accordions.map((accordion, index) => (
                        <AccordionHelper key={index} accordion={accordion} onChangeMap={getOnChangeMap(onChangeMap)} />
                    ))
                }
            </>
        );
    }
    return null;
}

const ComponentHelper: FC<ComponentHelperProps> = ({ schema, onChange, onChangeMap, onSubmit, parentRef, triggerOnLoad, hasErrors = false}) => {
    const httpService = utils.injection.useInjection<HttpService>(SERVICES.HttpService);
    const componentService = utils.injection.useInjection<ComponentService>(SERVICES.ComponentService);
    const dispatch = useDispatch();

    const callback = useCallback((value: string) => {
        if (schema.type === 'select' && schema.callback) {
            schema.callback(schema.data, value);
        }
        //eslint-disable-next-line
    }, [(schema as any).callback, (schema as any).data?.deal_id]);

    const elements = useMemo(
        () => renderComponent(schema, callback, dispatch, hasErrors, httpService, componentService, onChange, onChangeMap, onSubmit, parentRef, triggerOnLoad),
        [schema, onChange, onSubmit, callback, httpService, hasErrors]
    );

    return elements;
};

export default ComponentHelper;
