import { ActionButton, ActionButtons, ActionDropdown, ActionOption, ModalActionButton, utils, Widget } from '@truenorthmortgage/olympus';
import { FC, useCallback } from 'react';
import { DealHeader as DealHeaderModel } from '../../models/deals/header';
import { FormSchema } from '../../models/schemas/form-schema';
import { SERVICES } from '../../services';
import { HttpService } from '../../services/http.service';
import ContainerModal from '../container-modal/container-modal.component';
import onHoldImage from '../../resources/images/on-hold.png';
import deadImage from '../../resources/images/dead.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type DealHeaderProps = {
    header?: DealHeaderModel,
    onModalClose?: (data?: any) => void
};

const DealHeader: FC<DealHeaderProps> = ({header, onModalClose}) => {
    const httpService = utils.injection.useInjection<HttpService>(SERVICES.HttpService);
    const displayBadge = useCallback((badge: string) => {
        return (
            <div className="badge-wrapper">
                <img className="badge" src={badge === 'on-hold' ? onHoldImage : (badge === 'dead' ? deadImage : '')} />
            </div>
        );
    }, [header]);

    return header ? (
        <Widget>
            <div className="deal-header">
                <div className="left">
                    {header.badge ? displayBadge(header.badge) : null}
                    <div className="left-content">
                        <h2>{header.is_premier && <FontAwesomeIcon className='premier' icon={'star'} />} {header.title}</h2>
                        { header.subjects.length ? 
                            <h3 className="subjects">
                                {header.subjects.map((subject, index) => (
                                    <span key={index} dangerouslySetInnerHTML={{__html: subject}} />
                                ))}
                            </h3>
                            : null
                        }
                        <ActionButtons className="left">
                            {header.action_buttons.map((button, index) =>
                                button.modal ? (
                                    <ModalActionButton
                                        key={index}
                                        text={button.label}
                                        className={button.classes}
                                        disabled={button.disabled}
                                        modalArgs={{
                                            loadProps: async () => ({
                                                data: await httpService.fetchJson<FormSchema>(button.uri)
                                            }),
                                            onClose: onModalClose
                                        }}>
                                        <ContainerModal />
                                    </ModalActionButton>
                                ) : (
                                    <ActionButton key={index} text={button.label} className={button.classes} redirectTo={button.uri} openNewTab={button.new_window}/>
                                )
                            )}
                        </ActionButtons>
                    </div>
                </div>
                <div className="right">
                    {header.action_items && header.action_items.length > 0 ? 
                        <ActionDropdown inputText={header.action_items_label}>
                            {header.action_items.map((item, index) => {
                                if (item.modal) {
                                    const modalProps = {
                                        element: (<ContainerModal />),
                                        loadProps: async () => ({
                                            data: await httpService.fetchJson<FormSchema>(item.uri)
                                        }),
                                        onClose: onModalClose
                                    };
                                    return (
                                        <ActionOption
                                            key={index}
                                            modal={modalProps}>
                                            {item.label}
                                        </ActionOption>
                                    );
                                }
                                return (<ActionOption key={index} path={item.uri}>{item.label}</ActionOption>);
                            })}
                        </ActionDropdown>
                        : null }
                </div>
            </div>
        </Widget>
    ) : null;
};

export default DealHeader;

