import { Accordion, AccordionItem, ActionButtonProps, ModalActionButtonProps, utils } from '@truenorthmortgage/olympus';
import React, { FC, useMemo } from 'react';
import { Accordion as AccordionData } from '../../models/accordions/accordion';
import { AccordionItem as AccordionItemData } from '../../models/accordions/accordion-item';
import { FormSchema } from '../../models/schemas/form-schema';
import { SERVICES } from '../../services';
import { HttpService } from '../../services/http.service';
import ContainerModal from '../container-modal/container-modal.component';
import CheckboxAccordionItem from '../checkbox-accordion-item/checkbox-accordion-item.component';

export type AccordionProps = {
    accordion: AccordionData,
    onModalClose?: (data?: any) => void
    onChangeMap?: Record<string, (s: string | boolean | null) => void>,
};

export function isAccordionData(item: (AccordionData | AccordionItemData)): item is AccordionData {
    return 'accordion_items' in item;
}

export function isAccordionItemData(item: (AccordionData | AccordionItemData)): item is AccordionItemData {
    return 'value' in item;
}

const AccordionHelper: FC<AccordionProps> = ({ accordion, onModalClose, onChangeMap }) => {
    const httpService = utils.injection.useInjection<HttpService>(SERVICES.HttpService);

    const subjects = useMemo(() => {
        if (typeof accordion.subjects === 'string') {
            return [accordion.subjects];
        }
        return Object.values(accordion.subjects ?? []);
    }, [accordion.subjects]);

    const buttons: ActionButtonProps[] | ModalActionButtonProps[] = useMemo(() => {
        if (!accordion.action_buttons) {
            return [];
        }
        return accordion.action_buttons.map(button => {
            if (button.modal) {
                return {
                    text: button.label,
                    className: button.classes,
                    modalArgs: {
                        loadProps: async () => ({
                            data: await httpService.fetchJson<FormSchema>(button.uri)
                        }),
                        onClose: onModalClose
                    },
                    children: (<ContainerModal />),
                    type: utils.button.ButtonType.Modal
                };
            } else {
                return {
                    text: button.label,
                    className: button.classes,
                    redirectTo: button.uri,
                    openNewTab: button.new_window
                };
            }
        });
    }, [accordion.action_buttons]);

    const items = useMemo(() => {
        const mergedItems = [...(accordion.accordionItems ?? []), ...(accordion.accordion_items ?? [])];
        return mergedItems.map(item => {
            if (isAccordionItemData(item) && item.value !== null) {
                item.value = item.value.toString();
            }
            return item;
        });
    }, [accordion.accordionItems, accordion.accordion_items]);

    const accordionItemButtons = items.map((item) => {
        return item.action_buttons ? item.action_buttons.map((button, index) => {
            if (button.modal) {
                return {
                    text: button.label,
                    disabled: button.disabled,
                    className: button.classes,
                    modalArgs: {
                        loadProps: async () => ({
                            data: await httpService.fetchJson<FormSchema>(button.uri)
                        }),
                        onClose: button.modalOnClose ?? onModalClose
                    },
                    children: (<ContainerModal />),
                    type: utils.button.ButtonType.Modal
                } as ModalActionButtonProps;
            } else {
                return {
                    text: button.label,
                    className: button.classes,
                    redirectTo: button.uri,
                    openNewTab: button.new_window
                };
            }
        }) : [];
    });

    return (
        <Accordion 
            heading={accordion.heading}
            subjects={subjects}
            title={accordion.title}
            itemsSize={accordion.item_columns}
            buttons={buttons}
            expanded={accordion.expanded}>
            {items.map((item, index) => (
                <React.Fragment key={index}>
                    {isAccordionItemData(item) &&
                        (item.add_check && onChangeMap ? 
                            <CheckboxAccordionItem label={item.label} checked={item.checked} disabled={item.disabled} onCheckChange={onChangeMap[item.label]} value={item.value} buttons={accordionItemButtons[index]} className={item.classes} /> :
                            <AccordionItem label={item.label} value={item.value} buttons={accordionItemButtons[index]} className={item.classes} />
                        )
                    }
                    {isAccordionData(item) &&
                        <AccordionHelper accordion={item} onChangeMap={onChangeMap}/>
                    }
                </React.Fragment>
            ))}
        </Accordion>
    );
};

export default AccordionHelper;
