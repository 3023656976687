import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimelineItemHandle, TimelineItemProps, utils } from '@truenorthmortgage/olympus';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

export interface CorrespondenceItemProps extends TimelineItemProps {
    correspondenceHeading: string;
    correspondenceHeadingClasses: string[];
    actionButtons: JSX.Element | null;
    expanded?: boolean;
}

const CorrespondenceItem = forwardRef<TimelineItemHandle, CorrespondenceItemProps>(({
    category, headline, dateTime, icon, iconClass, children, visible = true, last = false, expanded = false,
    correspondenceHeading, correspondenceHeadingClasses, actionButtons
}, ref) => {
    const [openDetails, setOpenDetails] = useState(false);
    const [totalHeight, setTotalHeight] = useState<number | null>(null);
    const [height, setHeight] = useState<number | null>(null);
    const [detailHeight, setDetailHeight] = useState<number | null>(null);

    const itemRef = useRef<HTMLDivElement>(null);
    const detailsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (itemRef.current) {
            setTotalHeight(itemRef.current.offsetHeight);
        }

        if (detailsRef.current) {
            setDetailHeight(detailsRef.current.offsetHeight);
        }

        if (expanded) {
            setOpenDetails(true);
        }
    }, []);

    useEffect(() => {
        if (totalHeight === null && detailHeight === null) {
            return;
        } else if (visible && !openDetails && totalHeight !== null && detailHeight !== null) {
            setHeight(totalHeight - detailHeight);
        } else if (visible && openDetails) {
            setHeight(totalHeight);
        }
    }, [totalHeight, detailHeight, visible, openDetails]);
    
    const { isRelative, relativeDate } = useMemo(() => {
        return utils.date.getRelativeDate(dateTime);
    }, [dateTime]);

    const hour = useMemo(() => {
        return utils.date.getShortTime(dateTime);
    }, [dateTime]);

    useImperativeHandle(ref, () => ({
        getCategory: () => category
    }));

    return (
        <div ref={itemRef}
            className={`time-item ${last ? 'last' : ''} ${visible ? 'visible' : ''}`}
            style={{ height: visible ? (height || 'auto') : 0, opacity: visible ? 1 : 0 }}>
            <div className="time-item-inner">
                <div className="time-line internal">
                    {icon ? <i><FontAwesomeIcon icon={icon} /></i> : null}
                    {iconClass ? <i className={iconClass}/> : null}
                </div>

                <div className="time-info correspondence-info">
                    <span className={`correspondence-heading ${correspondenceHeadingClasses.join(' ')}`}>{correspondenceHeading}</span>
                    <span className="correspondence-item-headline">{headline}</span>
                    {actionButtons}
                </div>

                <p className="time-details">
                    {hour}
                    {' '}
                    {
                        isRelative ? (
                            <span className="heavy">{relativeDate}</span>
                        ) : relativeDate
                    }
                    {' '}
                    {
                        children ? (
                            <span className="detail-trigger" onClick={() => setOpenDetails(open => !open)}>
                            Details
                            </span>
                        ) : null
                    }
                </p>
                {
                    children ? (
                        <div ref={detailsRef}
                            className="time-item-details"
                            style={{ height: openDetails ? detailHeight! : (detailHeight ? 0 : 'auto') }}>
                            {children}
                        </div>
                    ) : null
                }
            </div>
        </div>
        
    );
});

export default CorrespondenceItem;
