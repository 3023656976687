import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useMemo, useState, FocusEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';
import AccordionHelper from '../../../components/accordion-helper/accordion-helper.component';
import DealHeader from '../../../components/deal-header/deal-header.component';
import { Accordion } from '../../../models/accordions/accordion';
import { SERVICES } from '../../../services';
import { DealService } from '../../../services/deal.service';
import { DealHeader as DealHeaderModel } from '../../../models/deals/header';
import { DocumentsData } from '../../../models/deals/deal';
import ActionHeaderHelper from '../../../components/action-header-helper/action-header-helper.component';
import TableHelper from '../../../components/table-helper/table-helper.component';
import { CellActionButtons, CellComponent, CellText, CustomCellText } from '../../../models/schemas/table-schema';
import { ButtonComponent, ComponentSchema, SelectComponent } from '../../../models/schemas/form-schema';
import DealStatusModal from './deal-status-modal.component';
import { Observable, tap, zip } from 'rxjs';
import { HttpService } from '../../../services/http.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { ActionHeaderSchema } from '../../../models/schemas/action-header';
import ComponentHelper from '../../../components/component-helper/component-helper.component';
import DealBatchUpdateModal from './deal-batch-update-modal.component';
import './deals-document.style.scss';
import ActionButtonsHelper from '../../../components/action-buttons-helper/action-buttons-helper.component';

type SectionGroups = Record<number, { disabled: boolean, rows: number[], isOpen: boolean, documents: { id: number, name: string, isSelected: boolean }[] }>;

const DealDocuments = () => {
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const dispatch = useDispatch();
    const [dealHeader, setDealHeader] = useState<DealHeaderModel | null>(null);
    const [simpleDealAccordion, setSimpleDealAccordion] = useState<Accordion | null>(null);
    const [noteAccordion, setNoteAccordion] = useState<[ActionHeaderSchema, ComponentSchema] | null>(null);
    const [documentData, setDocumentData] = useState<DocumentsData | null>(null);
    const [sectionGroups, setSectionGroups] = useState<SectionGroups>();
    const params = utils.nav.useAllPossibleParams();

    useEffect(() => {
        document.title = 'Deal ' + params.deal_id;
        
        return () => {
            document.title = 'True North Mortgage - Zeus';
        };
    }, [params.deal_id]);
    
    const loadDocumentData: () => Observable<DocumentsData> = useCallback(() => {
        return dealService.getDocumentData(params.deal_id!).pipe(tap(setDocumentDataWithHandlers));
    }, [params.deal_id, dealService]);

    const setDocumentDataWithHandlers = useCallback((data: DocumentsData) => {
        data[2].rows.forEach(row => {
            row.cells.forEach(cell => {
                if (cell.type === 'component' && cell.component.type === 'textarea') {
                    const component = cell.component;
                    cell.component.onBlur = (memoText: string | null) => {
                        if (component.value !== memoText) {
                            dealService.saveMemo({
                                memo_text: memoText!,
                                memo_deal_id: component.data.deal_id,
                                memo_document_section_id: component.data.document_section_id,
                                memo_role: component.data.role,
                                memo_type: component.data.type,
                            });

                            dispatch(pushNotification({ class: 'success', message: 'The memo has been updated' }));
                            HttpService.subscribe(loadDocumentData(), dispatch);
                        }
                    };
                }
            });
        });
        setDocumentData(data);
    }, [dealService, setDocumentData, dispatch, loadDocumentData]);

    const loadData = useCallback(() => {
        if (params.deal_id) {
            const subscription = HttpService.subscribe(zip(
                loadDocumentData(),
                dealService.getHeader(params.deal_id).pipe(tap(setDealHeader)),
                dealService.getSimpleDealAccordion(params.deal_id).pipe(tap(setSimpleDealAccordion)),
                dealService.getNotestoDocSpecialistAccordion(params.deal_id).pipe(tap(setNoteAccordion))
            ), dispatch);

            return () => {
                subscription.unsubscribe();
            };
        }
    }, [params.deal_id, dealService, dispatch, loadDocumentData]);

    useEffect(loadData, [loadData]);

    const reloadData = useCallback((data?: { submit: boolean, message: string }) => {
        if (data?.submit) {
            loadData();
        } else if (typeof data === 'string') {
            dispatch(pushNotification({ class: 'success', message: data }));
        }
        if (data?.message && typeof data.message === 'string') {
            dispatch(pushNotification({ class: 'success', message: data.message }));
        }
    }, [loadData]);

    const saveSectionName = useCallback((data: Record<string, string | number | null>) => (event: FocusEvent<HTMLInputElement>) => {
        if (event.target.value.trim() !== '') {
            dealService.saveSectionName(Object.assign({}, data, { 'new_section_name': event.target.value.trim() }))
                .then(() => HttpService.subscribe(loadDocumentData(), dispatch))
                .catch(e => {
                    dispatch(pushNotification({ class: 'error', message: e.toString() }));
                });
        }
    }, [dealService, loadDocumentData, dispatch]);

    const createCustomCellText = useCallback((cell: CustomCellText, rowIndex: number, isLastCell: boolean) => { 
        const allDocumentsChecked = sectionGroups && sectionGroups[rowIndex] ? sectionGroups[rowIndex].documents.every(doc => doc.isSelected) : false;
        const sectionDisabled = sectionGroups && sectionGroups[rowIndex] ? sectionGroups[rowIndex].disabled : false;
        const onChange = () => {
            const newGroups = Object.assign({}, sectionGroups);
            if (sectionGroups) {
                Object.keys(newGroups).map((key: string) => {
                    const index = parseInt(key);
                    if (rowIndex !== index) {
                        newGroups[index] = Object.assign({}, sectionGroups[index], {
                            disabled: !allDocumentsChecked
                        });
                    } else {
                        newGroups[index] = Object.assign({}, sectionGroups[rowIndex], {
                            disabled: false,
                            documents: sectionGroups[rowIndex].documents.map(doc => {
                                doc.isSelected = !allDocumentsChecked;
                                return doc;
                            })
                        });
                    }
                });
            }
            setSectionGroups(newGroups);
        };

        return {
            type: 'custom-jsx',
            className: 'text',
            colspan: cell.colspan,
            element: (
                <div className="custom-cell-text-container">
                    <div className="custom-cell-text-label-container">
                        {
                            documentData && documentData[2].allow_batch_edit && cell.show_checkbox ? (
                                <label className="checkbox">
                                    <input type="checkbox" checked={allDocumentsChecked} disabled={sectionDisabled} onChange={onChange} />
                                    <span className="checkmark"></span>
                                </label>
                            ) : null
                        }
                        <div className="custom-cell-text-label">{cell.value}</div>
                        {' '}
                        {cell.show_input && <input type="text"
                            className="custom-cell-text custom-section-name"
                            placeholder={cell.placeholder}
                            defaultValue={cell.custom_section_name}
                            onBlur={saveSectionName(cell.data)} />
                        }
                    </div>
                    {isLastCell ? createTableToggle(rowIndex) : null}
                </div>
            )
        };
    }, [saveSectionName, sectionGroups, setSectionGroups, documentData]);

    const createTableToggle = (rowIndex: number) => {
        const toggleSectionGroup = () => {
            setSectionGroups(groups => {
                return Object.assign({}, groups, { [rowIndex]: Object.assign({}, groups![rowIndex], { isOpen: !groups![rowIndex].isOpen }) });
            });
        };

        return (
            <div className="custom-cell-text-toggle">
                <i className={'fa interactive'} onClick={toggleSectionGroup}>
                    {
                        sectionGroups && sectionGroups[rowIndex] && sectionGroups[rowIndex].isOpen
                            ? <FontAwesomeIcon icon={faMinusSquare} />
                            : <FontAwesomeIcon icon={faPlusSquare} />
                    }
                </i>
            </div>
        );
    };

    const createCustomButton = useCallback((cell: CellActionButtons, rowIndex: number, isLastCell: boolean) => {
        const actionButtonSchema: ButtonComponent[] = [];
        cell.value.map((button, index: number) => {
            if (button) {
                actionButtonSchema.push({
                    'id': 'button-' + index,
                    'label': button.label,
                    'icon': button.icon ?? null,
                    'disabled': button.disabled ?? false,
                    'classes': button.classes ?? undefined,
                    'uri': button.uri,
                    'download': button.icon === 'download',
                    'modal': button.modal,
                    'new_window': false,
                });
            }
        });

        return {
            type: 'custom-jsx',
            className: 'text',
            element: (
                <div className='custom-cell-text-container no-text-transform'>
                    <div className='action-buttons left'>
                        {cell && cell.value && cell.value[0] && actionButtonSchema ? (
                            <ActionButtonsHelper schema={actionButtonSchema} onModalClose={reloadData}/>
                        ) : null}
                    </div>
                    {' '}
                    {isLastCell ? createTableToggle(rowIndex) : null}
                </div>
            )
        };
    }, [saveSectionName, sectionGroups, setSectionGroups, documentData]);

    const createDocumentStateSelectionDropdown = useCallback((cell: CellComponent, select: SelectComponent) => {
        return {
            type: 'custom-jsx',
            className: 'text',
            colspan: cell.colspan,
            element: (
                <DealStatusModal select={select} onSubmit={reloadData} />
            )
        };
    }, [reloadData]);

    const createCustomDocumentNameCell = useCallback((cell: CellText, documentId: number, rowIndex: number) => {
        const sectionGroup = sectionGroups
            ? Object.entries(sectionGroups).find(([_, section]) => section.rows.indexOf(rowIndex) !== -1)
            : undefined;

        const onChange = () => {
            const newGroups = Object.assign({}, sectionGroups);
            if (sectionGroup) {
                const documents = sectionGroup[1].documents.map(doc => {
                    if (doc.id === documentId) {
                        return Object.assign({}, doc, { isSelected: !doc.isSelected });
                    }
                    return doc;
                });

                const sectionHasCheckedDocuments = !!documents.find(doc => doc.isSelected === true);
                Object.keys(newGroups).map(key => {
                    if (key !== sectionGroup[0]) {
                        newGroups[parseInt(key)] = Object.assign({}, newGroups[parseInt(key)], { disabled: sectionHasCheckedDocuments });
                    }
                });
                newGroups[parseInt(sectionGroup[0])] = Object.assign({}, sectionGroup[1], { documents });
            }
            setSectionGroups(newGroups);
        };

        const isChecked = !!sectionGroup?.[1].documents.find(doc => doc.id === documentId && doc.isSelected);
        const disabled = sectionGroup?.[1].disabled;
        return {
            type: 'custom-jsx',
            className: 'text',
            colspan: cell.colspan,
            element: (
                <div className="document-name">
                    {
                        documentData && documentData[2].allow_batch_edit ? (
                            <label className="checkbox">
                                <input type="checkbox" checked={isChecked} disabled={disabled} onChange={onChange} />
                                <span className="checkmark"></span>
                            </label>
                        ) : null
                    }
                    {' '}
                    {cell.value}
                </div>
            )
        };
    }, [sectionGroups, setSectionGroups, documentData]);

    useEffect(() => {
        let groupIdx: number | null = null;
        const groups: SectionGroups = {};
        if (documentData) {
            documentData[2].rows.forEach((row, rowIndex) => {
                row.cells.map((cell, cellIndex) => {
                    if (cell.type === 'custom-cell-text') {
                        groupIdx = rowIndex;
                        groups[groupIdx] = { disabled: false, rows: [], isOpen: true, documents: [] };
                    } else if (cellIndex === 0 && (cell.type === 'component' || cell.type === 'text')) {
                        if (groupIdx) {
                            groups[groupIdx].rows.push(rowIndex);
                            if (row.cells.length > 1 && cell.type === 'text') {
                                const lastCell = row.cells[row.cells.length - 1] as CellComponent;
                                const selectComponent = lastCell.component as SelectComponent;
                                if (selectComponent && selectComponent.data && selectComponent.data.document_id !== undefined) {
                                    groups[groupIdx].documents.push({
                                        id: selectComponent.data.document_id,
                                        name: cell.value,
                                        isSelected: false
                                    });
                                }
                            }
                        }
                    }
                    return cell;
                });
            });

            setSectionGroups(groups);
        }
    }, [documentData]);

    const tableSchema = useMemo(() => {
        if (documentData && documentData[2] && sectionGroups) {
            return Object.assign({}, documentData[2], {
                rows: documentData[2].rows.map((row, rowIndex) => {
                    const section = Object.values(sectionGroups).find(section => section.rows.includes(rowIndex));
                    const hideRow = section ? !section.isOpen : undefined;
                    return Object.assign({}, row, {
                        hideRow,
                        cells: row.cells.map((cell, cellIndex) => {
                            if (cell.type === 'custom-cell-text') {
                                return createCustomCellText(cell, rowIndex, cellIndex === row.cells.length - 1);
                            } else if (cell.type === 'component' && cell.component.type === 'select') { 
                                return createDocumentStateSelectionDropdown(cell, cell.component);
                            } else if (cell.type === 'action_buttons') {
                                return createCustomButton(cell, rowIndex, cellIndex === row.cells.length - 1);
                            } else if (cellIndex === 0 && row.cells.length > 1 && cell.type === 'text') {
                                const lastCell = row.cells[row.cells.length - 1] as CellComponent;
                                const selectComponent = lastCell.component as SelectComponent;
                                return selectComponent && selectComponent.data && selectComponent.data.document_id !== undefined
                                    ? createCustomDocumentNameCell(cell, selectComponent.data.document_id, rowIndex)
                                    : cell;
                            }
                            return cell;
                        })
                    });
                })
            });
        }
        return null;
    }, [documentData, sectionGroups, createCustomCellText, createDocumentStateSelectionDropdown, createCustomDocumentNameCell, createCustomButton]);

    const selectedDocuments = useMemo(() => {
        return sectionGroups ? Object.values(sectionGroups).flatMap(({ documents }) => documents.filter(doc => doc.isSelected)) : undefined;
    }, [sectionGroups]);

    if (!params.deal_id) {
        return (<Navigate to="/deals/overview" />);
    }

    return dealHeader && simpleDealAccordion && documentData && tableSchema ? (
        <>
            <DealHeader header={dealHeader} onModalClose={reloadData} />
            <AccordionHelper accordion={simpleDealAccordion} onModalClose={reloadData}/>

            {noteAccordion && noteAccordion.length ?
                <>
                    <ActionHeaderHelper schema={noteAccordion[0]} useMargin={false} />
                    <ComponentHelper schema={noteAccordion[1]} />
                </>
                : null}
            <ActionHeaderHelper schema={documentData[0]} useMargin={true} onModalClose={reloadData} />
            <TableHelper schema={tableSchema} includeHeader={true} onModalClose={reloadData} showTitleBar={false} />
            <DealBatchUpdateModal documents={tableSchema.allow_batch_edit ? selectedDocuments : undefined} onClose={loadData} onSubmit={reloadData} />
        </>
    ) : null;
};

export default DealDocuments;
