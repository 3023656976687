import { useState, useEffect, useCallback, useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { Session } from '../../models/auth/session';
import { ActionButton, pushNotification, utils, ActionButtons, ModalActionButton } from '@truenorthmortgage/olympus';
import { SERVICES } from '../../services';
import VerbalCompanyCard, { CompanyData, CompanyInfo } from './verbal-company-card.component';
import VerbalApplicantCard, { ApplicantInfo } from './verbal-applicant-card.component';
import VerbalIncomeCard, { IncomeInfo } from './verbal-income-card.component';
import VerbalPsYtdCard, { YtdPsInfo } from './verbal-ps-ytd-card.component';
import VerbalTwoYearAverageCard, { TwoYearInfo } from './verbal-two-year-average-card.component';
import VerbalMiscCard, { MiscInfo } from './verbal-misc-card.component';
import { useDispatch } from 'react-redux';
import { VerbalerService } from '../../services/verbaler.service';
import { displayErrors } from '../../components/form-helper/form-helper.component'; 
import './verbal-component-styles.scss';

export interface VerbalerDraftConfig {
    loan_number: number,
    applicant_info: ApplicantInfo,
    company_info: CompanyInfo,
    income_info: IncomeInfo,
    ytd_ps_info: YtdPsInfo,
    two_yr_info: TwoYearInfo,
    misc_info: MiscInfo,
    guaranteed_output: string,
    ytd_output: string,
    paystub_output: string,
    twoyr_output: string,
    probation_output: string,
}

type VerbalDraftSections = 'applicant_info' | 'company_info' | 'income_info' | 'two_yr_info' | 'misc_info' | 'ytd_ps_info';

function formatDate(date: Date | null) {
    return (date) ? date.toISOString().slice(0, 10) : '';
}

const selectSession = createSelector(
    (state: any) => state.session,
    (session: Session | null) => session
);

const VerbalerDraft = () => {
    const dispatch = useDispatch();
    const uuid: string = utils.nav.useAllPossibleParams().draft_id ?? '';
    const verbalerService = utils.injection.useInjection<VerbalerService>(SERVICES.VerbalerService);

    // states
    const [companyData, setCompanyData] = useState<CompanyData[]>([{} as CompanyData]);
    const [draftData, setDraftData] = useState<VerbalerDraftConfig | null>(null);
    const [errors, setErrors] = useState<string[]>([]);

    const {
        loanNumber,
        applicantInfo,
        companyInfo,
        incomeInfo,
        ytdPsInfo,
        twoYearInfo,
        miscInfo,
        guaranteedOutput,
        ytdOutput,
        paystubOutput,
        twoyrOutput,
        probationOutput
    } = useMemo(() => ({
        loanNumber: (draftData?.loan_number) ?? 0,
        applicantInfo: (draftData?.applicant_info) ?? {} as ApplicantInfo,
        companyInfo: (draftData?.company_info) ?? {} as CompanyInfo,
        incomeInfo: (draftData?.income_info) ?? {} as IncomeInfo,
        ytdPsInfo: (draftData?.ytd_ps_info) ?? {} as YtdPsInfo,
        twoYearInfo: (draftData?.two_yr_info) ?? {} as TwoYearInfo,
        miscInfo: (draftData?.misc_info) ?? {} as MiscInfo,

        guaranteedOutput: (draftData?.guaranteed_output) ?? '',
        ytdOutput: (draftData?.ytd_output) ?? '',
        paystubOutput: (draftData?.paystub_output) ?? '',
        twoyrOutput: (draftData?.twoyr_output) ?? '',
        probationOutput: (draftData?.probation_output) ?? ''
    }), [draftData]);

    const createUpdatedInfo = useCallback((value: any, sectionName: VerbalDraftSections, parameter_name: string|null = null, cast_numeric = false, companyId = null) => {
        if (!draftData) return;
        const section = draftData[sectionName];
        if (!section) return;

        const configCpy = { ...draftData };
        value = (cast_numeric) ? ((isNaN(+value)) ? null : +value) : value;
        if (parameter_name == null) {
            if (value === section && companyId === null) return;
            configCpy[sectionName] = value ?? null;
        } else {
            if (!Object.keys(section).includes(parameter_name)) return;
            if (value == section[parameter_name]) return;
            configCpy[sectionName][parameter_name] = value ?? null;
        }

        // updates the outputs and company info if needed
        verbalerService.generateDraftOutputs(configCpy, companyId).then((configWithOutputs: VerbalerDraftConfig) => setDraftData(configWithOutputs));
    }, [draftData]);

    // will load company list once on component mount
    const getCompanyInfo = useCallback(async () => {
        const fetchedData = await verbalerService.getActiveCompaniesBasicInfo();
        setCompanyData(fetchedData);
    }, []);

    useEffect(() => {
        getCompanyInfo();
    }, [getCompanyInfo]);

    // will load draft once on component mount
    const getVerbalDraft = useCallback(async () => {
        const recievedConfig = await verbalerService.getVerbalDraft(uuid);
        setDraftData(recievedConfig);
    }, []);

    useEffect(() => {
        getVerbalDraft();
    }, [getVerbalDraft]);

    const saveVerbalDraft = async () => {
        if (!draftData) {
            dispatch(pushNotification({ class: 'error', message: 'Could not save'}));
            return;
        }

        verbalerService.updateVerbalDraft(uuid, draftData).then(
            (response: string) => {
                dispatch(pushNotification({ class: 'success', message: response}));
            },
            (error: any) => {
                displayErrors(error, setErrors, dispatch);
            }
        );
    };


    return loanNumber !== 0 ? (
        <div id='verbal-template'>
            <div className='vulcan-action-buttons'>
                <ActionButton callback={() => window.history.back()}>Back</ActionButton>
                <ActionButton className='vulcan-save-button primary' callback={saveVerbalDraft}>Save Draft</ActionButton>
            </div>

            <VerbalApplicantCard errors={errors} configData={applicantInfo} helpers={[createUpdatedInfo]} />

            <VerbalCompanyCard errors={errors} configData={companyInfo} companyData={companyData} helpers={[createUpdatedInfo, formatDate]} emailInfo={({ loan_number: loanNumber, client_name: applicantInfo.applicant_name })} />

            <VerbalIncomeCard configData={[incomeInfo, guaranteedOutput]} helpers={[createUpdatedInfo]} />

            <VerbalPsYtdCard configData={[ytdPsInfo, ytdOutput, paystubOutput]} helpers={[createUpdatedInfo]} />

            <VerbalTwoYearAverageCard errors={errors} configData={[twoYearInfo, twoyrOutput]} helpers={[createUpdatedInfo]} />

            <VerbalMiscCard configData={[miscInfo, probationOutput]} helpers={[createUpdatedInfo]} />
            <div className='vulcan-action-buttons'>
                <ActionButton className='vulcan-save-button' callback={saveVerbalDraft}>Save Draft</ActionButton>
            </div>
        </div>
    ) : null;
};

export default VerbalerDraft;
