import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ComponentHelper from '../../components/component-helper/component-helper.component';
import TableHelper from '../../components/table-helper/table-helper.component';
import { SelectComponent } from '../../models/schemas/form-schema';
import { TableSchema } from '../../models/schemas/table-schema';
import { SERVICES } from '../../services';
import { ReportService } from '../../services/report.service';
import './agent-rating-report.scss';

const AgentRatingReport = () => {
    const reportService = utils.injection.useInjection<ReportService>(SERVICES.ReportService);
    const [reportData, setReportData] = useState<[SelectComponent, TableSchema]>();
    const [filters, setFilters] = useState<{[name: string]: string | null}>({});
    const dispatch = useDispatch();
    
    const loadData = useCallback(() => {
        const subscription = reportService.getAgentRatingReport(filters).subscribe({
            next: setReportData,
            error: (e) => {
                dispatch(
                    pushNotification({ class: 'error', message: e.toString() })
                );
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [filters, reportService, dispatch]);

    const reloadData = useCallback((data?: {submit: boolean}) => {
        if (data?.submit) {
            loadData();
        }
    }, [loadData]);

    useEffect(loadData, [loadData]);

    const onChangeHandler = useCallback((value: string | null) => {
        if (filters.agent_user_id !== value) {
            setFilters({agent_user_id: value});
        }
        
    }, [filters]);
    
    return reportData ?
        <>
            <div className='single-filter'>
                <ComponentHelper schema={reportData[0]} onChange={onChangeHandler} />
            </div>
            <TableHelper schema={reportData[1]} includeHeader={true} onModalClose={reloadData} />
        </>
        : null;
};

export default AgentRatingReport;

