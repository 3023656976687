import { TextComponent, MoneyComponent } from '@truenorthmortgage/olympus';
import { Card } from './card.component';
import * as _ from 'lodash';

export interface TwoYearInfo {
    twoyr_previous_year: number,
    twoyr_current_year: number,
    twoyr_previous_year_income: number,
    twoyr_current_year_income: number,
    [key: string]: TwoYearInfo[keyof TwoYearInfo];
}

interface VerbalTwoYearCardProps {
    configData: [TwoYearInfo, string];
    errors: string[];
    helpers: CallableFunction[];
}

const VerbalTwoYearAverageCard = ({ configData: [twoYearInfo, twoYearOutput], helpers: [createUpdatedInfo], errors: errors }: VerbalTwoYearCardProps) => {
    const { twoyr_previous_year: previousYear,
        twoyr_current_year: currentYear,
        twoyr_previous_year_income: previousYearIncome,
        twoyr_current_year_income: currentYearIncome
    } = twoYearInfo;

    return (
        <Card className='verbal-card' id='vulcan-ps-and-ytd'>
            <h3>Two Year Average:</h3>
            <div className='vulcan-parent-div'>
                <div className='verbal-year'>
                    <label>First Year: </label>
                    <TextComponent
                        formData={previousYear?.toString()}
                        onBlur={(val) =>
                            createUpdatedInfo(val, 'two_yr_info', 'twoyr_previous_year')
                        }
                        error={errors.includes('twoyr_previous_year')}
                        name='verbal-previous-year'
                    />

                    <label>Previous Year Income: </label>
                    <MoneyComponent cents={previousYearIncome} onChangeCents={_.debounce((val) => createUpdatedInfo(val, 'two_yr_info', 'twoyr_previous_year_income'), 500)} name='verbal-previous-year-income' />
                </div>

                <div className='verbal-year'>
                    <label>Second Year: </label>
                    <TextComponent
                        formData={currentYear?.toString()}
                        onBlur={(val) =>
                            createUpdatedInfo(val, 'two_yr_info', 'twoyr_current_year')
                        }
                        error={errors.includes('twoyr_current_year')}
                        name='verbal-current-year'
                    />
                    <label>Current Year Income: </label>
                    <MoneyComponent cents={currentYearIncome} onChangeCents={_.debounce((val) => createUpdatedInfo(val, 'two_yr_info', 'twoyr_current_year_income'), 500)} name='verbal-current-year-income' />
                </div>

                <div>
                    <label>Two Year Average Output: </label>
                    <TextComponent formData={twoYearOutput} disabled={true} />
                </div>
            </div>
        </Card>
    );
};
export default VerbalTwoYearAverageCard;
