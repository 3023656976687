import { BasicModal, BasicModalHandle, utils } from '@truenorthmortgage/olympus';
import React, { createRef, FC, useMemo } from 'react';
import ContainerModal from '../../../components/container-modal/container-modal.component';
import { DocumentSpecialistQueueItem } from '../../../models/deals/document-specialist';
import { SERVICES } from '../../../services';
import { DocumentSpecialistService } from '../../../services/document-specialist.service';

export interface DocumentSpecialistQueueProps {
    items: DocumentSpecialistQueueItem[],
    onModalClose: () => void
}

const DocumentSpecialistQueue: FC<DocumentSpecialistQueueProps> = ({ items, onModalClose }) => {
    const dealSpecialistService = utils.injection.useInjection<DocumentSpecialistService>(SERVICES.DocumentSpecialistService);

    const refs = useMemo(() => {
        return items.map(() => createRef<BasicModalHandle>());
    }, [items]);

    return (
        <div id="doc-specialist-queue">
            {
                items.map((item, index) => (
                    <React.Fragment key={item.id}>
                        <span className="button primary doc-specialist-queue-entry modal-trigger" onClick={() => refs[index].current?.open()}>
                            <div>
                                <span>{item.applicants}</span>
                                <span>{item.loan_type} - T# {item.deal_id}</span>
                            </div>
                            <div>
                                <span>COF: </span>
                                <span>{item.cof_date}</span>
                                <span>Closing: </span>
                                <span>{item.closing}</span>
                            </div>
                        </span>
                        <BasicModal ref={refs[index]} onClose={onModalClose} loadProps={async () => ({
                            data: await dealSpecialistService.getClaimModal(item.id)
                        })}>
                            <ContainerModal />
                        </BasicModal>
                    </React.Fragment>
                ))
            }
        </div>
    );
};

export default DocumentSpecialistQueue;
