import { utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { timer, tap, zip, switchMap } from 'rxjs';
import TableHelper from '../../../components/table-helper/table-helper.component';
import { SERVICES } from '../../../services';
import { HttpService } from '../../../services/http.service';
import { TableSchema } from '../../../models/schemas/table-schema';
import { ActionService } from '../../../services/action.service';

const AllNextActions= () => {
    const actionService = utils.injection.useInjection<ActionService>(SERVICES.ActionService);
    const [nextActions, setNextActions] = useState<TableSchema | null>(null);
    const dispatch = useDispatch();

    const loadData = useCallback(() => {
        return zip(
            actionService.getAllActions()
        ).pipe(
            tap({
                next: ([nextActions]) => {
                    setNextActions(nextActions);
                }
            })
        );
    }, [
        dispatch,actionService
    ]);

    const reloadData = useCallback((data?: {submit: boolean}) => {
        if (data?.submit) {
            HttpService.subscribe(loadData(), dispatch);
        }
    }, [loadData, dispatch]);

    useEffect(() => {
        const subscription = HttpService.subscribe(timer(0, 60000).pipe(switchMap(loadData)), dispatch);
        return () => {
            subscription.unsubscribe();
        };
    }, [loadData, dispatch]);

    return nextActions ?
        <TableHelper schema={nextActions} includeHeader onModalClose={reloadData}/> : null;
};

export default AllNextActions;
