import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';
import AccordionHelper from '../../../components/accordion-helper/accordion-helper.component';
import DealHeader from '../../../components/deal-header/deal-header.component';
import { SERVICES } from '../../../services';
import { DealService } from '../../../services/deal.service';
import { DealHeader as DealHeaderModel } from '../../../models/deals/header';
import { LeadsData } from '../../../models/deals/deal';
import { Observable, tap, zip, map } from 'rxjs';
import { HttpService } from '../../../services/http.service';
import { Accordion } from '../../../models/accordions/accordion';

export type simpleDealAccordionProps = {
    contact_info: Accordion,
    applicant_info: Accordion,
    co_applicant_info: Accordion,
    mortgage_info: Accordion 

}
const DealLeads = () => {
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const dispatch = useDispatch();
    const [dealHeader, setDealHeader] = useState<DealHeaderModel | null>(null);
    const [simpleDealAccordion, setSimpleDealAccordion] = useState<simpleDealAccordionProps | null>(null);
    const params = utils.nav.useAllPossibleParams();

    useEffect(() => {
        document.title = 'Deal ' + params.deal_id;
        
        return () => {
            document.title = 'True North Mortgage - Zeus';
        };
    }, [params.deal_id]);
    
    const loadData = useCallback(() => {
        if (params.deal_id) {
            const subscription = HttpService.subscribe(zip(
                dealService.getHeader(params.deal_id).pipe(tap(setDealHeader)),
                dealService.getLeadAccordion(params.deal_id)
                    .pipe(map(([
                        contact_info,
                        applicant_info,
                        co_applicant_info,
                        mortgage_info,
                    ]) => ({
                        contact_info,
                        applicant_info,
                        co_applicant_info,
                        mortgage_info,
                    })),
                    tap(setSimpleDealAccordion)
                    )
            )                
            , dispatch);

            return () => {
                subscription.unsubscribe();
            };
        }
    }, [params.deal_id, dealService, dispatch]);

    useEffect(loadData, [loadData]);

    const reloadData = useCallback((data?: { submit: boolean, message: string }) => {
        if (data?.submit) {
            loadData();
        } else if (typeof data === 'string') {
            dispatch(pushNotification({ class: 'success', message: data }));
        }
        if (data?.message && typeof data.message === 'string') {
            dispatch(pushNotification({ class: 'success', message: data.message }));
        }
    }, [loadData]);

    if (!params.deal_id) {
        return (<Navigate to="/deals/overview" />);
    }

    return dealHeader && simpleDealAccordion ? (
        <>
            <DealHeader header={dealHeader} onModalClose={reloadData} />
            <AccordionHelper accordion={simpleDealAccordion.contact_info} onModalClose={reloadData}/>
            <AccordionHelper accordion={simpleDealAccordion.applicant_info} onModalClose={reloadData}/>
            <AccordionHelper accordion={simpleDealAccordion.co_applicant_info} onModalClose={reloadData}/>
            <AccordionHelper accordion={simpleDealAccordion.mortgage_info} onModalClose={reloadData}/>
        </>
    ) : null;
};

export default DealLeads;
