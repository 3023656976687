import { Observable } from 'rxjs';
import { ActionHeaderSchema } from '../models/schemas/action-header';
import { TableSchema } from '../models/schemas/table-schema';
import { HttpService } from './http.service';

export class UsersService extends HttpService {
    getSecurity(): Observable<[ActionHeaderSchema, TableSchema]> {
        return this.fetchJsonObservable<[ActionHeaderSchema, TableSchema]>('/users/security');
    }

    arrayToBase64String(a: Uint8Array) {
        return btoa(String.fromCharCode(...Array.from(a)));
    }

    base64url2base64(input: string) {
        let replaced = input
            .replace(/=/g, '')
            .replace(/-/g, '+')
            .replace(/_/g, '/');

        const pad = replaced.length % 4;
        if (pad) {
            if (pad === 1) {
                throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
            }
            replaced += new Array(5 - pad).join('=');
        }

        return replaced;
    }

    async post2faAuth(options: any, token: string): Promise<any> {
        const publicKey = Object.assign({}, options);
        publicKey.challenge = Uint8Array.from(
            window.atob(this.base64url2base64(publicKey.challenge)), 
            c => c.charCodeAt(0)
        );
        if (publicKey.allowCredentials) {
            publicKey.allowCredentials = publicKey.allowCredentials.map((data: any) => {
                data.id = Uint8Array.from(window.atob(this.base64url2base64(data.id)), c => c.charCodeAt(0));
                return data;
            });
        }
        const data: any = await navigator.credentials.get({ 'publicKey': publicKey });
        if (data) {
            const publicKeyCredential = {
                id: data.id,
                type: data.type,
                rawId: this.arrayToBase64String(new Uint8Array(data.rawId)),
                response: {
                    clientDataJSON: this.arrayToBase64String(new Uint8Array(data.response.clientDataJSON)),
                    authenticatorData: this.arrayToBase64String(new Uint8Array(data.response.authenticatorData)),
                    signature: this.arrayToBase64String(new Uint8Array(data.response.signature)),
                    userHandle: data.response.userHandle ? this.arrayToBase64String(new Uint8Array(data.response.userHandle)) : null
                }
            };
    
            const payload = {
                cred_data: btoa(JSON.stringify(publicKeyCredential))
            };

            return HttpService.executeFetchRequest('/login2fa', {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token}, 'POST', payload);
        }
    }
}
