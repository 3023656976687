import { createSlice } from '@reduxjs/toolkit';
import { Session } from '../models/auth/session';

const sessionSlice = createSlice({
    name: 'session',
    initialState: null as Session | null,
    reducers: {
        setSession: (_, action) => {
            return action.payload;
        }
    }
});

export const { setSession } = sessionSlice.actions;

export default sessionSlice.reducer;
