import {
    TextComponent,
    RadioComponent,
    RadioOption,
    DateComponent,
} from '@truenorthmortgage/olympus';
import { Card } from './card.component';
import { useEffect, useState } from 'react';

export interface ApplicantInfo {
    applicant_name: string | null;
    applicant_hire_date: string | null;
    applicant_status: string | null;
    applicant_position: string | null;
    [key: string]: ApplicantInfo[keyof ApplicantInfo];
}

interface VerbalApplicantCardProps {
    configData: ApplicantInfo;
    helpers: CallableFunction[];
    errors: string[];
}

const VerbalApplicantCard = ({
    configData: applicantInfo,
    helpers: [createUpdatedInfo],
    errors: errors,
}: VerbalApplicantCardProps) => {
    const {
        applicant_name: applicantName,
        applicant_hire_date: applicantHireDate,
        applicant_status: applicantStatus,
        applicant_position: applicantPosition,
    } = applicantInfo;
    const [localDate, setLocalDate] = useState<string>(applicantHireDate ?? '');

    useEffect(() => {
        createUpdatedInfo(localDate, 'applicant_info', 'applicant_hire_date');
    }, [localDate]);

    return (
        <Card className='verbal-card' id='verbal-template-client-info'>
            <h3>Client Information:</h3>
            <div className='vulcan-parent-div'>
                <div>
                    <label>Applicant Name:</label>
                    <TextComponent
                        formData={applicantName ?? undefined}
                        onBlur={(newName) => createUpdatedInfo(newName, 'applicant_info', 'applicant_name')}
                        name='verbal-applicant-name'
                        error={errors.includes('applicant_name')}
                    />
                </div>
                <div>
                    <label>Date of hire:</label>
                    <DateComponent
                        defaultValue={localDate}
                        onChange={(date) => setLocalDate(date ?? '')}
                        name='verbal-applicant-hire-date'
                    />
                </div>
                <div>
                    <label>Status:</label>
                    <RadioComponent
                        name='verbal-applicant-status'
                        value={applicantStatus ?? ''}
                        onChange={(val) =>
                            createUpdatedInfo(val, 'applicant_info', 'applicant_status')
                        }
                        columnStyle='quarter'
                    >
                        <RadioOption
                            isChecked={applicantStatus == 'full-time'}
                            label='Full Time'
                            value='full-time'
                        />
                        <RadioOption label='Part Time' value='part-time' />
                        <RadioOption label='Casual' value='casual' />
                    </RadioComponent>
                </div>
                <div>
                    <label>Position:</label>
                    <TextComponent
                        formData={applicantPosition ?? undefined}
                        onBlur={(val) =>
                            createUpdatedInfo(
                                val,
                                'applicant_info',
                                'applicant_position',
                            )
                        }
                        name='verbal-applicant-position'
                    />
                </div>
            </div>
        </Card>
    );
};
export default VerbalApplicantCard;
