import {
    TextComponent,
    TextareaComponent,
    Switch,
    DateComponent,
    CKEditorComponent,
} from '@truenorthmortgage/olympus';
import { Card } from './card.component';
import { useEffect, useState } from 'react';

export interface MiscInfo {
    taxed_at_source: boolean;
    past_probation: boolean;
    probation_end_date: string | null;
    misc_notes: string | null;
    [key: string]: MiscInfo[keyof MiscInfo];
}

interface VerbalMiscCardProps {
    configData: [MiscInfo, string];
    helpers: CallableFunction[];
}

const VerbalMiscCard = ({
    configData: [miscInfo, probationOutput],
    helpers: [createUpdatedInfo],
}: VerbalMiscCardProps) => {
    const {
        taxed_at_source: taxedAtSource,
        past_probation: pastProbation,
        probation_end_date: probationEndDate,
        misc_notes: miscNotes,
    } = miscInfo;

    const [localDate, setLocalDate] = useState<string>(probationEndDate ?? '');

    useEffect(() => {
        createUpdatedInfo(localDate, 'misc_info', 'probation_end_date');
    }, [localDate]);

    return (
        <Card className='verbal-card' id='vulcan-misc'>
            <h3>Miscellaneous: </h3>
            <div className='vulcan-parent-div'>
                <div className='inline-horizontal'>
                    <div className='inline-vertical'>
                        <label>Taxed At Source:</label>
                        <label>Past Probation: </label>
                    </div>
                    <div className='inline-vertical'>
                        <div className='inline-horizontal'>
                            <label>No</label>
                            <Switch
                                defaultChecked={taxedAtSource}
                                onChange={() => createUpdatedInfo(!taxedAtSource, 'misc_info', 'taxed_at_source')}
                                id='verbal-taxed-at-source'
                            ></Switch>
                            <label>Yes</label>
                        </div>
                        <div className='inline-horizontal'>
                            <label>No</label>
                            <Switch
                                checked={pastProbation}
                                onChange={() =>
                                    createUpdatedInfo( !pastProbation, 'misc_info', 'past_probation')}
                                id='verbal-past-probation'
                            ></Switch>
                            <label>Yes</label>
                        </div>
                    </div>
                </div>
                {!pastProbation ? (
                    <div className='vulcan-parent-div'>
                        <div>
                            <label>Probation End Date:</label>
                            <DateComponent
                                defaultValue={localDate}
                                onChange={(date) => setLocalDate(date ?? '')}
                                name='verbal-applicant-hire-date'
                            />
                        </div>
                        <div>
                            <label>Output:</label>
                            <TextComponent formData={probationOutput} disabled={true} />
                        </div>
                    </div>
                ) : null}
                <div>
                    <label>Notes:</label>
                    <CKEditorComponent
                        value={miscNotes ?? undefined}
                        defaultValue={miscNotes ?? undefined}
                        onBlur={(val) => createUpdatedInfo(val, 'misc_info', 'misc_notes')}
                    />
                </div>
            </div>
        </Card>
    );
};
export default VerbalMiscCard;
