import { utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { timer, tap, zip, switchMap } from 'rxjs';
import TableHelper from '../../../components/table-helper/table-helper.component';
import { DocumentSpecialistDashboardResponse, DocumentSpecialistQueueItem } from '../../../models/deals/document-specialist';
import { SERVICES } from '../../../services';
import { DocumentSpecialistService } from '../../../services/document-specialist.service';
import { HttpService } from '../../../services/http.service';
import DocumentSpecialistQueue from './document-specialist-queue.component';
import { TableSchema } from '../../../models/schemas/table-schema';
import { ActionService } from '../../../services/action.service';
const DocumentSpecialistDashboard = () => {
    const dealSpecialistService = utils.injection.useInjection<DocumentSpecialistService>(SERVICES.DocumentSpecialistService);
    const actionService = utils.injection.useInjection<ActionService>(SERVICES.ActionService);
    const [dashboard, setDashboard] = useState<DocumentSpecialistDashboardResponse | null>(null);
    const [queue, setQueue] = useState<DocumentSpecialistQueueItem[] | null>(null);
    const [nextActions, setNextActions] = useState<TableSchema | null>(null);
    const dispatch = useDispatch();

    const loadData = useCallback(() => {
        return zip(
            dealSpecialistService.getDashboard(),
            dealSpecialistService.getQueue(),
            actionService.getTodaysActions()
        ).pipe(
            tap({
                next: ([dashboard, queue, nextActions]) => {
                    setDashboard(dashboard);
                    setQueue(queue);
                    setNextActions(nextActions);
                }
            })
        );
    }, [
        dispatch, setDashboard, setQueue, setNextActions, dealSpecialistService, actionService
    ]);

    const reloadData = useCallback((data?: {submit: boolean}) => {
        if (data?.submit) {
            HttpService.subscribe(loadData(), dispatch);
        }
    }, [loadData, dispatch]);

    useEffect(() => {
        const subscription = HttpService.subscribe(timer(0, 60000).pipe(switchMap(loadData)), dispatch);
        return () => {
            subscription.unsubscribe();
        };
    }, [loadData, dispatch]);

    return dashboard ? (
        <>
            <div className="col-12-12">
                <div className="col-8-12 col-small-12-12">
                    <div className="col-12-12 kill-the-padding">
                        <TableHelper schema={dashboard.content} includeHeader onModalClose={reloadData}/>
                    </div>
                </div>
                <div className="col-4-12 col-medium-4-12 kill-the-padding">
                    {nextActions ? <TableHelper schema={nextActions} className="no-left-padding" onModalClose={reloadData}/> : null }
                    <h2>
                        <FormattedMessage id="Document Specialist Queue"/>
                    </h2>
                    {queue && queue.length > 0 ? (
                        <DocumentSpecialistQueue items={queue} onModalClose={reloadData}/>) : (
                        <FormattedMessage id="Empty"/>)}
                </div>
            </div>
        </>
    ) : null;
};

export default DocumentSpecialistDashboard;
