import { pushNotification, utils, Document } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TableHelper from '../../components/table-helper/table-helper.component';
import { TableSchema } from '../../models/schemas/table-schema';
import { SERVICES } from '../../services';
import { DealService } from '../../services/deal.service';
import { VerbalerService } from '../../services/verbaler.service';
import { timer, switchMap, zip, tap } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { SelectComponent } from '../../models/schemas/form-schema';
import ComponentHelper from '../../components/component-helper/component-helper.component';

const VerbalerCompanies = () => {
    const dispatch = useDispatch();
    const [companiesTable, setCompaniesTable] = useState<TableSchema | null>(null);
    const [filterComponent, setFilterComponent] = useState<SelectComponent | null>(null);
    const dealsService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const verbalerService = utils.injection.useInjection<VerbalerService>(SERVICES.VerbalerService);
    const [filters, setFilters] = useState<{[name: string]: string | null}>({});

    const loadData = useCallback(() => {
        return zip(
            verbalerService.getCompaniesTable(filters),
        ).pipe(
            tap({
                next: ([companies_table]) => {
                    setFilterComponent(companies_table[0]);
                    setCompaniesTable(companies_table[1]);
                },
                error: e => {
                    dispatch(pushNotification({ class: 'error', message: e.toString() }));
                }
            })
        );
    }, [
        filters, dispatch, setCompaniesTable,
        verbalerService, dealsService,
    ]);

    const reloadData = useCallback((data?: { submit: boolean }) => {
        if (data?.submit) {
            HttpService.subscribe(loadData(), dispatch);
        }
    }, [loadData, dispatch]);

    useEffect(() => {
        const subscription = HttpService.subscribe(timer(0, 60000).pipe(switchMap(loadData)), dispatch);
        return () => {
            subscription.unsubscribe();
        };
    }, [loadData, dispatch]);

    const onChangeHandler = useCallback((value: string | null) => {
        if (filters.filter_type !== value) {
            setFilters({filter_type: value});
        }
        
    }, [filters]);

    return companiesTable && filterComponent ? 
        <>
            <div className='single-filter'>
                <ComponentHelper schema={filterComponent} onChange={onChangeHandler} />
            </div>
            <br></br>
            <TableHelper schema={companiesTable} includeHeader={true} onModalClose={reloadData} />
        </>
        : null;
    
};

export default VerbalerCompanies;
