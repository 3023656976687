import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionButton, ActionButtons, ActionDropdown, ActionHeader, ActionOption, ModalActionButton, pushNotification, utils } from '@truenorthmortgage/olympus';
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ActionHeaderSchema } from '../../models/schemas/action-header';
import { FormSchema } from '../../models/schemas/form-schema';
import { SERVICES } from '../../services';
import { ComponentService } from '../../services/component.service';
import { HttpService } from '../../services/http.service';
import ContainerModal from '../container-modal/container-modal.component';

export interface ActionHeaderHelperProps {
    schema: ActionHeaderSchema;
    useMargin?: boolean;
    buttonPosition?: 'left' | 'right';
    onModalClose?: (data?: any) => void;
}

const ActionHeaderHelper: FC<ActionHeaderHelperProps> = ({ schema, useMargin, buttonPosition = 'left', onModalClose }) => {
    const httpService = utils.injection.useInjection<HttpService>(SERVICES.HttpService);
    const componentService = utils.injection.useInjection<ComponentService>(SERVICES.ComponentService);
    const dispatch = useDispatch();

    const buttons = useMemo(() => {
        return (
            schema.action_buttons ? (
                <ActionButtons className={buttonPosition}>
                    {schema.action_buttons.map((button, index) => {
                        if (button.modal) {
                            return (<ModalActionButton
                                key={index}
                                text={button.label}
                                icon={button.icon}
                                disabled={button.disabled ?? false}
                                modalArgs={{
                                    onClose: onModalClose,
                                    modalHeader: button.modalHeader,
                                    loadProps: async () => ({
                                        data: await httpService.fetchJson<any>(button.uri)
                                    }),
                                }}
                                className={button.classes?.join(' ')}>
                                <ContainerModal />
                            </ModalActionButton>);
                        }

                        let callback = button.callback;
                        if (!callback && button.classes?.includes('link-trigger')) {
                            callback = () => {
                                componentService.executeLinkTriggerClick(button.data_array?.uri ?? '', dispatch, onModalClose);
                            };
                        }

                        return (<ActionButton
                            key={index}
                            redirectTo={button.uri}
                            callback={callback}
                            disabled={button.disabled ?? false}
                            className={button.classes?.join(' ')}>
                            {button.icon ? (<><FontAwesomeIcon icon={button.icon} /> {' '}</>) : null}
                            {button.label}
                        </ActionButton>);
                    })}
                </ActionButtons>
            ) : undefined

        );
    }, [schema.action_buttons, buttonPosition, onModalClose, httpService, dispatch]);

    return (
        <ActionHeader title={schema.title} subtitle={schema.sub_title} buttons={buttons} widgetClassName={useMargin ? '' : 'no-margin'} buttonPosition={buttonPosition}>
            {schema.action_items ? (
                <ActionDropdown inputText="Actions">
                    {schema.action_items.map((item, index) => (
                        <ActionOption key={index} modal={
                            item.type === '1'
                                ? {
                                    element: (<ContainerModal />),
                                    loadProps: async () => ({ data: await httpService.fetchJson<FormSchema>(item.uri) }),
                                    header: item.label
                                }
                                : item.modal
                        }>{item.label}</ActionOption>
                    ))}
                </ActionDropdown>
            ) : undefined}
        </ActionHeader>
    );
};

export default ActionHeaderHelper;
