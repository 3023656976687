import { Column } from '@truenorthmortgage/olympus';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from './card.component';
import './dropdown-search.scss';

export type DropdownData = Array<{uuid:string, consent:number, allows_verbal: number, name:string}>;
export interface DropdownSearchProps {
    onItemClick: (uuid: string) => void;
    helpers: CallableFunction[];
    data: DropdownData;
    initial_value?: string;
}

// this component was built with vulcan/verbaler system in mind. I believe it could be generalized within olympus if we decide we want to do it
const DropdownSearch = ({data: data, onItemClick: onItemClick, initial_value: initial_value, helpers: [onNewValue]}: DropdownSearchProps) => {
    const [input_value, setInputValue]= useState('');
    const [dropdown_allowed, setIsDropdownAllowed]= useState(false);

    useEffect(() => {
        setInputValue(initial_value ?? '');
    }, [initial_value]);

    const [consent_required, allows_verbals, uuids, data_names] = useMemo(() => {
        const consent_arr = data.map((company) => company.consent);
        const allows_verbal_arr = data.map((company) => company.allows_verbal);
        const uuid_arr = data.map((company) => company.uuid);
        const names_arr = data.map((company) => company.name);
        return [consent_arr, allows_verbal_arr, uuid_arr, names_arr];
    }, [data]);

    // Event listener for clicking away
    const handleClick = useCallback((event: MouseEvent) => {
        if (event.button === 0 && dropdown_allowed) {
            setIsDropdownAllowed(false);
        }
    }, [dropdown_allowed]); 

    const onClickFunc = useCallback((uuid: string, name: string) => {
        onItemClick(uuid);
        setInputValue(name);
        setIsDropdownAllowed(false);
    }, [data_names, onItemClick]);

    // removing the event listener on component unmount
    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [handleClick]);

    const isChanging = (search_param: string) => {
        setIsDropdownAllowed(true);
        setInputValue(search_param);
    };

    const generateDropdown = useCallback((value: string) => {
        const dropdown_items = [];
        for (let i = 0; i < data_names.length; i++) {
            if (!data_names[i].toLowerCase().includes(value.toLocaleLowerCase())) continue;

            // not completing verbals takes priority
            let message: string | null = null;
            if (allows_verbals[i] === 0) {
                message = '* Does not complete verbals';
            } else if (consent_required[i] === 1) {
                message = '* consent required';
            }

            dropdown_items.push(<div className='vulcan-search-item' onClick={() => onClickFunc(uuids[i], data_names[i])} key={`filtered-data-${i}`}><p>{data_names[i]}</p> <p className='consent'>{message}</p></div>);
        }

        if (dropdown_items.length === 0) {
            setIsDropdownAllowed(false);
            return null;
        }

        return (
            <Card className='vulcan-search-dropdown'>
                {dropdown_items}
            </Card>
        );
    }, [input_value, data_names, allows_verbals]);

    return (
        <div className='vulcan-search'>
            <div className='vulcan-search-container' >
                <Column columnStyle='text'>
                    <input value={input_value} type='text' onClick={() => setIsDropdownAllowed(true)} onChange={(event) => isChanging(event.target.value)} onBlur={(event) => onNewValue(event.target.value)}></input>
                    {(dropdown_allowed) ? generateDropdown(input_value) : null}
                </Column>
            </div>

        </div>
    );
};



export default DropdownSearch;
