import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UsersService } from '../../../services/users.service';
import TableHelper from '../../../components/table-helper/table-helper.component';
import { TableSchema } from '../../../models/schemas/table-schema';
import { SERVICES } from '../../../services';
import { ActionHeaderSchema } from '../../../models/schemas/action-header';
import ActionHeaderHelper from '../../../components/action-header-helper/action-header-helper.component';

export interface SecurityProps {}

const Security: FC<SecurityProps> = () => {
    const usersService = utils.injection.useInjection<UsersService>(SERVICES.UsersService);
    const [security, setSecurity] = useState<[ActionHeaderSchema, TableSchema] | null>(null);
    const dispatch = useDispatch();

    const loadData = useCallback(() => {
        const subscription = usersService.getSecurity().subscribe({
            next: setSecurity,
            error: (e) => {
                dispatch(
                    pushNotification({ class: 'error', message: e.toString() })
                );
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [
        usersService, setSecurity, dispatch,
    ]);

    const reloadData = useCallback((data?: {submit: boolean}) => {
        if (data?.submit) {
            loadData();
        }
    }, [loadData]);

    useEffect(loadData, [loadData]);

    return security ? (
        <>
            <ActionHeaderHelper schema={security[0]} useMargin={true} buttonPosition="right" onModalClose={reloadData} />
            <TableHelper schema={security[1]} includeHeader={true} onModalClose={reloadData} />
        </>
    ) : null;
};

export default Security;
