import { createSelector } from '@reduxjs/toolkit';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Session } from '../models/auth/session';

export interface RollbarProvider {
    children: ReactNode
}

const selectSession = createSelector(
    (state: any) => state.session,
    (session: Session | null) => session
);

const RollbarProvider: FC<RollbarProvider> = props => {
    const [rollbarProvider, setRollbarProvider] = useState(<>{props.children}</>);
    const session = useSelector<any, Session | null>((state) => selectSession(state));
    
    useEffect(() => {
        if (session !== null) {
            if (session.rollbar_config.env === 'dev') {
                return;
            }

            const rollbarConfig = {
                accessToken: session.rollbar_config.access_token,
                captureUncaught: true,
                captureUnhandledRejections: true,
                payload: {
                    person: {
                        id: session.user.id,
                        email: session.user.email
                    },
                    environment: session.rollbar_config.env,
                    client: {
                        javascript: {
                            code_version: '1.0.0',
                            source_map_enabled: true
                        }
                    }
                }
            };

            setRollbarProvider(
                <Provider config={rollbarConfig}>
                    <ErrorBoundary>{props.children}</ErrorBoundary>
                </Provider>
            );
        }
    }, [session]);

    return rollbarProvider;
};

export default RollbarProvider;
