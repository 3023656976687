import { ModalActionButton, pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SERVICES } from '../../../services';
import { DealService } from '../../../services/deal.service';
import DealHeader from '../../../components/deal-header/deal-header.component';
import { DealHeader as DealHeaderModel } from '../../../models/deals/header';
import { zip } from 'rxjs';
import { FormSchema } from '../../../models/schemas/form-schema';
import ContainerModal from '../../../components/container-modal/container-modal.component';
import { HttpService } from '../../../services/http.service';
import { TableSchema } from '../../../models/schemas/table-schema';
import TableHelper from '../../../components/table-helper/table-helper.component';
import { ActionButton } from '../../../models/accordions/action-button';

const DealConditions = () => {
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const httpService = utils.injection.useInjection<HttpService>(SERVICES.HttpService);
    const [dealHeader, setDealHeader] = useState<DealHeaderModel | null>(null);
    const [data, setData] = useState<[TableSchema, ActionButton, ActionButton, ActionButton, TableSchema] | null>(null);
    const dispatch = useDispatch();
    const params = utils.nav.useAllPossibleParams();

    const loadData = useCallback(() => {
        if (params.deal_id) {
            const subscription = zip(
                dealService.getDealConditions(params.deal_id),
                dealService.getHeader(params.deal_id)
            ).subscribe({
                next: ([data, dealHeader]) => {
                    setDealHeader(dealHeader);
                    setData(data);
                },
                error: (e) => {
                    dispatch(
                        pushNotification({class: 'error', message: e.toString()})
                    );
                }
            });
            return () => {
                subscription.unsubscribe();
            };
        }
    }, [dealService, dispatch, params.deal_id]);

    const modalClosed = (data?: { submit: boolean }) => {
        if (data?.submit) {
            loadData();
        }
    };

    useEffect(loadData, [loadData]);

    return dealHeader && data ?
        <>
            <DealHeader header={dealHeader}/>
            <div className={'widget'}>
                <ModalActionButton
                    text={data[2].label}
                    className={data[2].classes}
                    disabled={data[2].disabled}
                    modalArgs={{
                        loadProps: async () => ({
                            data: await httpService.fetchJson<FormSchema>(data[2].uri)
                        }),
                        onClose: modalClosed
                    }}>
                    <ContainerModal/>
                </ModalActionButton>
                <ModalActionButton
                    text={data[1].label}
                    className={data[1].classes}
                    disabled={data[1].disabled}
                    modalArgs={{
                        loadProps: async () => ({
                            data: await httpService.fetchJson<FormSchema>(data[1].uri)
                        }),
                        onClose: modalClosed
                    }}>
                    <ContainerModal/>
                </ModalActionButton>
                {data[3] ?
                    <ModalActionButton
                        text={data[3].label}
                        className={data[3].classes}
                        disabled={data[3].disabled}
                        modalArgs={{
                            loadProps: async () => ({
                                data: await httpService.fetchJson<FormSchema>(data[3].uri)
                            }),
                            onClose: modalClosed
                        }}>
                        <ContainerModal/>
                    </ModalActionButton>
                    : null}
            </div>
            <TableHelper includeHeader={true} schema={data[0]} onModalClose={modalClosed}/>
            {data[4] ? <TableHelper includeHeader={true} schema={data[4]} onModalClose={modalClosed}/> : null}
        </> : null;
};

export default DealConditions;
