import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { SERVICES } from '../../../services';
import { DealService } from '../../../services/deal.service';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { TableSchema } from '../../../models/schemas/table-schema';
import TableHelper from '../../../components/table-helper/table-helper.component';
import { DealHeader as DealHeaderSchema } from '../../../models/deals/header';
import DealHeader from '../../../components/deal-header/deal-header.component';
import './deal-concierge.component.scss';
import NoData from '../../default/no-data.component';

export interface DealConciergeProps {
    header: DealHeaderSchema,
    properties: PropertyProps[],
    concierge_loan_notes: TableSchema,
    investor_notes: TableSchema
}
export interface PropertyProps {
    header: DealHeaderSchema,
    values: TableSchema,
    mortgages: TableSchema,
}
const DealConcierge = () => {
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const dispatch = useDispatch();
    const params = utils.nav.useAllPossibleParams();
    const [conciergeLoan, setConciergeLoan] = useState<DealConciergeProps>();

    useEffect(() => {
        document.title = 'Deal ' + params.deal_id;
        
        return () => {
            document.title = 'True North Mortgage - Zeus';
        };
    }, [params.deal_id]);
    
    const loadData = () => {
        if (params.deal_id) {
            const subscribe = dealService.getConciergeLoanData(params.deal_id)
                .subscribe({
                    next: setConciergeLoan,
                    error: e => {
                        dispatch(pushNotification({ class: 'error', message: e.toString() }));
                    }
                });

            return () => {
                subscribe.unsubscribe();
            };
        }
    };

    const reloadData = useCallback((data?: {submit: boolean}) => {
        if (data?.submit) {
            loadData();
        }
    }, [loadData]);

    useEffect(loadData, [params.deal_id]);

    return conciergeLoan ?
        <>
            <DealHeader header={conciergeLoan.header} onModalClose={reloadData} />
            <div className='widget'>
                <div className='deal-header'>
                    <h2>Properties</h2>
                </div>
            </div>
            { conciergeLoan.properties.map((property, index) => {
                return (
                    <div key={index}>
                        <DealHeader header={property.header} onModalClose={reloadData} />
                        <div className='property property-wrapper'>
                            <div className='property property-table'>
                                <TableHelper schema={property.values} includeHeader={true} onModalClose={reloadData} />
                            </div>
                        
                            <div className='property property-table'>
                                <TableHelper schema={property.mortgages} includeHeader={true} onModalClose={reloadData} />
                            </div>
                        </div>
                    </div>
                );
            }) }
            <div className='widget'>
                <div className='deal-header'>
                    <h2>Concierge Loan Notes</h2>
                </div>
            </div>
            <TableHelper schema={conciergeLoan.concierge_loan_notes} includeHeader={true} onModalClose={reloadData} />
            <div className='widget'>
                <div className='deal-header'>
                    <h2>Investor Notes</h2>
                </div>
            </div>
            <TableHelper schema={conciergeLoan.investor_notes} includeHeader={true} onModalClose={reloadData} />
        </>
        : <NoData />;
};

export default DealConcierge;
