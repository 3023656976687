import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ActionHeaderHelper from '../../components/action-header-helper/action-header-helper.component';
import TableHelper from '../../components/table-helper/table-helper.component';
import { ActionHeaderSchema } from '../../models/schemas/action-header';
import { TableSchema } from '../../models/schemas/table-schema';
import { SERVICES } from '../../services';
import { FunderService } from '../../services/funder.service';
import { SelectComponent as SelectComponentProps } from '../../models/schemas/form-schema';
import ComponentHelper from '../../components/component-helper/component-helper.component';

const FundersManagement = () => {
    const funderService = utils.injection.useInjection<FunderService>(SERVICES.FunderService);
    const [data, setData] = useState<[ActionHeaderSchema, TableSchema, SelectComponentProps, TableSchema] | null >(null);
    const [dateRange, setDateRange] = useState<string | null>('0');
    const dispatch = useDispatch();

    const loadData = useCallback(() => {
        return funderService.getFunderManagement(dateRange).subscribe({
            next: setData,
            error: (e) => {
                dispatch(
                    pushNotification({ class: 'error', message: e.toString() })
                );
            }
        });
    }, [setData, dateRange]);

    const reloadData = useCallback((data?: {submit: boolean}) => {
        if (data?.submit) {
            loadData();
        }
    }, [loadData]);

    useEffect(() => {
        const subscription = loadData();
        
        return () => {
            subscription.unsubscribe();
        };
    }, [funderService, dispatch, setData, dateRange]);

    const handleNewDateRange = useCallback((new_date: string | null) => {
        setDateRange(new_date);
    }, []);

    return data && dateRange ? (
        <>
            <ActionHeaderHelper schema={data[0]} />
            <TableHelper
                schema={data[1]}
                includeHeader={true}
                onModalClose={reloadData}
            />
            <div className="widget no-margin">
                <ComponentHelper schema={data[2]} onChange={handleNewDateRange}/>
            </div> 
            <TableHelper
                schema={data[3]}
                includeHeader={true}
                onModalClose={reloadData}
            />
        </>
    ) : null;
};

export default FundersManagement;
