import { FC, useMemo } from 'react';
import { utils, ActionButton, ActionButtonProps, ActionButtons, ModalActionButton, ModalActionButtonProps, CheckboxComponent, CheckboxOption } from '@truenorthmortgage/olympus';
import './checkbox-accordion-item.component.scss';

export type CheckboxAccordionItemProps = {
    id?: string;
    label?: string;
    value?: string;
    className?: string;
    checked?: boolean;
    disabled?: boolean;
    onCheckChange?: (checked: string | boolean | null) => void;
    buttons?: ActionButtonProps[] | ModalActionButtonProps[];
    alignContent?: string;
};

const CheckboxAccordionItem: FC<CheckboxAccordionItemProps> = ({ id, label, value, className = '', checked = false, disabled = false, onCheckChange = undefined, buttons = [], alignContent = 'left' }) => {
    const escapedValue = useMemo(() => {
        return value ? utils.html.doSanitize(utils.html.nl2br(value)) : undefined;
    }, [value]);

    const buttonElements = useMemo(() => {
        return buttons ? (
            <ActionButtons className=''>
                {buttons.map((buttonConfig, index) => {
                    if (buttonConfig.type === utils.button.ButtonType.Modal) {
                        return <ModalActionButton {...buttonConfig} key={buttonConfig.key} />;
                    }
                    const simpleConfig = buttonConfig as ActionButtonProps;
                    return <ActionButton {...simpleConfig} key={simpleConfig.key || index} />;
                })}
            </ActionButtons>
        ) : null;
    }, [buttons]);

    return (
        <div id={id} className={`accordion-item ${className}`}>
            <div className='accordion-item-wrapper'>
                {onCheckChange && 
                    <CheckboxComponent name={'accepted'} value={checked ? 'true' : 'false'} onChange={e => {onCheckChange(e);}} >
                        <CheckboxOption value={'true'} disabled={disabled} className={disabled ? 'disabled' : ''} />
                    </CheckboxComponent>
                }
                <div className={`accordion-item-inner ${alignContent}`}>
                    <p className='accordion-item-label'>{label}</p>
                    <p className='accordion-item-value' dangerouslySetInnerHTML={escapedValue} />
                    {buttons && buttons.length > 0 ? (
                        <div className='accordion-item-actions'>
                            {buttonElements}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default CheckboxAccordionItem;
