import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TableHelper from '../../../components/table-helper/table-helper.component';
import { TableSchema } from '../../../models/schemas/table-schema';
import { SERVICES } from '../../../services';
import { UnderwritingService } from '../../../services/underwriting.service';

const ThinkerPods = () => {
    const underwritingService = utils.injection.useInjection<UnderwritingService>(SERVICES.UnderwritingService);
    const dispatch = useDispatch();
    const [schema, setSchema] = useState<TableSchema | null>(null);
    
    const loadData = useCallback(() => {
        const subscribe = underwritingService.getThinkerPods()
            .subscribe({
                next: setSchema,
                error: e => {
                    dispatch(pushNotification({ class: 'error', message: e.toString() }));
                }
            });

        return () => {
            subscribe.unsubscribe();
        };
    }, [underwritingService, setSchema, dispatch]);

    const reloadData = useCallback((data?: {submit: boolean}) => {
        if (data?.submit) {
            loadData();
        }
    }, [loadData]);

    useEffect(loadData, []);

    return schema ? <TableHelper schema={schema} includeHeader={true} onModalClose={reloadData} /> : null;
};

export default ThinkerPods;

