import { BasicModalHandle, pushNotification, utils } from '@truenorthmortgage/olympus';
import { FC, RefObject, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import FormHelper from '../components/form-helper/form-helper.component';
import { FormSchema } from '../models/schemas/form-schema';

export interface UpgradePremierThinkerFormProps {
    schema: FormSchema | [{ content: string }, FormSchema];
    parentRef?: RefObject<BasicModalHandle>;
    onSubmit: (data: any) => void;
}
const UpgradePremierThinkerForm: FC<UpgradePremierThinkerFormProps> = ({ schema, onSubmit, parentRef }) => {
    const [remainder, setRemainder] = useState<number>(120);
    const intl = useIntl();
    const dispatch = useDispatch();
    
    const [html, data] = useMemo(() => {
        const [html, data] = Array.isArray(schema)
            ? [schema[0].content, schema[1]]
            : [null, schema];
        return [html, data];
    }, [schema]);

    const timer = useMemo(() => {
        const totalMinutes = 2;
        const minutes = Math.floor((remainder % (60 * 60)) / 60);
        const seconds = Math.floor(remainder % 60);
        return { minutes: totalMinutes, timer: `${minutes}m ${seconds}s` };
    }, [remainder]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (remainder === 0) {
                parentRef?.current?.close();
                dispatch(pushNotification({
                    class: 'error',
                    message: intl.formatMessage({
                        id: 'Out of time to submit at the current Premier price, \'Upgrade\' again for a new price.'
                    })
                }));
            } else {
                setRemainder(prevRemainer => prevRemainer - 1);
            }
            
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [remainder]);

    return (
        <>
            {html ? (
                <div>
                    <p dangerouslySetInnerHTML={utils.html.doSanitize(html)} />
                </div>
            ) : null}
            <p>
                <FormattedMessage
                    id={
                        '(Upgrade price is valid for {minutes} minutes: {timer} remaining)'
                    }
                    values={timer}
                />
            </p>
            <FormHelper schema={data} onSubmit={onSubmit} parentRef={parentRef} />
        </>
    );
};

export default UpgradePremierThinkerForm;
