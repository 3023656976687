import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvailabilityRadio } from '@truenorthmortgage/olympus';
import { FC } from 'react';
import { DealsQueueData } from '../../models/deals/deals-queue';
import CloserQueue from './closer-queue.component';
import ThinkerQueue from './thinker-queue.component';

export interface DealsQueueProps {
    className?: string;
    status: DealsQueueData;
}

const DealsQueue: FC<DealsQueueProps> = ({ className, status }) => {
    return (
        <div className={className}>
            {status.include_thinker_queue ? (
                <div className="widget no-margin">
                    <div className="widget-inner">
                        <div className="thinker-queue-wrapper">
                            <h2>
                                <span className="tooltip">
                                    Thinker Queue {' '}
                                    <i><FontAwesomeIcon icon="eye" /></i>
                                    <span className="tooltip-table">Thinker Deals In Progress
                                        <table className="faux-table table-builder">
                                            <tbody>
                                                {status.data.thinker_progress.map((value, index) => (
                                                    <tr className="faux-row" key={index}>
                                                        <td className="faux-cell">{value.thinker_pod_name}</td>
                                                        <td className="faux-cell">{value.available != undefined && <AvailabilityRadio isAvailable={value.available} isRelative={true} />}</td>
                                                        <td className="faux-cell">{value.first_name} {value.last_name}</td>
                                                        <td className="faux-cell">T:{value.deals_in_progress}</td>
                                                        <td className="faux-cell">E:{value.economy_in_progress}</td>
                                                        <td className="faux-cell">P:{value.premier_in_progress}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </span>
                                </span>
                            </h2>

                            <div className="queue-status">
                                <ThinkerQueue type="premier" deals={status.data.premier} status={status} />
                                <ThinkerQueue type="economy" deals={status.data.economy} status={status} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {status.include_closer_queue ? (
                <div className="widget no-margin">
                    <div className="widget-inner">
                        <CloserQueue closer={status.data.closer} status={status} />
                    </div>
                </div>
            ) : null}
        </div>

    );
};

export default DealsQueue;
