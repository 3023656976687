import React from 'react';
import './card-component.scss';

type CardProps = {
    id?: string;
    className?: string;
    children?: React.ReactElement | React.ReactElement[];
};

export function Card(props: CardProps) {
    const content = props.children ? props.children : <br />;
    return (
        <div className={'vulcan-card ' + props.className} id={props.id}>
            {content}
        </div>
    );
}
