import { BasicModal, BasicModalHandle, pushNotification, utils } from '@truenorthmortgage/olympus';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import ComponentHelper from '../../../components/component-helper/component-helper.component';
import ContainerModal from '../../../components/container-modal/container-modal.component';
import { FormSchema } from '../../../models/schemas/form-schema';
import { SERVICES } from '../../../services';
import { DealService } from '../../../services/deal.service';
import { HttpService } from '../../../services/http.service';
import { SelectComponent } from '../../../models/schemas/form-schema';
import { useDispatch } from 'react-redux';

export interface DealStatusModalProps {
    select: SelectComponent;
    onSubmit: (data: any) => void;
}

export interface DealStatusModalHandle {
    open: () => void;
}

const DealStatusModal: FC<DealStatusModalProps> = ({ onSubmit, select }) => {
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const httpService = utils.injection.useInjection<HttpService>(SERVICES.HttpService);
    const modalRef = useRef<BasicModalHandle>(null);
    const [url, setUrl] = useState<string>();
    const [selectSchema, setSelectSchema] = useState<SelectComponent>(select);
    const [previous, setPrevious] = useState<string>(select.value);
    const [preventUpdate, setPreventUpdate] = useState<boolean>(false);
    const dispatch = useDispatch();

    const loadProps = useCallback(async () => {
        return { data: await httpService.fetchJson<FormSchema>(url ?? '') };
    }, [url, httpService]);

    useEffect(() => {
        if (!preventUpdate) {
            setSelectSchema(Object.assign({}, select));
        }
    }, [select, preventUpdate, setSelectSchema]);

    useEffect(() => {
        if (url) {
            modalRef.current?.open();
        }
    }, [modalRef, url]);

    const changeDealStatus = useCallback((value: string | null) => {
        if (value && ['3', '4', '5'].includes(value)) {
            if (value === '3' || value === '5') {
                setUrl(`/documents/set-status-details?document_id=${selectSchema.data.document_id}&closer_status=${value}`);
            } else if (value === '4') {
                setUrl(`/documents/confirm-document-not-needed?document_id=${selectSchema.data.document_id}`);
            }
        } else if (value && value !== previous) {
            setPrevious(value);
            dealService
                .setCloserStatus({ document_id: selectSchema.data.document_id, closer_status: value })
                .then(onSubmit)
                .catch(e => {
                    dispatch(pushNotification({ class: 'error', message: e.toString() }));
                });
        }
    }, [dealService, previous, selectSchema.data.document_id, onSubmit, modalRef]);

    const onModalClose = useCallback((data: any) => {
        setUrl(undefined);
        if (data && data.submit) {
            onSubmit(data);
            setPreventUpdate(true);
        } else if (typeof data === 'undefined' || data.cancel) {
            setPreventUpdate(false);
            setSelectSchema(schema => {
                schema.value = '';
                return Object.assign({}, schema);
            });

            setTimeout(() => {
                setSelectSchema(schema => {
                    schema.value = previous;
                    return Object.assign({}, schema);
                });
            }, 0);
        }
    }, [onSubmit, previous, select, setPreventUpdate, setSelectSchema]);

    return (
        <>
            <ComponentHelper schema={selectSchema} onChange={changeDealStatus} />
            <BasicModal loadProps={loadProps} ref={modalRef} onClose={onModalClose}>
                <ContainerModal />
            </BasicModal>
        </>
    );
};

export default DealStatusModal;
