import { injectable } from 'inversify';
import { HttpService } from './http.service';

@injectable()
export class NotificationService extends HttpService {
    async pushNotification() {
        const getPubKey = async () => {
            const pubKey = await this.fetchJson('/users/get-push-public-key');

            return pubKey;
        };

        if ('serviceWorker' in navigator) {
            const serviceWorkerRegistration = await navigator.serviceWorker.register('/assets/js/serviceWorker.js');
            console.log('[SW] Service worker has been registered');
            getPubKey().then((applicationServerKey) => {
                this.push_subscribe(applicationServerKey, serviceWorkerRegistration);
            });
        }
    }

    urlBase64ToUint8Array(base64String: string) {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        // eslint-disable-next-line no-useless-escape
        const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    checkNotificationPermission() {
        return new Promise<void>((resolve, reject) => {
            if (Notification.permission === 'denied') {
                // The user denied the notification permission which
                // means we failed to subscribe and the user will need
                // to manually change the notification permission to
                // subscribe to push messages
                return reject(new Error('Push messages are blocked.'));
            }

            if (Notification.permission === 'granted') {
                return resolve();
            }

            if (Notification.permission === 'default') {
                return Notification.requestPermission().then(result => {
                    if (result !== 'granted') {
                        reject(new Error('Bad permission result'));
                    }

                    resolve();
                });
            }
            else {
                // A problem occurred with the subscription; common reasons
                // include network errors or the user skipped the permission
                reject(new Error('Network error or user skipped permission request'));
            }
        });
    }

    push_subscribe(applicationServerKey: string, serviceWorkerRegistration: ServiceWorkerRegistration) {
        return this.checkNotificationPermission()
            .then(() =>
                serviceWorkerRegistration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: this.urlBase64ToUint8Array(applicationServerKey),
                })
            )
            .then((subscription: PushSubscription) => {
                // Subscription was successful
                // create subscription on your server
                return this.push_sendSubscriptionToServer(subscription, 'POST');
            })
            .then((subscription: PushSubscription) => subscription) // update your UI
            .catch(e => {
                console.error('error subscribing to push notifications', e);
            });
    }

    push_sendSubscriptionToServer(subscription: PushSubscription, method: string) {
        const key = subscription.getKey('p256dh');
        const token = subscription.getKey('auth');
        const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];

        return this.fetchJson('/users/push-subscription', 'POST', {
            'body': JSON.stringify({
                'endpoint': subscription.endpoint,
                'publicKey': key ? btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(key)))) : null,
                'authToken': token ? btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(token)))) : null,
                'contentEncoding': contentEncoding,
            })
        });
    }
}
