import { inject } from 'inversify';
import { Observable } from 'rxjs';
import { SERVICES } from '.';
import { TableSchema } from '../models/schemas/table-schema';
import { DealService } from './deal.service';
import { HttpService } from './http.service';

export class CloserDealsService extends HttpService {
    @inject(SERVICES.DealService) protected dealService: DealService | null = null;
    
    getCloserDeals(account_uuid: string | null = null): Observable<TableSchema> {
        const param = account_uuid !== null ? '?account_uuid=' + account_uuid : '';
        return this.fetchJsonObservable<TableSchema>('/closer/deals' + param);
    }

    getClosableDeals(): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>('/closer/closable-deals');
    }

    getPodCloserDeals(): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>('/closer/my-thinker-pod-closing-deals');
    }
}
