import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TableHelper from '../../components/table-helper/table-helper.component';
import { TableSchema } from '../../models/schemas/table-schema';
import { SERVICES } from '../../services';
import { CloserDealsService } from '../../services/closer-deals.service';
import { DealService } from '../../services/deal.service';
import { VerbalerService } from '../../services/verbaler.service';
import { timer, switchMap, zip, tap } from 'rxjs';
import { HttpService } from '../../services/http.service';

const VerbalerOverview = () => {
    const dispatch = useDispatch();
    const [priorityInboxRows, setPriorityInboxRows] = useState<TableSchema | null>(null);
    const [VerbalRequestsTables, setVerbalRequestsTables] = useState<TableSchema[] | null>(null);
    const dealsService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const verbalerService = utils.injection.useInjection<VerbalerService>(SERVICES.VerbalerService);
    const closerDealsService = utils.injection.useInjection<CloserDealsService>(SERVICES.CloserDealsService);

    const loadData = useCallback(() => {
        return zip(
            verbalerService.getPriorityInbox(),
            verbalerService.getVerbalerRequests(),
        ).pipe(
            tap({
                next: ([priorityInboxRows, verbalTables]) => {
                    setPriorityInboxRows(priorityInboxRows);
                    setVerbalRequestsTables(verbalTables);
                },
                error: e => {
                    dispatch(pushNotification({ class: 'error', message: e.toString() }));
                }
            })
        );
    }, [
        dispatch, setPriorityInboxRows, verbalerService, dealsService, closerDealsService,
    ]);

    useEffect(() => {
        const subscription = HttpService.subscribe(loadData(), dispatch);
        return () => {
            subscription.unsubscribe();
        };
    }, [loadData, dispatch]);

    const reloadData = useCallback((data?: { submit: boolean }) => {
        if (data?.submit) {
            HttpService.subscribe(loadData(), dispatch);
        }
    }, [loadData, dispatch]);


    return (priorityInboxRows && VerbalRequestsTables) ?
        <>
            <TableHelper schema={priorityInboxRows} includeHeader={true} onModalClose={(data) => reloadData(data)} />
            {VerbalRequestsTables.map((schema, index) => <TableHelper key={index} schema={schema} includeHeader={true} onModalClose={(data) => reloadData(data)}/>)}
        </>
        : null;
};

export default VerbalerOverview;
