import { injectable } from 'inversify';
import { DocumentSpecialistDashboardResponse, DocumentSpecialistQueueItem } from '../models/deals/document-specialist';
import { HttpService } from './http.service';

@injectable()
export class DocumentSpecialistService extends HttpService {
    getDashboard(): Promise<DocumentSpecialistDashboardResponse> {
        return this.fetchJson<DocumentSpecialistDashboardResponse>('/deals/document-specialist-dashboard');
    }

    getQueue(): Promise<DocumentSpecialistQueueItem[]> {
        return this.fetchJson<DocumentSpecialistQueueItem[]>('/deals/ajax-document-specialist-queue');
    }

    getClaimModal(id: number) {
        return this.fetchJson<DocumentSpecialistQueueItem[]>(`/deals/claim-doc-specialist-deal?entry_id=${id}`);
    }
}
