import { pushNotification, utils, Widget } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormHelper from '../../../components/form-helper/form-helper.component';
import { FormSchema } from '../../../models/schemas/form-schema';
import { SERVICES } from '../../../services';
import { SubmitToThinkService } from '../../../services/submit-to-think.service';

const Start = () => {
    const [form, setForm] = useState<FormSchema | null>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const submitToThinkService = utils.injection.useInjection<SubmitToThinkService>(SERVICES.SubmitToThinkService);

    useEffect(() => {
        const subscription = submitToThinkService.getStart()
            .subscribe({
                next: setForm,
                error: e => {
                    dispatch(pushNotification({ class: 'error', message: e.toString() }));
                }
            });
        
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const onSubmit = useCallback(({uri}: {uri: string}) => {
        navigate(uri);
    }, [navigate]);

    return form ? (
        <Widget>
            <FormHelper schema={form} onSubmit={onSubmit} />
        </Widget>
    ) : null;
};

export default Start;
