import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ComponentHelper from '../../components/component-helper/component-helper.component';
import TableHelper from '../../components/table-helper/table-helper.component';
import { SelectComponent } from '../../models/schemas/form-schema';
import { TableSchema } from '../../models/schemas/table-schema';
import { SERVICES } from '../../services';
import { ReportService } from '../../services/report.service';

const ThinkerBonusReport = () => {
    const reportService = utils.injection.useInjection<ReportService>(SERVICES.ReportService);
    const [reportData, setReportData] = useState<[TableSchema, SelectComponent[] | undefined]>();
    const [filters, setFilters] = useState<{[name: string]: string | null}>({});
    const dispatch = useDispatch();
    
    const loadData = useCallback(() => {
        const subscription = reportService.getThinkerBonusReport(filters).subscribe({
            next: setReportData,
            error: (e) => {
                dispatch(
                    pushNotification({ class: 'error', message: e.toString() })
                );
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [filters, reportService, setReportData, dispatch]);

    const reloadData = useCallback((data?: {submit: boolean}) => {
        if (data?.submit) {
            loadData();
        }
    }, [loadData]);

    useEffect(loadData, [loadData]);

    const onChangeHandler = useCallback((name: string | undefined) => (value: string | null) => {
        if (name !== undefined) {
            if (!(name in filters) || filters[name] !== value) {
                setFilters(prev => ({...prev, [name]: value}));
            }
        }
        
    }, [filters]);

    const filtersData: SelectComponent[] | undefined = useMemo(() => {
        if (reportData && typeof reportData[1] !== 'undefined') {
            return reportData[1].map(selectSchema => {
                return Object.assign({}, selectSchema, {
                    value: filters[selectSchema.name!] ?? selectSchema.value
                });
            });
        }
        return undefined;
    }, [reportData, filters]);

    return reportData ? (
        <>
            { filtersData ? 
                <div className="widget">
                    { filtersData.map((selectSchema, index) => (<ComponentHelper key={index} schema={selectSchema} onChange={onChangeHandler(selectSchema.name)} triggerOnLoad={true} />)) }
                </div> 
                : null }
            <TableHelper schema={reportData[0]} includeHeader={true} onModalClose={reloadData} />
        </>
    ) : null;
};

export default ThinkerBonusReport;
