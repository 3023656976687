import { pushNotification, utils, Document } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';
import TableHelper from '../../components/table-helper/table-helper.component';
import { TableSchema } from '../../models/schemas/table-schema';
import { SERVICES } from '../../services';
import { DealService } from '../../services/deal.service';
import { VerbalerService } from '../../services/verbaler.service';
import UpdateVerbalStatus from './update-verbal-status.component';
import { timer, switchMap, zip, tap } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { Card } from './card.component';
import { SelectComponent } from '../../models/schemas/form-schema';

const VerbalerRequest = () => {
    const dispatch = useDispatch();
    const [draftTables, setDraftTables] = useState<TableSchema[] | null>();
    const [cadmusTables, setCadmusTables] = useState<TableSchema[] | null>();
    const [supportingDocsTables, setSupportingDocsTables] = useState<TableSchema[] | null>();
    const [incomeTables, setIncomeTables] = useState<TableSchema[] | null>();
    const dealsService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const verbalerService = utils.injection.useInjection<VerbalerService>(SERVICES.VerbalerService);
    const params = utils.nav.useAllPossibleParams();


    // retrieving all request drafts
    const loadData = useCallback(() => {
        return zip(
            verbalerService.getVerbalerDrafts(params.deal_id),
        ).pipe(
            tap({
                next: ([verbalerDraftRows]) => {
                    // abstraction layer will assign the returned array to an object.
                    const recievedSchema = Object.values(verbalerDraftRows);
                    const localDraftTables: Array<Array<TableSchema>> = [[], [], [], []];
                    for (const table of recievedSchema) {
                        for (let i = 0; i < table.length; i++) {

                            let schema = table[i];
                            if (schema === null) continue;

                            // done to map the drop-down change to open a model
                            if (i === 0) {
                                schema = Object.assign({}, schema, {
                                    rows: schema.rows.map(row => {
                                        return Object.assign({}, row, {
                                            cells: row.cells.map(cell => {
                                                if (cell.type === 'raw' && typeof cell.value === 'object' && 'type' in cell.value && cell.value.type === 'select') {
                                                    return updateVerbalStatusComponent('custom-jsx', cell.value.data.draft_id, cell.value.data.deal_id, cell.value);
                                                }
                                                return cell;
                                            })
                                        });
                                    })
                                });
                            }

                            localDraftTables[i].push(schema);
                        }
                    }

                    setDraftTables(localDraftTables[0]);
                    setCadmusTables(localDraftTables[1]);
                    setSupportingDocsTables(localDraftTables[2]);
                    setIncomeTables(localDraftTables[3]);
                },
                error: e => {
                    dispatch(pushNotification({ class: 'error', message: e.toString() }));
                }
            })
        );
    }, [dispatch, setDraftTables, setCadmusTables, setSupportingDocsTables, setIncomeTables, verbalerService, dealsService, params.deal_id]);

    const reloadData = useCallback((data?: { submit: boolean }) => {
        if (data?.submit) {
            HttpService.subscribe(loadData(), dispatch);
        }
    }, [loadData, dispatch]);

    useEffect(() => {
        const subscription = HttpService.subscribe(timer(0, 60000).pipe(switchMap(loadData)), dispatch);
        return () => {
            subscription.unsubscribe();
        };
    }, [loadData, dispatch]);

    const updateVerbalStatusComponent = useCallback((type: string, draft_id: string, deal_id: string, schema: SelectComponent) => {
        return {
            type: type,
            element: (
                <UpdateVerbalStatus
                    draftId={draft_id}
                    dealId={deal_id}
                    schema={schema}
                    reloadData={reloadData} />
            )
        };
    }, [loadData, dispatch, draftTables]);

    if (!params.deal_id) {
        return (<Navigate to="/deals/overview" />);
    }

    return cadmusTables && cadmusTables && supportingDocsTables && draftTables && incomeTables ? (
        <>
            {
                // mapping over the draft tables as there is a guarantee to have 1 per request
                draftTables.map((_, index: number) => {
                    return <Card className='verbal-card' key={index}>
                        <TableHelper schema={draftTables[index]} includeHeader={true} onModalClose={reloadData} />
                        {(cadmusTables[index]) ? <TableHelper schema={cadmusTables[index]} includeHeader={true} onModalClose={reloadData} showTitleBar={false} /> : <></>}
                        <TableHelper schema={supportingDocsTables[index]} includeHeader={true} onModalClose={reloadData} showTitleBar={false} />
                        <TableHelper schema={incomeTables[index]} includeHeader={true} onModalClose={reloadData} showTitleBar={false} />
                    </Card>;
                }
                )
            }
        </>
    ) : <Document markdown='No Verbal Requests For This Deal.' />;
};

export default VerbalerRequest;
