import { BasicModalHandle, SelectComponent } from '@truenorthmortgage/olympus';
import { FC, RefObject, useCallback, useMemo, useState } from 'react';
import FormHelper from '../components/form-helper/form-helper.component';
import { FormSchema, SelectComponent as SelectComponentSchema } from '../models/schemas/form-schema';

export interface UpdateDealStatusFormProps {
    schema: FormSchema,
    onSubmit: (data: any) => void;
    parentRef?: RefObject<BasicModalHandle>;
}

export interface DealStatusDropdownProps {
    value: string,
    selectConfig: SelectComponentSchema;
    onChange: (data: any) => void;
}

const DealStatusDropdown: FC<DealStatusDropdownProps> = ({ value, selectConfig, onChange}) => {
    const { style, value: defaultValue, disabled, label, type, options} = selectConfig;

    return (
        <SelectComponent
            columnStyle={`${style ?? ''} ${type}`}
            onChange={onChange}
            defaultValue={defaultValue}
            value={value}
            disabled={disabled}
            label={label}
            options={options}>
        </SelectComponent>
    );
};

const UpdateDealStatusForm: FC<UpdateDealStatusFormProps> = ({ schema, onSubmit, parentRef }) => {
    const selectComponentSchema: SelectComponentSchema = schema.components.find(component => component.type === 'select') as SelectComponentSchema;
    const [dealStatus, setDealStatus] = useState<string>(selectComponentSchema.value);
    const [previous, setPrevious] = useState<string>(selectComponentSchema.value);

    const selectChangeHandler = useCallback((value: string) => {
        let confirm = true;
        if (value === 'lead') {
            confirm = window.confirm('Are you sure you want to revert this Deal to a Lead? The following fields will be cleared: Lender, Term, Submission or Hedged Date, 95%, 95% Date, Closing Date, Closing Month');
        } else if (value === 'cancel_think_deal') {
            confirm = window.confirm('Are you sure you want to Cancel this THINK Deal? All THINK Data associated to this Deal will be permanently deleted. Additionally, all Documents which have been shared will be become unshared. The active Thinker and Closer will be informed of this action.');
        } else {
            setPrevious(value);
        }

        setDealStatus(value);
        
        if (confirm === false) {
            setTimeout(() => {
                setDealStatus(previous);
            }, 0);
        }
    }, [previous]);

    const returnCustomPayload = useCallback(async () => {
        return {
            status: dealStatus
        };
    }, [dealStatus]);

    const formSchema = useMemo(() => {
        return Object.assign({}, schema, {
            components: schema.components.map(component => {
                if (component.type === 'select') {
                    return {
                        type: 'react',
                        element: (
                            <DealStatusDropdown value={dealStatus} selectConfig={component} onChange={selectChangeHandler} />
                        )
                    };
                }
                return component;
            })
        });

    }, [schema, dealStatus, selectChangeHandler]);

    return (
        <FormHelper schema={formSchema} onSubmit={onSubmit} parentRef={parentRef} onCustomPayload={returnCustomPayload} />
    );
};

export default UpdateDealStatusForm;
