import './App.scss';
import Default from './pages/default/default.component';

function App() {
    return (
        <div className="App">
            <Default />
        </div>
    );
}

export default App;
