import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { SERVICES } from '../../services';
import { FunderService } from '../../services/funder.service';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionHeaderSchema } from '../../models/schemas/action-header';
import { TableSchema } from '../../models/schemas/table-schema';
import ActionHeaderHelper from '../../components/action-header-helper/action-header-helper.component';
import TableHelper from '../../components/table-helper/table-helper.component';
import ComponentHelper from '../../components/component-helper/component-helper.component';
import { ComponentSchema } from '../../models/schemas/form-schema';

const FunderTaskQueue = () => {
    const funderService = utils.injection.useInjection<FunderService>(SERVICES.FunderService);
    const [data, setData] = useState<[ActionHeaderSchema, TableSchema, ComponentSchema] | null>(null);
    const [selected, setSelected] = useState<string | null>('');
    const dispatch = useDispatch();

    const loadData = useCallback(() => {
        const subscription = funderService.getFunderTaskQueue(selected).subscribe({
            next: setData,
            error: (e) => {
                dispatch(
                    pushNotification({class: 'error', message: e.toString()})
                );
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [funderService, selected, dispatch, setData]);

    useEffect(loadData, [loadData]);

    const modalClosed = (data?: { submit: boolean }) => {
        if (data?.submit) {
            loadData();
        }
    };

    return data ? (
        <>
            <ActionHeaderHelper schema={data[0]}/>
            {data[2] ? <ComponentHelper onChange={setSelected} schema={data[2]}/> : null}
            <TableHelper schema={data[1]} onModalClose={modalClosed} includeHeader={true}/>
        </>
    ) : null;
};

export default FunderTaskQueue;
