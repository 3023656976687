import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BasicModal, BasicModalHandle, Form, SelectComponent, utils, Widget } from '@truenorthmortgage/olympus';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import ContainerModal from '../../../components/container-modal/container-modal.component';
import { FormSchema } from '../../../models/schemas/form-schema';
import { SERVICES } from '../../../services';
import { DealService } from '../../../services/deal.service';
import { HttpService } from '../../../services/http.service';
import './deal-batch-update-modal.style.scss';

export interface DealBatchUpdateModalProps {
    documents?: { id: number, name: string }[];
    onClose: () => void;
    onSubmit: (data: any) => void;
}

const DealBatchUpdateModal: FC<DealBatchUpdateModalProps> = ({ documents, onClose, onSubmit }) => {
    const intl = useIntl();
    const httpService = utils.injection.useInjection<HttpService>(SERVICES.HttpService);
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const [options, setOptions] = useState<{ label: string, value: number }[]>();
    const [selection, setSelection] = useState<string>();
    const [url, setUrl] = useState<string>();
    const modalRef = useRef<BasicModalHandle>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const subscription = HttpService.subscribe(dealService.getDealDocumentsBatchUpdateOptions(), dispatch, setOptions);
        return () => {
            subscription.unsubscribe();
        };
    }, [dispatch]);

    const documentNames = useMemo(() => {
        const names = documents?.slice(0, 3).map(deal => deal.name).join(', ');
        if (documents && documents.length > 3) {
            return names + ' ' + intl.formatMessage({ id: '(+{count} more)' }, { count: documents.slice(3).length });
        }
        return names;
    }, [documents, intl]);

    useEffect(() => {
        if (url) {
            modalRef.current?.open();
        }
    }, [modalRef, url]);

    const loadProps = useCallback(async () => {
        return { data: await httpService.fetchJson<FormSchema>(url ?? '') };
    }, [url, httpService]);

    const onChange = useCallback((selection: string) => {
        if (documents && selection && selection !== 'default') {
            if (['3', '4', '5'].includes(selection)) {
                const document_ids = documents.map(document => document.id);
                if (selection === '3' || selection === '5') {
                    setUrl(`/documents/set-status-details?document_id=${documents[0].id}&closer_status=${selection}&document_ids=${document_ids.join(',')}`);
                } else if (selection === '4') {
                    setUrl(`/documents/confirm-document-not-needed?document_id=${documents[0].id}&document_ids=${document_ids.join(',')}`);
                }
            } else {
                const payload = {
                    document_ids: documents.map(doc => doc.id),
                    closer_status: selection
                };
                HttpService.subscribe(dealService.executeDealDocumentBatchUpdate(payload), dispatch, (message) => onSubmit({submit: true, message: message}));
            }
        }
    }, [dealService, documents, dispatch, selection, onSubmit]);

    const onModalClose = useCallback(() => {
        onSubmit({submit: true});
    }, [onSubmit]);

    const cancelBulkUpdate = useCallback(() => {
        onClose();
    }, []);

    return documents && documents.length > 0 && options ? (
        <>
            <div id='deal-batch-update-modal'>
                <div className="modal-header">
                    <div className='modal-close'>
                        <i>
                            <FontAwesomeIcon icon={'times'} onClick={cancelBulkUpdate}/>
                        </i>
                    </div>
                </div>
                <Widget>
                    <Form>
                        <div className='label'>
                            <FormattedMessage id='Selected documents:' />
                            {' '}
                            {documentNames}
                        </div>
                        <div className='selection'>
                            <SelectComponent onChange={onChange} triggerOnLoad>
                                <option value={'default'}>- Select Status -</option>
                                {options.map(option => (
                                    <option value={option.value} key={option.value}>{option.label}</option>
                                ))}
                            </SelectComponent>
                        </div>
                    </Form>
                </Widget>
            </div>
            <BasicModal loadProps={loadProps} ref={modalRef} onClose={onModalClose}>
                <ContainerModal />
            </BasicModal>
        </>
    ) : null;
};

export default DealBatchUpdateModal;
