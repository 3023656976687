import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import DealsQueue from '../../../components/deals-queue/deals-queue.component';
import TableHelper from '../../../components/table-helper/table-helper.component';
import { DealsQueueData } from '../../../models/deals/deals-queue';
import { TableSchema } from '../../../models/schemas/table-schema';
import { SERVICES } from '../../../services';
import { CloserDealsService } from '../../../services/closer-deals.service';
import { DealService } from '../../../services/deal.service';
import { UnderwritingService } from '../../../services/underwriting.service';
import UpdateDealThinkerStatus, { UpdateData } from './update-deal-thinker-status.component';
import { timer, switchMap, zip, tap } from 'rxjs';
import { HttpService } from '../../../services/http.service';
import { SelectComponent } from '../../../models/schemas/form-schema';

const Overview = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [priorityInboxRows, setPriorityInboxRows] = useState<TableSchema | null>(null);
    const [underwritingDealRows, setUnderwritingDealRows] = useState<TableSchema | null>(null);
    const [myThinkerPodDealsRows, setMyThinkerPodDealsRows] = useState<TableSchema | null>(null);
    const [dealsQueue, setDealsQueue] = useState<DealsQueueData | null>(null);
    const dealsService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const underwritingService = utils.injection.useInjection<UnderwritingService>(SERVICES.UnderwritingService);
    const closerDealsService = utils.injection.useInjection<CloserDealsService>(SERVICES.CloserDealsService);
    const [updateData, setUpdateData] = useState<UpdateData>(null);

    const statusChangeCallback = useCallback((data: any) => {
        let type = 'success';
        let message = intl.formatMessage({ id: 'Deal status has been updated!' });
        if (data.status && data.status !== '200') {
            type = 'error';
            message = data.message;
        }
        dispatch(pushNotification({ class: type, message: message }));
        HttpService.subscribe(loadData(), dispatch);
    }, [intl, dispatch]);

    const loadData = useCallback(() => {
        return zip(
            dealsService.getDealsQueue(),
            underwritingService.getPriorityInbox(),
            underwritingService.getUnderwritingDeals(),
            underwritingService.getMyThinkerPodDeals(),
        ).pipe(
            tap({
                next: ([dealsQueue, priorityInboxRows, underwritingDealRows, myThinkerPodDealsRows]) => {
                    setDealsQueue(dealsQueue);
                    setPriorityInboxRows(priorityInboxRows);
                    setUnderwritingDealRows(underwritingDealRows);
                    setMyThinkerPodDealsRows(myThinkerPodDealsRows);
                }, 
                error: e => {
                    dispatch(pushNotification({ class: 'error', message: e.toString() }));
                }
            })
        );
    }, [
        dispatch, setPriorityInboxRows, setUnderwritingDealRows,
        setMyThinkerPodDealsRows, setDealsQueue, statusChangeCallback,
        underwritingService, dealsService, closerDealsService
    ]);

    const reloadData = useCallback((data?: { submit: boolean }) => {
        if (data?.submit) {
            HttpService.subscribe(loadData(), dispatch);
        }
    }, [loadData, dispatch]);

    useEffect(() => {
        const subscription = HttpService.subscribe(timer(0, 60000).pipe(switchMap(loadData)), dispatch);
        return () => {
            subscription.unsubscribe();
        };
    }, [loadData, dispatch]);

    const onChange = useCallback((data: any, value: string) => {
        setUpdateData(updateData => {
            if (value === 'awaiting-agent-clarification') {
                return { data, value };
            } else if (updateData && 'ignoreUpdate' in updateData) {
                return null;
            } else {
                dealsService.updateDealThinkerStatus(data.deal_id, value).then(statusChangeCallback);
            }
            return updateData;
        });
    }, [dealsService, statusChangeCallback, setUpdateData]);

    const updateDealThinkerStatusComponent = useCallback((type: string, deal_id: string, schema: SelectComponent, updateData: UpdateData) => {
        return {
            type: type,
            element: (
                <UpdateDealThinkerStatus
                    dealId={deal_id}
                    schema={schema}
                    onChange={onChange}
                    statusChangeCallback={() => HttpService.subscribe(loadData(), dispatch)}
                    updateData={updateData}
                    setUpdateData={setUpdateData} />
            )
        };
    }, [onChange, loadData, setUpdateData, dispatch]);

    const underwritingDealRowsWithCallbacks = useMemo(() => {
        if (underwritingDealRows) {
            return Object.assign({}, underwritingDealRows, {
                rows: underwritingDealRows.rows.map(row => {
                    return Object.assign({}, row, {
                        cells: row.cells.map(cell => {
                            if (cell.type === 'raw' && Array.isArray(cell.value)) {
                                return Object.assign({}, cell, {
                                    value: cell.value.map(component => {
                                        if (component.type === 'select') {
                                            return updateDealThinkerStatusComponent('react', component.data.deal_id, component, updateData);
                                        }
                                        return component;
                                    })
                                });

                            } else if (cell.type === 'raw' && typeof cell.value === 'object' && 'type' in cell.value && cell.value.type === 'select') {
                                return updateDealThinkerStatusComponent('custom-jsx', cell.value.data.deal_id, cell.value, updateData);
                            }
                            return cell;
                        })
                    });
                })
            });
        }
        return null;
    }, [underwritingDealRows, onChange, updateData, setUpdateData, loadData]);

    return dealsQueue && priorityInboxRows && underwritingDealRowsWithCallbacks && myThinkerPodDealsRows ? (
        <>
            <TableHelper schema={priorityInboxRows} includeHeader={true} onModalClose={reloadData} statusChangeCallback={statusChangeCallback} />
            <DealsQueue status={dealsQueue} />
            <TableHelper schema={underwritingDealRowsWithCallbacks} includeHeader={true} onModalClose={reloadData} statusChangeCallback={statusChangeCallback} />
            <TableHelper schema={myThinkerPodDealsRows} includeHeader={true} onModalClose={reloadData} statusChangeCallback={statusChangeCallback} />
        </>
    ) : null;
};

export default Overview;
