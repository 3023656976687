import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SERVICES } from '../../services';
import { TableSchema } from '../../models/schemas/table-schema';
import TableHelper from '../../components/table-helper/table-helper.component';
import { AdminService } from '../../services/admin.service';

const Tags = () => {
    const adminService = utils.injection.useInjection<AdminService>(SERVICES.AdminService);
    const [schema, setSchema] = useState<TableSchema | null>(null);
    const dispatch = useDispatch();

    const loadSchema = useCallback(() => {
        const subscription = adminService.getTagsTable().subscribe({
            next: setSchema,
            error: (e) => {
                dispatch(
                    pushNotification({ class: 'error', message: e.toString() })
                );
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [adminService, dispatch, setSchema]);

    useEffect(loadSchema, [loadSchema]);

    const reloadSchema = (data?: { submit: boolean }) => {
        if (data?.submit) {
            loadSchema();
        }
    };

    return schema ? (
        <TableHelper schema={schema} onModalClose={reloadSchema} includeHeader={true} />
    ) : null;
};

export default Tags;
