import { BasicModal, BasicModalHandle, utils } from '@truenorthmortgage/olympus';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ComponentHelper from '../../../components/component-helper/component-helper.component';
import ContainerModal from '../../../components/container-modal/container-modal.component';
import { SelectComponent } from '../../../models/schemas/form-schema';
import { SERVICES } from '../../../services';
import { DealService } from '../../../services/deal.service';

export type UpdateData = {data: any, value: string} | {ignoreUpdate: true} | null;
export interface UpdateDealThinkerStatusProps {
    schema: SelectComponent;
    dealId: string;
    statusChangeCallback: () => void;
    onChange: (data: any, value: string) => void;
    updateData: UpdateData;
    setUpdateData: (f: (value: UpdateData) => UpdateData) => void;
}

const UpdateDealThinkerStatus: FC<UpdateDealThinkerStatusProps> = ({ schema, dealId, onChange, setUpdateData, updateData, statusChangeCallback }) => {
    const dealsService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const modalRef = useRef<BasicModalHandle>(null);
    const [current, setCurrent] = useState<string>(schema.value);
    const [previous, setPrevious] = useState<string>(schema.value);

    useEffect(() => {
        if (updateData && 'data' in updateData && updateData.data.deal_id === dealId) {
            modalRef.current?.open();
        }
    }, [updateData, dealId]);

    const updatedSchema = useMemo(() => {
        return Object.assign({}, schema, {
            callback: onChange,
            value: current
        });
    }, [current, schema, onChange]);

    const loadProps = useCallback(async () => {
        return { data: await dealsService.createMessage('deal', 'thinker-awaiting-agent-clarification', dealId) };
    }, [dealsService]);

    const onSubmit = useCallback((data: any) => {
        setUpdateData(updateData => {
            if (data?.submit && updateData && 'data' in updateData) {
                dealsService.updateDealThinkerStatus(updateData.data.deal_id, updateData.value).then(statusChangeCallback);
            } else if (updateData && 'data' in updateData) {
                setCurrent('awaiting-agent-clarification');

                setTimeout(() => {
                    setCurrent(previous);
                }, 0);

                return {ignoreUpdate: true};
            }
            return null;
        });
    }, [setUpdateData, dealsService, statusChangeCallback]);

    return (
        <>
            <ComponentHelper schema={updatedSchema} />
            <BasicModal loadProps={loadProps} modalHeader={'THINKer - Awaiting Agent Clarification - Create'} ref={modalRef} onClose={onSubmit}>
                <ContainerModal />
            </BasicModal>
        </>
    );
};

export default UpdateDealThinkerStatus;
