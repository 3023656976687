import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionButton, BasicModalHandle, BasicRow, FieldGroupComponent, Form, pushNotification, RawCell, SelectComponent, Table, Widget } from '@truenorthmortgage/olympus';
import { FC, RefObject, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import './deal-search-modal.component.scss';

export type DealSearchModalProps = {
    parentRef?: RefObject<BasicModalHandle>,
    onSubmit?: (s: string) => void
};

const DealSearchModal: FC<DealSearchModalProps> = ({ parentRef, onSubmit}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [state, setState] = useState<Record<string, string | Date>[]>([{order: 'asc'}]);
    const [callbacks, setCallbacks] = useState<Record<string, (value: string | Date | null) => void>[]>([{}]);

    const onChange = useCallback((index: number, fieldName: string) => {
        if (callbacks[index][fieldName]) {
            return callbacks[index][fieldName];
        }
        const callback = (value: string | Date | null) => setState(state => {
            if (value === '') {
                state[index] = {};
            } else {
                state[index] = Object.assign({}, state[index], { [fieldName]: value });
            }
            
            return Array.from(state);
        });
        callbacks[index][fieldName] = callback;
        setCallbacks(callbacks);
        return callback;
    }, [callbacks, setCallbacks, setState]);
    
    const addRemoveRow = useCallback(() => {
        if (state.length && !('column' in state[0])) {
            dispatch(
                pushNotification({ 
                    class: 'error', 
                    message: intl.formatMessage(
                        { id: 'Please choose a sort field for all sort options before adding more' }
                    )
                })
            );
            return;
        }
        setCallbacks(callbacks => {
            const newCallbacks = Array.from(callbacks);
            if (newCallbacks.length > 1) {
                newCallbacks.splice(1, 1);
            } else {
                newCallbacks.push({});
            }
            return newCallbacks;
        });
        setState(state => {
            const newState = Array.from(state);
            if (newState.length > 1) {
                newState.splice(1, 1);
            } else {
                newState.push({order: 'asc'});
            }
            return newState;
        });
    }, [state]);

    const onSortSubmit = useCallback(() => {
        if (onSubmit && 'column' in state[0]) {
            const queryParams = state.map(filter => {
                return filter.column + ':' + filter.order;
            });

            onSubmit('&search_deals_table_sort=' + queryParams.join('|'));
        }
        parentRef?.current?.close();
    }, [state]);

    return parentRef ? (
        <Widget>
            <Form>
                <FieldGroupComponent>
                    <Table title={intl.formatMessage({ id: 'Sort' })}>
                        {state.map((_, index) => (
                            <BasicRow key={index} rowClasses={['sort', 'layout']}>
                                <RawCell>
                                    <SelectComponent
                                        onChange={onChange(index, 'column')}
                                        value={state[index]['column'] as string ?? ''}>
                                        <option value="">
                                            {intl.formatMessage({ id: '-- Select Field --' })}
                                        </option>
                                        <option value="id">
                                            {intl.formatMessage({ id: 'ID' })}
                                        </option>
                                        <option value="think_loan_number">
                                            {intl.formatMessage({ id: 'THINK Loan #' })}
                                        </option>
                                    </SelectComponent>
                                </RawCell>
                                <RawCell>
                                    <SelectComponent
                                        onChange={onChange(index, 'order')}
                                        value={state[index]['order'] as string ?? ''}>
                                        <option value="asc">
                                            {intl.formatMessage({ id: 'Ascending' })}
                                        </option>
                                        <option value="desc">
                                            {intl.formatMessage({ id: 'Descending' })}
                                        </option>
                                    </SelectComponent>
                                </RawCell>
                                <RawCell html="">
                                    {index + 1 === state.length ? (
                                        <ActionButton className="button remove" callback={addRemoveRow}>
                                            <i>
                                                <FontAwesomeIcon icon={index === 0 ? 'plus' : 'minus'} />
                                            </i>
                                        </ActionButton>
                                    ) : null}
                                </RawCell>
                            </BasicRow>
                        ))}
                    </Table>
                </FieldGroupComponent>
                <div className="column buttons">
                    <div className="buttons">
                        <button className="button cancel" onClick={() => parentRef.current?.close()}>
                            <FormattedMessage id="Clear" />
                        </button>
                        <button className="button primary right form-trigger" onClick={onSortSubmit}>
                            <FormattedMessage id="Sort" />
                        </button>
                    </div>
                </div>
            </Form>
        </Widget>
    ) : null;
};

export default DealSearchModal;
