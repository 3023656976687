import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SERVICES } from '../../../services';
import { DealHeader as DealHeaderModel } from '../../../models/deals/header';
import { DealService } from '../../../services/deal.service';
import DealHeader from '../../../components/deal-header/deal-header.component';
import AccordionHelper from '../../../components/accordion-helper/accordion-helper.component';
import { Accordion } from '../../../models/accordions/accordion';
import { zip } from 'rxjs';

export interface DealRatingProps {}

const DealRating: FC<DealRatingProps> = () => {
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const dispatch = useDispatch();
    const [dealHeader, setDealHeader] = useState<DealHeaderModel | null>(null);
    const [accordion, setAccordion] = useState<Accordion | null>(null);
    const [reload, setReload] = useState<boolean>(false);
    const params = utils.nav.useAllPossibleParams();

    const loadData = useCallback(() => {
        if (params.deal_id) {
            const subscription = zip(
                dealService.getHeader(params.deal_id),
                dealService.getDealRanking(params.deal_id)
            ).subscribe({
                next: ([dealHeader, accordion]) => {
                    setDealHeader(dealHeader);
                    setAccordion(accordion);
                },
                error: e => {
                    dispatch(pushNotification({ class: 'error', message: e.toString() }));
                }
            });

            return () => {
                subscription.unsubscribe();
            };
        }
    }, [params.deal_id, reload, dealService, dispatch]);

    const reloadData = useCallback((data?: { submit: boolean }) => {
        if (data?.submit) {
            loadData();
        }
    }, [loadData]);

    useEffect(loadData, [loadData]);

    return dealHeader && accordion ? (
        <>
            <DealHeader header={dealHeader} onModalClose={() => setReload(prev => !prev)}/>
            <AccordionHelper accordion={accordion} onModalClose={reloadData} />
        </>
    ) : null;
};

export default DealRating;
