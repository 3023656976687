import { BasicModalHandle, DateComponent, TextComponent } from '@truenorthmortgage/olympus';
import { FC, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import FormHelper from '../components/form-helper/form-helper.component';
import { FormSchema, DateComponent as DateComponentSchema, HiddenField as HiddenComponentSchema} from '../models/schemas/form-schema';

export interface RenewConciergeLoanProps {
    schema: FormSchema,
    onSubmit: (data: any) => void,
    parentRef?: RefObject<BasicModalHandle>
}
const RenewConciergeLoan: FC<RenewConciergeLoanProps> = ({ schema, onSubmit, parentRef }) => {
    const [outstandingDays, setOutstandingDays] = useState<string>('');
    const [newMaturityDate, setNewMaturityDate] = useState<string | null>('');
    const [newInterestRate, setNewInterestRate] = useState<string | null>('');
    const [newMonthlyPayment, setNewMonthlyPayment] = useState<string | null>('');

    const minDate = useMemo(() => {
        const dateComponent = schema.components.filter(component => component.type === 'date' && component.name === 'new_maturity_date')[0] as DateComponentSchema;
        if (dateComponent.min_date) {
            return new Date(dateComponent.min_date);
        }
        return new Date();
    }, [schema]);

    const mortgageAmount = useMemo(() => {
        const hiddenComponent = schema.components.filter(component => component.type === 'hidden' && component.name === 'hidden_mortgage_amount')[0] as HiddenComponentSchema;
        return +hiddenComponent.value;
    }, [schema]);

    useEffect(() => {
        if (newMaturityDate) {
            const newDate = new Date(newMaturityDate);
            const diff = newDate.getTime() - minDate.getTime();
            // Here we add one day back because backend sent us one less day
            const days = (diff / (1000 * 3600 * 24) + 1);
            setOutstandingDays(days.toString());
        } 
    }, [newMaturityDate]);

    useEffect(() => {
        if (newInterestRate && +newInterestRate > 0) {
            const payment = +newInterestRate / 100 / 12 * mortgageAmount;
            setNewMonthlyPayment(payment.toFixed(2));
        }
    }, [newInterestRate]);

    const returnCustomPayload = useCallback(async () => {
        return {
            new_interest_rate: newInterestRate,
            new_monthly_payment: newMonthlyPayment,
            new_maturity_date: newMaturityDate,
            outstanding_days: outstandingDays
        };
    }, [newInterestRate, newMonthlyPayment, newMaturityDate, outstandingDays]);

    const updatedSchema = useMemo(() => {
        return Object.assign({}, schema, {
            components: schema.components.map((component, index) => {
                if (component.type === 'text' && component.name === 'new_interest_rate') {
                    return {
                        type: 'render',
                        name: component.name,
                        renderComponent: (showError: boolean) => {
                            return <TextComponent
                                key={index}
                                error={showError}
                                triggerOnLoad={true}
                                disabled={component.disabled}
                                columnStyle={`${component.style ?? ''} ${component.type}`}
                                formData={newInterestRate ?? ''}
                                label={component.label}
                                defaultValue={component.value}
                                onBlur={setNewInterestRate}
                                name={component.name} />;
                        }
                    };
                } else if (component.type === 'text' && component.name === 'new_monthly_payment') {
                    return {
                        type: 'render',
                        name: component.name,
                        renderComponent: (showError: boolean) => {
                            return <TextComponent
                                key={index}
                                error={showError}
                                triggerOnLoad={true}
                                disabled={component.disabled}
                                columnStyle={`${component.style ?? ''} ${component.type}`}
                                formData={newMonthlyPayment ?? ''}
                                label={component.label}
                                defaultValue={component.value}
                                name={component.name} />;
                        }
                    };
                } else if (component.type === 'date' && component.name === 'new_maturity_date') {
                    return {
                        type: 'render',
                        name: component.name,
                        renderComponent: (showError: boolean) => {
                            return <DateComponent 
                                key={index}
                                error={showError}
                                label={component.label}
                                name={component.name} 
                                minDate={component.min_date ? new Date(component.min_date) : undefined} 
                                maxDate={component.max_date ? new Date(component.max_date) : undefined} 
                                dateFormat={component.date_format} 
                                value={newMaturityDate ?? ''} 
                                columnStyle={`${component.style ?? ''} ${component.type}`} 
                                onChange={setNewMaturityDate} />;
                        }
                    };
                } else if (component.type === 'text' && component.name === 'new_outstanding_days') {
                    return {
                        type: 'render',
                        name: component.name,
                        renderComponent: (showError: boolean) => {
                            return <TextComponent
                                key={index}
                                error={showError}
                                triggerOnLoad={true}
                                disabled={component.disabled}
                                columnStyle={`${component.style ?? ''} ${component.type}`}
                                formData={outstandingDays}
                                label={component.label}
                                defaultValue={component.value}
                                name={component.name} />;
                        }
                    };
                }
                
                return component;
            })});
    }, [schema, outstandingDays, newInterestRate, newMonthlyPayment]);

    return <FormHelper schema={updatedSchema} onSubmit={onSubmit} onCustomPayload={returnCustomPayload} parentRef={parentRef} />;
};

export default RenewConciergeLoan;
