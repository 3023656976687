import { pushNotification, utils, Document } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TableHelper from '../../components/table-helper/table-helper.component';
import { TableSchema } from '../../models/schemas/table-schema';
import { SERVICES } from '../../services';
import { DealService } from '../../services/deal.service';
import { VerbalerService } from '../../services/verbaler.service';

const CompletedVerbals = () => {
    const dispatch = useDispatch();
    const [completedVerbalsTable, setCompletedVerbalsTable] = useState<TableSchema | null>(null);
    const dealsService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const verbalerService = utils.injection.useInjection<VerbalerService>(SERVICES.VerbalerService);

    const loadData = useCallback((searchParam: string|null = null) => {

        const subscription = verbalerService.getCompletedRequests(searchParam).subscribe({
            next: setCompletedVerbalsTable,
            error: (e) => {
                dispatch(
                    pushNotification({ class: 'error', message: e.toString() })
                );
            }
        });

        return () => {
            subscription.unsubscribe();
        };

    }, [
        dispatch, setCompletedVerbalsTable,
        verbalerService, dealsService,
    ]);

    const reloadData = (searchParam: string) => {
        loadData(searchParam);
    };

    useEffect(loadData, [loadData]);

    return (completedVerbalsTable)
        ? <TableHelper schema={completedVerbalsTable} includeHeader={true} onModalClose={reloadData} onSearch={reloadData}/>
        : null;
};

export default CompletedVerbals;
