import { BasicModalHandle, DateComponent, TextComponent, utils } from '@truenorthmortgage/olympus';
import { FC, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import FormHelper from '../components/form-helper/form-helper.component';
import { FormSchema, TextComponent as TextComponentSchema, DateComponent as DateComponentSchema } from '../models/schemas/form-schema';
import { SERVICES } from '../services';
import { DealService } from '../services/deal.service';

export interface DealHelocFormProps {
    schema: FormSchema;
    onSubmit: (data: any) => void;
    parentRef?: RefObject<BasicModalHandle>;
}

const DealHelocForm: FC<DealHelocFormProps> = ({ schema, onSubmit, parentRef }) => {
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const prime_rate = useMemo(() => {
        const rateTextComponent = schema.components.filter(component => component.type === 'text' && component.name === 'heloc_rate_prime_plus')[0] as TextComponentSchema;
        return rateTextComponent.value;
    }, [schema]);

    const service_fee = useMemo(() => {
        const feeTextComponent = schema.components.filter(component => component.type === 'text' && component.name === 'servicing_fee_bps')[0] as TextComponentSchema;
        return feeTextComponent.value;
    }, [schema]);

    const closing_date = useMemo(() => {
        const dateTextComponent = schema.components.filter(component => component.type === 'date' && component.name === 'closing_date')[0] as DateComponentSchema;
        return dateTextComponent.value ?? null;
    }, [schema]);

    const line_of_credit_limit = useMemo(() => {
        const creditLimitTextComponent = schema.components.filter(component => component.type === 'text' && component.name === 'line_of_credit_limit')[0] as TextComponentSchema;
        return creditLimitTextComponent.value;
    }, [schema]);

    const mortgage_balance = useMemo(() => {
        const mortgageBalanceTextComponent = schema.components.filter(component => component.type === 'text' && component.name === 'mortgage_balance')[0] as TextComponentSchema;
        return mortgageBalanceTextComponent.value;
    }, [schema]);

    const appraisal_value = useMemo(() => {
        const appraisalValueTextComponent = schema.components.filter(component => component.type === 'text' && component.name === 'appraisal_value')[0] as TextComponentSchema;
        return appraisalValueTextComponent.value;
    }, [schema]);

    const combined_ltv = useMemo(() => {
        const combinedLtvTextComponent = schema.components.filter(component => component.type === 'text' && component.name === 'combined_ltv')[0] as TextComponentSchema;
        return combinedLtvTextComponent.value;
    }, [schema]);

    const [helocRatePlus, setHelocRatePlus] = useState<string | null>(prime_rate);
    const [closingDate, setClosingDate] = useState<string | null>(closing_date);
    const [servicingFee, setServicingFee] = useState<string | null>(service_fee);
    const [lineOfCreditLimit, setLineOfCreditLimit] = useState<string | null>(line_of_credit_limit);
    const [mortgageBalance, setMortgageBalance] = useState<string | null>(mortgage_balance);
    const [appraisalValue, setAppraisalValue] = useState<string | null>(appraisal_value);
    const [combinedLtv, setCombinedLtv] = useState<string | null>(combined_ltv);

    useEffect(() => {
        if (helocRatePlus !== null && closingDate !== null) {
            dealService.calculateServicingFee(helocRatePlus, closingDate)
                .then(data => {
                    setServicingFee(data);
                });
        }
    }, [helocRatePlus, closingDate]);

    useEffect(() => {
        if (lineOfCreditLimit !== null && mortgageBalance !== null && appraisalValue !== null) {
            const combinedLTV = ((Number(mortgageBalance) + Number(lineOfCreditLimit)) / Number(appraisalValue)).toFixed(3);
            setCombinedLtv(combinedLTV.toString());
        }
    }, [lineOfCreditLimit, mortgageBalance, appraisalValue]);

    const returnCustomPayload = useCallback(async () => {
        return {
            heloc_rate_prime_plus: helocRatePlus,
            servicing_fee_bps: servicingFee,
            closing_date: closingDate,
            line_of_credit_limit: lineOfCreditLimit,
            mortgage_balance: mortgageBalance,
            appraisal_value: appraisalValue,
            combined_ltv: combinedLtv
        };
    }, [helocRatePlus, servicingFee, closingDate, lineOfCreditLimit, mortgageBalance, appraisalValue, combinedLtv]);

    const updatedSchema = useMemo(() => {
        return Object.assign({}, schema, {
            components: schema.components.map((component) => {
                if (component.type === 'text' && component.name === 'heloc_rate_prime_plus') {
                    return {
                        type: 'react',
                        element: (
                            <TextComponent
                                columnStyle={`${component.style ?? ''} ${component.type}`}
                                autoComplete={component.autocomplete}
                                disabled={component.disabled}
                                label={component.label}
                                defaultValue={component.value ?? ''}
                                readOnly={component.readonly}
                                placeholder={component.placeholder}
                                onBlur={setHelocRatePlus}
                                name={component.name}
                            />
                        )
                    };
                } else if (component.type === 'text' && component.name === 'servicing_fee_bps') {
                    return {
                        type: 'react',
                        element: (
                            <TextComponent
                                columnStyle={`${component.style ?? ''} ${component.type}`}
                                autoComplete={component.autocomplete}
                                disabled={component.disabled}
                                label={component.label}
                                formData={servicingFee ?? component.value}
                                defaultValue={component.value ?? ''}
                                readOnly={component.readonly}
                                placeholder={component.placeholder}
                                name={component.name}
                            />
                        )
                    };
                } else if (component.type === 'date' && component.name === 'closing_date') {
                    return {
                        type: 'react',
                        element: (
                            <DateComponent
                                label={component.label}
                                name={component.name}
                                value={component.value ?? undefined}
                                columnStyle={`${component.style ?? ''} ${component.type}`}
                                onChange={setClosingDate}
                            />
                        )
                    };
                } else if (component.type === 'text' && component.name === 'line_of_credit_limit') {
                    return {
                        type: 'react',
                        element: (
                            <TextComponent
                                columnStyle={`${component.style ?? ''} ${component.type}`}
                                autoComplete={component.autocomplete}
                                disabled={component.disabled}
                                label={component.label}
                                defaultValue={lineOfCreditLimit ?? component.value}
                                readOnly={component.readonly}
                                placeholder={component.placeholder}
                                onBlur={setLineOfCreditLimit}
                                name={component.name}
                            />
                        )
                    };
                } else if (component.type === 'text' && component.name === 'mortgage_balance') {
                    return {
                        type: 'react',
                        element: (
                            <TextComponent
                                columnStyle={`${component.style ?? ''} ${component.type}`}
                                autoComplete={component.autocomplete}
                                disabled={component.disabled}
                                label={component.label}
                                defaultValue={mortgageBalance ?? component.value}
                                readOnly={component.readonly}
                                placeholder={component.placeholder}
                                onBlur={setMortgageBalance}
                                name={component.name}
                            />
                        )
                    };
                } else if (component.type === 'text' && component.name === 'appraisal_value') {
                    return {
                        type: 'react',
                        element: (
                            <TextComponent
                                columnStyle={`${component.style ?? ''} ${component.type}`}
                                autoComplete={component.autocomplete}
                                disabled={component.disabled}
                                label={component.label}
                                defaultValue={appraisalValue ?? component.value}
                                readOnly={component.readonly}
                                placeholder={component.placeholder}
                                onBlur={setAppraisalValue}
                                name={component.name}
                            />
                        )
                    };
                } else if (component.type === 'text' && component.name === 'combined_ltv') {
                    return {
                        type: 'react',
                        element: (
                            <TextComponent
                                columnStyle={`${component.style ?? ''} ${component.type}`}
                                autoComplete={component.autocomplete}
                                disabled={component.disabled}
                                label={component.label}
                                formData={combinedLtv ?? component.value}
                                defaultValue={component.value ?? ''}
                                readOnly={component.readonly}
                                placeholder={component.placeholder}
                                name={component.name}
                            />
                        )
                    };
                }
                return component;
            })
        });
    }, [schema, servicingFee, lineOfCreditLimit, mortgageBalance, appraisalValue, combinedLtv]);

    return updatedSchema
        ? <FormHelper schema={updatedSchema} onSubmit={onSubmit} parentRef={parentRef} onCustomPayload={returnCustomPayload} />
        : null;
};

export default DealHelocForm;
