import { TextComponent, MoneyComponent } from '@truenorthmortgage/olympus';
import { Card } from './card.component';
import { Frequency } from './frequency-component';
import _ from 'lodash';

export interface YtdPsInfo {
    ytd_income: number | null;
    ps_frequency: number | null;
    ps_current_pay: number | null;
    ps_current_pay_period: number | null;
    [key: string]: YtdPsInfo[keyof YtdPsInfo];
}

interface VerbalYtdPsCardProps {
    configData: [YtdPsInfo, string, string];
    helpers: CallableFunction[];
}

const VerbalPsYtdCard = ({
    configData: [ytd_ps_info, ytd_output, paystub_output],
    helpers: [createUpdatedInfo],
}: VerbalYtdPsCardProps) => {
    const {
        ytd_income: ytdIncome,
        ps_frequency: psFrequency,
        ps_current_pay_period: psCurrentPayPeriod,
        ps_current_pay: psCurrentPay,
    } = ytd_ps_info;

    const estimatePP = () => {
        return ytdIncome && psCurrentPay
            ? Math.round(ytdIncome / psCurrentPay)
            : null;
    };

    const setCorrectPp = (enteredPPString: string | null) => {
        const enteredPP = enteredPPString ? +enteredPPString : null;
        const estimatedPP = estimatePP();
        const ppToUse = (enteredPP !== null && enteredPP !== 0) ? enteredPP : estimatedPP;
        createUpdatedInfo(ppToUse, 'ytd_ps_info', 'ps_current_pay_period');
    };

    return (
        <Card className="verbal-card" id="vulcan-ps-and-ytd">
            <h3>YTD And Paystub:</h3>
            <div className="vulcan-parent-div">
                <div>
                    <label>YTD Income: </label>
                    <MoneyComponent
                        cents={ytdIncome ?? undefined}
                        onChangeCents={_.debounce((val) => createUpdatedInfo(val, 'ytd_ps_info', 'ytd_income'), 500)}
                        name="verbal-ytd"
                    />
                </div>
                <Frequency
                    frequency={psFrequency ?? undefined}
                    onBlur={(val) =>
                        createUpdatedInfo(val, 'ytd_ps_info', 'ps_frequency')
                    }
                />
                <div>
                    <label>Current Pay: </label>
                    <MoneyComponent
                        cents={psCurrentPay ?? undefined}
                        onChangeCents={_.debounce((val) => createUpdatedInfo(val, 'ytd_ps_info', 'ps_current_pay'), 500)}
                        name="verbal-ytd-ps-pay"
                    />
                </div>
                <div>
                    <label>Current Pay Period: </label>
                    <TextComponent
                        placeholder={
                            estimatePP()?.toString()
                                ? `Estimate: ${estimatePP()?.toString()}. Hit Tab To Accept`
                                : ''
                        }
                        formData={psCurrentPayPeriod?.toString()}
                        onBlur={(enteredPP) => setCorrectPp(enteredPP)}
                        name="verbal-ytd-ps-pay-period"
                    />
                </div>
                <div>
                    <label>YTD Output: </label>
                    <TextComponent formData={ytd_output} disabled={true} />
                </div>
                <div>
                    <label>Paystub Output: </label>
                    <TextComponent formData={paystub_output} disabled={true} />
                </div>
            </div>
        </Card>
    );
};
export default VerbalPsYtdCard;
