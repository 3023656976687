import {
    BasicModal,
    BasicModalHandle,
    utils,
} from '@truenorthmortgage/olympus';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import ComponentHelper from '../../components/component-helper/component-helper.component';
import ContainerModal from '../../components/container-modal/container-modal.component';
import { SelectComponent } from '../../models/schemas/form-schema';
import { SERVICES } from '../../services';
import { DealService } from '../../services/deal.service';
import { VerbalerService } from '../../services/verbaler.service';

export interface UpdateVerbalStatusProps {
    schema: SelectComponent;
    draftId: string;
    dealId: string;
    reloadData: (data: any) => void;
}

const UpdateVerbalStatus: FC<UpdateVerbalStatusProps> = ({
    schema,
    draftId,
    dealId,
    reloadData,
}) => {
    const dealService = utils.injection.useInjection<DealService>(
        SERVICES.DealService,
    );
    const verbalerService = utils.injection.useInjection<VerbalerService>(
        SERVICES.VerbalerService,
    );
    const modalMessageRef = useRef<BasicModalHandle>(null);
    const modalConfirmRef = useRef<BasicModalHandle>(null);
    const [current, setCurrent] = useState<string>(schema.value);
    const [selected, setSelected] = useState<string>(schema.value);

    const onChange = useCallback(
        async (_: any, value: string) => {
            if (current !== value) {
                if (value === 'pending-info' || value === 'third-try') {
                    modalMessageRef.current?.open();
                } else {
                    modalConfirmRef.current?.open();
                }
            }

            setSelected(value);
        },
        [current],
    );

    const onClose = useCallback(
        async (data: any) => {
            if (data?.submit) {
                setCurrent(selected);
            } else {
                setSelected(current);
            }

            reloadData(data);
        },
        [dealService, selected, current],
    );

    const updatedSchema = useMemo(() => {
        return Object.assign({}, schema, {
            callback: onChange,
            value: selected,
        });
    }, [onChange, schema, selected]);

    const loadMessageProps = useCallback(async () => {
        return {
            data: await dealService.createMessage(
                'verbal-draft-request',
                'verbaler-awaiting-additional-info',
                draftId,
            ),
        };
    }, [dealService]);

    const loadConfirmProps = useCallback(async () => {
        const modal_config = await dealService.createConfirm(draftId, selected);
        return { data: modal_config };
    }, [dealService, selected]);

    return (
        <>
            <ComponentHelper schema={updatedSchema} />
            <BasicModal
                loadProps={loadConfirmProps}
                modalHeader={'Confirm Status Change'}
                ref={modalConfirmRef}
                onClose={onClose}
            >
                <ContainerModal />
            </BasicModal>
            <BasicModal
                loadProps={loadMessageProps}
                modalHeader={'Verbaler - Awaiting Additional Info'}
                ref={modalMessageRef}
                onClose={(data: any) => {
                    onClose(data);
                    if (data?.submit) {
                        verbalerService.updateVerbalStatus(draftId, selected);
                    }
                }}
            >
                <ContainerModal />
            </BasicModal>
        </>
    );
};

export default UpdateVerbalStatus;
