import { Form, pushNotification, RadioComponent, RadioOption, RawCell, utils } from '@truenorthmortgage/olympus';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { timer, tap, switchMap, zip } from 'rxjs';
import DealsQueue from '../../../components/deals-queue/deals-queue.component';
import TableHelper from '../../../components/table-helper/table-helper.component';
import { DealsQueueData } from '../../../models/deals/deals-queue';
import { FormSchema } from '../../../models/schemas/form-schema';
import { SchemaRow, TableSchema } from '../../../models/schemas/table-schema';
import { SERVICES } from '../../../services';
import { DealService } from '../../../services/deal.service';
import { HttpService } from '../../../services/http.service';

const DealsOverview = () => {
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const dispatch = useDispatch();
    const [dealsQueue, setDealsQueue] = useState<DealsQueueData | null>(null);
    const [overview, setOverview] = useState<[TableSchema, FormSchema, TableSchema] | null>(null);
    const [radio, setRadio] = useState<string>('0');

    const loadData = useCallback(() => {
        return zip(
            dealService.getDealsQueue(),
            dealService.getOverview()
        ).pipe(
            tap({
                next: ([dealsQueue, overview]) => {
                    setDealsQueue(dealsQueue);
                    setOverview(overview);
                }
            })
        );
    }, [setDealsQueue, setOverview]);

    useEffect(() => {
        const subscription = HttpService.subscribe(timer(0, 60000).pipe(switchMap(loadData)), dispatch);

        return () => {
            subscription.unsubscribe();
        };
    }, [dealService, dispatch]);

    const reloadData = useCallback((data?: {submit: boolean}) => {
        if (data?.submit) {
            HttpService.subscribe(loadData(), dispatch);
        }
    }, [loadData, dispatch]);

    useEffect(() => {
        if (overview) {
            let rows: SchemaRow[] = [];

            if (radio === '1') {
                rows = overview[2].rows.map((row) => {
                    if (row.class.includes('instructed')) {
                        row.class += ' hidden';
                    }
                    return row;
                });
            } else {
                rows = overview[2].rows.map((row) => {
                    if (row.class.includes('hidden')) {
                        row.class = row.class.replace(' hidden', '');
                    }
                    return row;
                });
            }
            setOverview([overview[0], overview[1], { ...overview[2], rows: rows }]);
        }
    }, [radio]);

    const onChangeHandler = useCallback((value: string | null) => {
        setRadio(value === '1' ? '1' : '0');
    }, []);
    const component = overview ? overview[1].components[0] : null;

    return dealsQueue && overview ? (
        <>
            <DealsQueue status={dealsQueue} />
            {overview[0] ? (
                <TableHelper
                    schema={overview[0]}
                    className="bordered"
                    includeHeader={true}
                    onModalClose={reloadData}
                />
            ) : null}
            <TableHelper
                schema={overview[2]}
                includeHeader={true}
                headerIndex={0}
                onModalClose={reloadData}
            >
                {component && component?.type === 'radio' ? (
                    <tr className="faux-row">
                        <RawCell className="faux-cell component" colSpan={7}>
                            <Form>
                                <RadioComponent
                                    label={component.label}
                                    name={component.name}
                                    value={radio}
                                    columnStyle={component.style}
                                    onChange={onChangeHandler}
                                >
                                    {component.options.map((option, index) => (
                                        <RadioOption
                                            key={index}
                                            value={option.value}
                                            label={option.label}
                                        />
                                    ))}
                                </RadioComponent>
                            </Form>
                        </RawCell>
                    </tr>
                ) : null}
            </TableHelper>
        </>
    ) : null;
};

export default DealsOverview;
