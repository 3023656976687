import { Observable } from 'rxjs';
import { TableSchema } from '../models/schemas/table-schema';
import { HttpService as HttpService } from './http.service';
import { DateComponent, FormSchema, MultiSelectComponent, SelectComponent } from '../models/schemas/form-schema';

export interface Filters {
    [propName: string] : string | null | []
}

export class ReportService extends HttpService {
    getThinkerPodReport(filters: Filters): Observable<[TableSchema,SelectComponent[] | DateComponent[]]> {
        const queryParams = this.formatQueryParams(filters);

        return this.fetchJsonObservable<[TableSchema, SelectComponent[] | DateComponent[]]>(`/reports/thinker-pod-report${queryParams}`);
    }

    getFunderPipeline(filters: Filters): Observable<[TableSchema,SelectComponent[] | DateComponent[]]> {
        const queryParams = this.formatQueryParams(filters);

        return this.fetchJsonObservable<[TableSchema, SelectComponent[] | DateComponent[]]>(`/reports/funder-pipeline${queryParams}`);
    }

    getCesReport(filters: Filters): Observable<[TableSchema,SelectComponent[] | DateComponent[]]> {
        const queryParams = this.formatQueryParams(filters);
        return this.fetchJsonObservable<[TableSchema, SelectComponent[] | DateComponent[]]>(`/reports/ces-report${queryParams}`);
    }

    getDeadNoContactLeadsReport(filters: Filters): Observable<[TableSchema,SelectComponent[] | DateComponent[]]> {
        const queryParams = this.formatQueryParams(filters);
        return this.fetchJsonObservable<[TableSchema, SelectComponent[] | DateComponent[]]>(`/reports/dead-no-contact-leads-report${queryParams}`);
    }

    getThinkerBonusReport(filters: Filters): Observable<[TableSchema, SelectComponent[] | undefined]> {
        const queryParams = this.formatQueryParams(filters);

        return this.fetchJsonObservable<[TableSchema, SelectComponent[] | undefined]>(`/reports/thinker-bonus-report${queryParams}`);
    }

    getFunderBonusReport(filters: Filters): Observable<[TableSchema, SelectComponent[] | undefined]> {
        const queryParams = this.formatQueryParams(filters);

        return this.fetchJsonObservable<[TableSchema, SelectComponent[] | undefined]>(`/reports/funder-bonus-report${queryParams}`);
    }

    getExtraBonusAmount(filters: Filters): Promise<string> {
        const queryParams = this.formatQueryParams(filters);

        return this.fetchJson<string>(`/thinker/calculate-extra-bonus-amount${queryParams}`);
    }

    getFunderDealsReport(filters: Filters): Observable<[TableSchema, SelectComponent[] | DateComponent[] | MultiSelectComponent[]]> {
        const queryParams = this.formatQueryParams(filters);

        return this.fetchJsonObservable<[TableSchema, SelectComponent[] | DateComponent[] | MultiSelectComponent[]]>(`/reports/funder-deal-report${queryParams}`);
    }

    getAgentRatingReport(filters: Filters): Observable<[SelectComponent, TableSchema]> {
        const queryParams = this.formatQueryParams(filters);

        return this.fetchJsonObservable<[SelectComponent, TableSchema]>(`/reports/agent-rating-report${queryParams}`);
    }

    getRequestForFunding(): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>('/reports/request-for-funding');
    }
}
