import { SelectComponent, BasicModalHandle } from '@truenorthmortgage/olympus';
import { FC, RefObject, useCallback, useMemo, useState } from 'react';
import FormHelper from '../components/form-helper/form-helper.component';
import { FormSchema } from '../models/schemas/form-schema';

export interface CesAgentFormProps {
    schema: FormSchema;
    onSubmit: (data: any) => void;
    parentRef?: RefObject<BasicModalHandle>;
}

const CesAgent: FC<CesAgentFormProps> = ({ schema, onSubmit, parentRef }) => {
    const [cesStatus, setCesStatus] = useState<string>('assign');

    const returnCustomPayload = useCallback(async () => {
        return {
            ces_status: cesStatus
        };
    }, [cesStatus]);

    const updatedSchema = useMemo(() => {
        return Object.assign({}, schema, {
            components: schema.components.map((component: any) => {
                if (component.type === 'select' && component.label === 'CES Status') {
                    const { value, options, disabled, label, style, type } = component;

                    return {
                        type: 'react',
                        element: (
                            <SelectComponent
                                name="ces_lead_status"
                                columnStyle={`${style ?? ''} ${type}`}
                                onChange={setCesStatus}
                                defaultValue={value ?? 'assign'}
                                value={cesStatus}
                                disabled={disabled}
                                label={label}
                                options={options}
                                triggerOnLoad={true}
                            >
                            </SelectComponent>
                        )
                    };
                } else if (component.type === 'select' && component.label === 'Agent' && cesStatus !== 'assign') {
                    return {
                        type: 'react',
                        element: null
                    };
                }
                
                return component;
            })
        });
    }, [schema, cesStatus]);

    return <FormHelper schema={updatedSchema} onSubmit={onSubmit} onCustomPayload={returnCustomPayload} parentRef={parentRef}  />;
};

export default CesAgent;
