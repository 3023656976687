import { Dispatch } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router';
import { map, Observable, tap } from 'rxjs';
import { ComponentSchema } from '../models/schemas/form-schema';
import { TableSchema } from '../models/schemas/table-schema';
import { HttpService } from './http.service';

export class AdminService extends HttpService {
    getUsers(dispatch: Dispatch<any>, navigate: NavigateFunction, showActive = true ): [Observable<ComponentSchema>, Observable<TableSchema>] {
        const users = this.fetchJsonObservable<[ComponentSchema, TableSchema]>(`/admin/users?show_active=${showActive}`);
        const componentSchemas = users.pipe(map(([componentSchema, _]) => componentSchema));
        const userTableSchema = users.pipe(map(([_, tableSchema]) => tableSchema));

        const updatedUserTableSchema = userTableSchema.pipe(
            tap(users => {
                users.rows.forEach(row => {
                    row.cells.forEach(cell => {
                        if (cell.type === 'actions') {
                            cell.options.forEach(option => {
                                if (option.type === 2) {
                                    option.uriCallback = uri => this.assumePersona(uri, dispatch, navigate);
                                }
                            });
                        }
                    });
                });
            })
        );

        return [componentSchemas, updatedUserTableSchema];
    }

    async assumePersona(uri: string, dispatch: Dispatch<any>, navigate: NavigateFunction) {
        const { jwt } = await this.fetchJson<{ jwt: string }>(uri);
        this.authService!.assumePersona(jwt, dispatch, navigate);
    }

    getMailingListSchema(): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>('/admin/get-mailer-list-table');
    }

    getRolesTable(): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>('/admin/get-roles-table');
    }

    getTagsTable(): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>('/admin/get-tags-table');
    }
}
