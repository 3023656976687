import { inject } from 'inversify';
import { Observable } from 'rxjs';
import { SERVICES } from '.';
import { TableSchema } from '../models/schemas/table-schema';
import { DealService } from './deal.service';
import { HttpService as HttpService } from './http.service';
import { ActionHeaderSchema } from '../models/schemas/action-header';
import { ActionButton } from '../models/accordions/action-button';
import { ComponentSchema } from '../models/schemas/form-schema';

export class UnderwritingService extends HttpService {
    @inject(SERVICES.DealService) protected dealService: DealService | null = null;

    getPriorityInbox(): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>('/thinker/priority-inbox');
    }

    getUnderwritingDeals(account_uuid: string | null = null): Observable<TableSchema> {
        const param = account_uuid !== null ? '?account_uuid=' + account_uuid : '';
        return this.fetchJsonObservable<TableSchema>('/thinker/deals' + param);
    }

    getMyThinkerPodDeals(): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>('/thinker/my-thinker-pod-deals');
    }

    getThinkerPods(): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>('/thinker/manage-thinker-pods');
    }

    async declineDeal(url: string, reasonForDecline: string): Promise<any> {
        return await this.fetchJson<any>(url, 'POST', {reason_for_decline: reasonForDecline});
    }

    async approveDeal(url: string): Promise<any> {
        return await this.fetchJson<any>(url);
    }

    getDealsRequiringManagerReview(isConciergeManager: boolean): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>(`/thinker/manager-review-requests${isConciergeManager ? '?is_concierge_manager=true' : ''}`);
    }

    getRateChangeRequests(isConciergeManager: boolean): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>(`/thinker/rate-change-requests${isConciergeManager ? '?is_concierge_manager=true' : ''}`);
    }

    getThinkersDropdown(isConciergeManager: boolean): Observable<ComponentSchema> {
        return this.fetchJsonObservable<ComponentSchema>(`/thinker/thinkers-dropdown${isConciergeManager ? '?is_concierge_manager=true' : ''}`);
    }

    getUnderwriterConditions(): Observable<[ActionHeaderSchema, TableSchema, ActionButton]> {
        return this.fetchJsonObservable<[ActionHeaderSchema, TableSchema, ActionButton]>('/thinker/conditions');
    }
}
