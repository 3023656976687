import { HttpService } from './http.service';
import { injectable } from 'inversify';
import { TableSchema } from '../models/schemas/table-schema';
import { SelectComponent } from '../models/schemas/form-schema';
import { Observable } from 'rxjs';

@injectable()
export class CesService extends HttpService {

    getDeals(): Observable<[TableSchema, SelectComponent[] | undefined]> {
        return this.fetchJsonObservable<[TableSchema, SelectComponent[] | undefined]>('/ces/get-active-deals');
    }

    assignDeal(): Observable<[TableSchema, SelectComponent[] | undefined]> {
        return this.fetchJsonObservable<[TableSchema, SelectComponent[] | undefined]>('/ces/assign-a-lead');
    }

    saveNotes(url: string, note: string | null): Promise<any> {
        return this.fetchJson<any>(url, 'POST', {ces_notes: note});
    }

    getLeadCount(): Observable<any> {
        return this.fetchJsonObservable<any>('ces/get-lead-count');
    }
}

