import { pushNotification, utils, Widget } from '@truenorthmortgage/olympus';
import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import FormHelper from '../../../components/form-helper/form-helper.component';
import { FormSchema } from '../../../models/schemas/form-schema';
import { SERVICES } from '../../../services';
import { SubmitToThinkService } from '../../../services/submit-to-think.service';

export interface CancelProps {}

const Cancel: FC<CancelProps> = () => {
    const [form, setForm] = useState<FormSchema>();
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const submitToThinkService = utils.injection.useInjection<SubmitToThinkService>(SERVICES.SubmitToThinkService);
    const params = utils.nav.useAllPossibleParams();

    useEffect(() => {
        if (params.deal_key) {
            const subscribe = submitToThinkService.getCancel(params.deal_key)
                .subscribe({
                    next: setForm,
                    error: e => {
                        dispatch(pushNotification({ class: 'error', message: e.toString() }));
                    }
                });

            return () => {
                subscribe.unsubscribe();
            };
        }
    }, []);

    const onSubmit = useCallback(({ uri }: { uri: string }) => {
        navigate(uri);
        dispatch(pushNotification({ class: 'success', message: intl.formatMessage({ id: 'Think Submission Cancelled' }) }));
    }, [navigate]);

    return form ? (
        <Widget>
            <FormHelper schema={form} onSubmit={onSubmit} />
        </Widget>
    ) : null;
};

export default Cancel;
