import { BasicModalHandle, CheckboxComponent, CheckboxOption, } from '@truenorthmortgage/olympus';
import { Dispatch, FC, RefObject, SetStateAction, useCallback, useMemo, useState } from 'react';
import FormHelper from '../components/form-helper/form-helper.component';
import { CheckboxComponent as CheckboxComponentSchema, FormSchema } from '../models/schemas/form-schema';

export interface AssignEmailComponentProps {
    checkboxSchema: CheckboxComponentSchema;
    setClasses: Dispatch<SetStateAction<{ think_number: string; deal_id: string; }>>;
    setUseDealId: Dispatch<SetStateAction<boolean>>;
}

export interface AssignEmailDealFormProps {
    schema: FormSchema,
    onSubmit: (data: any) => void,
    parentRef?: RefObject<BasicModalHandle>
}

const AssignEmailComponent: FC<AssignEmailComponentProps> = ({ checkboxSchema, setClasses, setUseDealId }) => {
    const useDealIdCheckboxHandler = useCallback((value: string | null) => {
        if (value === '1') {
            setUseDealId(true);
        }
        setClasses(value === '1' 
            ? {think_number: 'half hidden', deal_id: 'half'} 
            : {think_number: 'half', deal_id: 'half hidden'});
    }, []);

    return (
        <CheckboxComponent label={checkboxSchema.label} name={checkboxSchema.name} value={checkboxSchema.value} columnStyle={`${checkboxSchema.style ?? ''} ${checkboxSchema.type}`} onChange={useDealIdCheckboxHandler} >
            {Object.keys(checkboxSchema.options).map((key: string) =>
                <CheckboxOption key={key} label={key} value={checkboxSchema.options[key].toString()} />
            )}
        </CheckboxComponent>
    );
};

const AssignEmailDealForm: FC<AssignEmailDealFormProps> = ({ schema, onSubmit, parentRef}) => {
    const [classes, setClasses] = useState<{think_number: string, deal_id: string}>({
        think_number: '',
        deal_id: ''
    });

    const [useDealId, setUseDealId] = useState<boolean>(false);

    const onCustomPayload = useCallback(async () => {
        return { use_deal_id: useDealId };
    }, [useDealId]);

    const updatedComponents = useMemo(() => {
        return Object.assign({}, schema, {
            components: schema.components.map(component => {
                if (component.type === 'text' && component.name === 'think_number') {
                    return Object.assign({}, component, { style: classes.think_number === '' ? component.style : classes.think_number });
                } else if (component.type === 'text' && component.name === 'deal_id') {
                    return Object.assign({}, component, { style: classes.deal_id === '' ? component.style : classes.deal_id });
                } else if (component.type === 'checkbox') {
                    return {
                        type: 'react',
                        element: <AssignEmailComponent checkboxSchema={component} setClasses={setClasses} setUseDealId={setUseDealId} />
                    };
                }
                return component;
            })
        });
    }, [schema, classes]);

    return updatedComponents 
        ? <FormHelper schema={updatedComponents} onSubmit={onSubmit} onCustomPayload={onCustomPayload} parentRef={parentRef} /> 
        : null;
};

export default AssignEmailDealForm;
