import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { timer, tap, zip, switchMap } from 'rxjs';
import DealsQueue from '../../components/deals-queue/deals-queue.component';
import TableHelper from '../../components/table-helper/table-helper.component';
import { DealsQueueData } from '../../models/deals/deals-queue';
import { TableSchema } from '../../models/schemas/table-schema';
import { SERVICES } from '../../services';
import { CloserDealsService } from '../../services/closer-deals.service';
import { DealService } from '../../services/deal.service';
import { HttpService } from '../../services/http.service';

const CloserDeals = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [closableDealsRows, setClosableDealsRows] = useState<TableSchema | null>(null);
    const [closingDealsRows, setClosingDealsRows] = useState<TableSchema | null>(null);
    const [thinkerPodClosingDealsRows, setThinkerPodClosingDealsRows] = useState<TableSchema | null>(null);
    const [dealsQueue, setDealsQueue] = useState<DealsQueueData | null>(null);
    const dealsService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const closerDealsService = utils.injection.useInjection<CloserDealsService>(SERVICES.CloserDealsService);

    const statusChangeCallback = useCallback(() => {
        dispatch(pushNotification({ class: 'success', message: intl.formatMessage({ id: 'Deal status has been updated!' }) }));
        HttpService.subscribe(loadData(), dispatch);
    }, [intl, dispatch]);

    const loadData = useCallback(() => {
        return zip(
            dealsService.getDealsQueue(),
            closerDealsService.getClosableDeals(),
            closerDealsService.getCloserDeals(),
            closerDealsService.getPodCloserDeals(),
        ).pipe(
            tap({
                next: ([dealsQueue, closableDealsRows, closingDealsRows, thinkerPodClosingDealsRows]) => {
                    setDealsQueue(dealsQueue);
                    setClosableDealsRows(closableDealsRows);
                    setClosingDealsRows(closingDealsRows);
                    setThinkerPodClosingDealsRows(thinkerPodClosingDealsRows);
                }
            })
        );
    }, [setClosableDealsRows, setClosingDealsRows, setDealsQueue]);

    const reloadData = useCallback((data?: {submit: boolean}) => {
        if (data?.submit) {
            HttpService.subscribe(loadData(), dispatch);
        }
    }, [loadData, dispatch]);

    useEffect(() => {
        const subscription = HttpService.subscribe(timer(0, 60000).pipe(switchMap(loadData)), dispatch);
        return () => {
            subscription.unsubscribe();
        };
    }, [loadData]);

    return closableDealsRows && closingDealsRows && dealsQueue && thinkerPodClosingDealsRows ? (
        <>
            <DealsQueue status={dealsQueue} />
            <TableHelper schema={closableDealsRows} includeHeader={true} onModalClose={reloadData} statusChangeCallback={statusChangeCallback} />
            <TableHelper schema={closingDealsRows} includeHeader={true} onModalClose={reloadData} statusChangeCallback={statusChangeCallback} />
            <TableHelper schema={thinkerPodClosingDealsRows} includeHeader={true} onModalClose={reloadData} statusChangeCallback={statusChangeCallback} />
        </>
    ) : null;
};

export default CloserDeals;
