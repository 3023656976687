import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';
import AccordionHelper from '../../../components/accordion-helper/accordion-helper.component';
import DealHeader from '../../../components/deal-header/deal-header.component';
import { Deal } from '../../../models/deals/deal';
import { SERVICES } from '../../../services';
import { DealService } from '../../../services/deal.service';

const DealsDetails = () => {
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const dispatch = useDispatch();
    const [deal, setDeal] = useState<Deal | null>(null);
    const params = utils.nav.useAllPossibleParams();

    useEffect(() => {
        document.title = 'Deal ' + params.deal_id;
        
        return () => {
            document.title = 'True North Mortgage - Zeus';
        };
    }, [params.deal_id]);

    const loadData = useCallback(() => {
        if (params.deal_id) {
            const subscribe = dealService.getDeal(params.deal_id)
                .subscribe({
                    next: setDeal,
                    error: e => {
                        dispatch(pushNotification({ class: 'error', message: e.toString() }));
                    }
                });

            return () => {
                subscribe.unsubscribe();
            };
        }
    }, [dealService, params.deal_id, setDeal, dispatch]);

    const reloadData = useCallback((data?: {submit: boolean}) => {
        if (data?.submit) {
            loadData();
        }
    }, [loadData]);

    useEffect(loadData, [params.deal_id, dealService, dispatch]);

    if (!params.deal_id) {
        return (<Navigate to="/deals/overview" />);
    }

    return deal ? (
        <>
            <DealHeader header={deal.header} onModalClose={reloadData} />
            <AccordionHelper accordion={deal.applicant} />
            <AccordionHelper accordion={deal.status} onModalClose={reloadData} />
            <AccordionHelper accordion={deal.dealData} onModalClose={reloadData} />
            { deal.concierge ?
                <AccordionHelper accordion={deal.concierge} onModalClose={reloadData} />
                : null }
            { deal.heloc ?
                <AccordionHelper accordion={deal.heloc} onModalClose={reloadData} />
                : null }
            <AccordionHelper accordion={deal.property} />
        </>
    ) : null;
};

export default DealsDetails;
