import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ActionHeaderHelper from '../../components/action-header-helper/action-header-helper.component';
import TableHelper from '../../components/table-helper/table-helper.component';
import { ActionHeaderSchema } from '../../models/schemas/action-header';
import { TableSchema } from '../../models/schemas/table-schema';
import { SERVICES } from '../../services';
import { FunderService } from '../../services/funder.service';
import ComponentHelper from '../../components/component-helper/component-helper.component';
import { SelectComponent } from '../../models/schemas/form-schema';
import { useCallback, useMemo } from 'react';
import { ComponentSchema } from '../../models/schemas/form-schema';

const FunderStatistics = () => {
    const funderService = utils.injection.useInjection<FunderService>(SERVICES.FunderService);
    const [data, setData] = useState<[ActionHeaderSchema, TableSchema, ComponentSchema] | null>(null);
    const [filters, setFilters] = useState<string | null>(null);
    const dispatch = useDispatch();

    const loadFunderStatisticsData = useCallback(() => {
        const subscription = funderService.getFunderStatistics(filters).subscribe({
            next: setData,
            error: (e) => {
                dispatch(
                    pushNotification({ class: 'error', message: e.toString() })
                );
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [filters, funderService, dispatch, setData]);

    useEffect(loadFunderStatisticsData, [loadFunderStatisticsData]);

    return data ? (
        <>
            <ActionHeaderHelper schema={data[0]}/>
            {data[2] ? <ComponentHelper onChange={setFilters} schema={data[2]}/> : null}
            <TableHelper schema={data[1]} includeHeader={true}/>
        </>
    ) : null;
};

export default FunderStatistics;
