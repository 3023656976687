import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import TableHelper from '../../../components/table-helper/table-helper.component';
import { TableSchema } from '../../../models/schemas/table-schema';
import { SERVICES } from '../../../services';
import { DealService } from '../../../services/deal.service';
import DealSearchModal from './deal-search-modal.component';

const DealSearch = () => {
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const intl = useIntl();
    const dispatch = useDispatch();
    const [deals, setDeals] = useState<TableSchema | null>(null);

    const getData = useCallback((dealId?: string) => {
        const subscription = dealService.seachDeals(dealId).subscribe({
            next: setDeals,
            error: (e) => {
                dispatch(
                    pushNotification({ class: 'error', message: e.toString() })
                );
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [dealService, dispatch, setDeals]);

    useEffect(getData, [getData]);

    const filterModal = useMemo(() => {
        return {
            props: {
                modalHeader: intl.formatMessage({ id: 'Sort' }),
                loadProps: async () => ({
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onSave: () => {}
                })
            },
            element: <DealSearchModal onSubmit={getData} />,
            onEnterPressed: (s: string) => getData(s)
        };
    }, [getData]);

    return deals ? (
        <TableHelper schema={deals} includeHeader={true} filterModal={filterModal} />
    ) : null;
};

export default DealSearch;
