export class ValidationError extends Error {
    constructor(public errors: Record<string, string[]>) {
        let errorString = '<ul>';
        Object.values(errors).forEach(key => {
            errorString += Array.isArray(key) ? key.reduce((acc, error) => acc + `<li>${error}</li>`, '') : key;
        });
        errorString += '</ul>';
        super(errorString);
    }
}
