import { NavLayout, NavLayoutContainerConfig, utils, Switch as SwitchToggle, setDarkMode as setDarkModeRedux } from '@truenorthmortgage/olympus';
import { useEffect, useMemo, useState } from 'react';
import { IntlShape, useIntl, FormattedMessage } from 'react-intl';
import { Navigate, NavigateFunction, Route, useLocation, useNavigate } from 'react-router';
import logo from '../../resources/images/think-logo-stacked.svg';
import userImage from '../../resources/images/user-image-fallback.png';
import { SERVICES } from '../../services';
import { AuthService } from '../../services/auth.service';
import CloserDeals from '../closer-deals/closer-deals.component';
import DealSearch from '../deals/deal-search/deal-search.component';
import DealsDetails from '../deals/detail/deals-detail.component';
import DealDocuments from '../deals/documents/deals-document.component';
import DealFunding from '../deals/funding/deal-funding.component';
import DealMessages from '../deals/messages/deal-messages.component';
import DealNotes from '../deals/notes/deal-notes.component';
import DealsOverview from '../deals/overview/deals-overview.component';
import DealRating from '../deals/rating/deal-rating.component';
import DealLead from '../deals/lead/deal-lead.component';
import DocumentSpecialistDashboard from '../deals/document-specialist-dashboard/document-specialist-dashboard.component';
import FunderManagement from '../funders/funder-management.component';
import FunderStatistics from '../funders/funder-statistics.component';
import FunderOverview from '../funders/funder-overview.component';
import LoginPage from '../login/login.component';
import Search from '../rate-wizard/search.component';
import Start from '../submit-to-think/start/start.component';
import Underwriting from '../underwriting/overview/overview.component';
import './default.style.scss';
import ManagerDeals from '../manager-deals/manager-deals.component';
import AdminUsers from '../admin/users.component';
import Security from '../users/security/security.component';
import { Session } from '../../models/auth/session';
import ApplicantInformation from '../submit-to-think/applicant-information/applicant-information.component';
import MortgagePurpose from '../submit-to-think/mortgage-purpose/mortgage-purpose.component';
import MortgageDefaultInsurance from '../submit-to-think/mortgage-default-insurance/mortgage-default-insurance.component';
import Switch from '../submit-to-think/switch/switch.component';
import Cancel from '../submit-to-think/cancel/cancel.component';
import LTV from '../submit-to-think/ltv/ltv.component';
import PreferredFunder from '../submit-to-think/preferred-funder/preferred-funder.component';
import IncludeHeloc from '../submit-to-think/include-heloc/include-heloc.component';
import FixedOrVariable from '../submit-to-think/fixed-or-variable/fixed-or-variable.component';
import FixedRate from '../submit-to-think/fixed-rate/fixed-rate.component';
import FixedTerm from '../submit-to-think/fixed-term/fixed-term.component';
import VariableRate from '../submit-to-think/variable-rate/variable-rate.component';
import DealCosts from '../submit-to-think/deal-costs/deal-costs.component';
import HedgeNowStep from '../submit-to-think/hedge-now-step/hedge-now-step.component';
import SubmitStep from '../submit-to-think/submit-step/submit-step.component';
import { createSelector, Dispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { setSession } from '../../reducers/session.reducer';
import UnsetPersona from '../admin/unset-persona.component';
import UninsuredUninsurable from '../submit-to-think/uninsured-uninsurable/uninsured-uninsurable.component';
import ContainerModal from '../../components/container-modal/container-modal.component';
import { NotificationService } from '../../services/notification.service';
import FunderReports from '../funders/funder-reports.component';
import FunderTaskQueue from '../funders/funder-taskqueue.component';
import ThinkerConditions from '../underwriting/underwriting-conditions.component';
import DealConditions from '../deals/conditions/deal-conditions.component';
import ThinkerPods from '../underwriting/thinker-pods/thinker-pods.component';
import DealConcierge from '../deals/concierge/deal-concierge.component';
import ThinkerPodReport from '../reports/thinker-pod-report.component';
import FunderPipeline from '../reports/funder-pipeline.component';
import ThinkerBonusReport from '../reports/thinker-bonus-report.component';
import FunderBonusReport from '../reports/funder-bonus-report.component';
import DealHistory from '../deals/history/deal-history.component';
import AgentRatingReport from '../reports/agent-rating-report.component';
import RequestForFunding from '../reports/request-for-funding.component';
import AllNextActions from '../users/next-actions/all-next-actions.component';
import CesDashboard from '../ces/ces-dashboard/ces-dashboard.component';
import CesReport from '../reports/ces-report.component';
import DeadNoContactLeadsReport from '../reports/dead-no-contact-leads-report.component';
import FunderDealReport from '../reports/funder-deal-report.component';
import AdminMailingLists from '../admin/mailing-list.component';
import Commitment from '../manual/manual-commitment.component';
import VerbalerDraft from '../verbalers/verbaler-draft.component';
import VerbalerRequest from '../verbalers/verbaler-request.component';
import VerbalerOverview from '../verbalers/verbaler-overview.component';
import VerbalerCompanies from '../verbalers/verbaler-companies.component';
import Roles from '../rbac/roles';
import Tags from '../rbac/tags';
import CompletedVerbals from '../verbalers/completed-verbals.component';

function getPreviewConfiguration(
    session: Session | null,
    authService: AuthService,
    intl: IntlShape,
    dispatch: Dispatch<any>,
    navigate: NavigateFunction,
    darkMode: boolean,
    setDarkMode: (mode: boolean) => void,
): NavLayoutContainerConfig {
    const config: NavLayoutContainerConfig = {
        basePath: '/',
        initialUri: '/',
        logo: logo,
        logoAlt: 'THINK FINANCIAL',
        notFoundRoute: '/login',
        anonymousRoutes: ['/', '/login'],
        containerClassName: !session ? 'opacity-0' : '',
        items: [
            {
                'id': 'NavSubmitToThink',
                'name': intl.formatMessage({ id: 'Submit a Deal' }),
                'slug': 'submit-to-think',
                'icon': 'file',
                'children': [
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'start'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'cancel'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'applicant-information'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'mortgage-purpose'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'switch'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'uninsured-uninsurable'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'mortgage-default-insurance'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'ltv'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'include-heloc'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'preferred-funder'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'fixed-or-variable'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'fixed-term'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'fixed-rate'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'variable-rate'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'deal-costs'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'hedge-now'
                    },
                    {
                        'name': intl.formatMessage({ id: 'Submit a New Deal to THINK' }),
                        'hideSubNav': true,
                        'slug': 'submit'
                    },
                ]
            },
            {
                'id': 'NavMyDeals',
                'name': intl.formatMessage({ id: 'My Deals' }),
                'slug': 'deals',
                'icon': 'file-alt',
                'children': [
                    {
                        'id': 'SubnavMyDealsOverview',
                        'name': intl.formatMessage({ id: 'My THINK Financial Deals - Overview' }),
                        'slug': 'overview',
                        'hideSubNav': true,
                        'showItem': false
                    },
                    {
                        'id': 'SubnavDetails',
                        'name': intl.formatMessage({ id: 'Details' }),
                        'title': intl.formatMessage({ id: 'Deal #:deal_id - Details' }),
                        'slug': 'details',
                        'href': '#/deals/details?deal_id=:deal_id'
                    },
                    {
                        'id': 'SubnavLead',
                        'name': intl.formatMessage({ id: 'Lead' }),
                        'title': intl.formatMessage({ id: 'Deal #:deal_id - Lead' }),
                        'slug': 'lead',
                        'href': '#/deals/lead?deal_id=:deal_id'
                    },
                    {
                        'id': 'SubnavDocuments',
                        'name': intl.formatMessage({ id: 'Documents' }),
                        'title': intl.formatMessage({ id: 'Deal #:deal_id - Documents' }),
                        'slug': 'documents',
                        'href': '#/deals/documents?deal_id=:deal_id'
                    },
                    {
                        'id': 'SubnavNotes',
                        'name': intl.formatMessage({ id: 'Notes' }),
                        'title': intl.formatMessage({ id: 'Deal #:deal_id - Notes' }),
                        'slug': 'notes',
                        'href': '#/deals/notes?deal_id=:deal_id'
                    },
                    {
                        'id': 'SubnavMessages',
                        'name': intl.formatMessage({ id: 'Messages' }),
                        'title': intl.formatMessage({ id: 'Deal #:deal_id - Messages' }),
                        'slug': 'messages',
                        'href': '#/deals/messages?deal_id=:deal_id'
                    },
                    {
                        'id': 'SubnavFunding',
                        'name': intl.formatMessage({ id: 'Funding' }),
                        'title': intl.formatMessage({ id: 'Deal #:deal_id - Funding' }),
                        'slug': 'funding',
                        'href': '#/deals/funding?deal_id=:deal_id'
                    },
                    {
                        'id': 'SubnavRating',
                        'name': intl.formatMessage({ id: 'Rating' }),
                        'title': intl.formatMessage({ id: 'Deal #:deal_id - Rating' }),
                        'slug': 'rating',
                        'href': '#/deals/rating?deal_id=:deal_id'
                    },
                    {
                        'id': 'SubnavConditions',
                        'name': intl.formatMessage({ id: 'Conditions' }),
                        'title': intl.formatMessage({ id: 'Deal #:deal_id - Conditions' }),
                        'slug': 'conditions',
                        'href': '#/deals/conditions?deal_id=:deal_id'
                    },
                    {
                        'id': 'SubnavConcierge',
                        'name': intl.formatMessage({ id: 'Concierge Loan' }),
                        'title': intl.formatMessage({ id: 'Deal #:deal_id - Concierge Loan' }),
                        'slug': 'concierge',
                        'href': '#/deals/concierge?deal_id=:deal_id'
                    },
                    {
                        'id': 'SubnavHistory',
                        'name': intl.formatMessage({ id: 'History' }),
                        'title': intl.formatMessage({ id: 'Deal #:deal_id - History' }),
                        'slug': 'history',
                        'href': '#/deals/history?deal_id=:deal_id'
                    },
                    {
                        'id': 'SubnavIncomeRequest',
                        'name': intl.formatMessage({ id: 'Income Request' }),
                        'title': intl.formatMessage({ id: 'Deal #:deal_id - Income Request' }),
                        'slug': 'income-request',
                        'href': '#/deals/income-request?deal_id=:deal_id',
                    },
                ]
            },
            {
                'id': 'NavUnderwriting',
                'name': intl.formatMessage({ id: 'Underwriting' }),
                'slug': 'underwriting',
                'icon': 'gavel',
                'children': [
                    {
                        'id': 'SubnavUnderwriterOverview',
                        'name': intl.formatMessage({ id: 'Overview' }),
                        'title': intl.formatMessage({ id: 'Underwriting Deals - Overview' }),
                        'slug': 'overview',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavUnderwriterDocuments',
                        'name': intl.formatMessage({ id: 'Documents' }),
                        'slug': 'documents',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavConditions',
                        'name': intl.formatMessage({ id: 'Conditions'}),
                        'title': intl.formatMessage({ id: 'Underwriting Deals - Conditions' }),
                        'slug': 'conditions',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavThinkerPods',
                        'name': intl.formatMessage({id: 'Thinker Pods'}),
                        'title': intl.formatMessage({id: 'Manage Thinker Pods'}),
                        'slug': 'thinker-pods',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavManagerDeals',
                        'name': intl.formatMessage({id: 'Manager Deals'}),
                        'title': intl.formatMessage({id: 'Manager Deals'}),
                        'slug': 'manager-deals',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavConciergeManagerDeals',
                        'name': intl.formatMessage({id: 'Concierge Manager Deals'}),
                        'title': intl.formatMessage({id: 'Concierge Manager Deals'}),
                        'slug': 'concierge-manager-deals',
                        'hideSubNav': false,
                        'showItem': true
                    }
                ]
            },
            {
                'id': 'NavDocSpecialistDeals',
                'name': intl.formatMessage({ id: 'Dashboard' }),
                'slug': 'deals',
                'icon': 'home',
                'children': [
                    {
                        'name': intl.formatMessage({ id: 'Document Specialist Dashboard' }),
                        'id': 'SubnavDocumentSpecialistDashboard',
                        'slug': 'document-specialist-dashboard',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubNavAllActions',
                        'name': intl.formatMessage({ id: 'Doc Specialist Actions' }),
                        'slug': 'all-actions',
                        'hideSubNav': false,
                        'showItem': true
                    }
                ]
            },
            {
                'id': 'NavCesDashboard',
                'name': intl.formatMessage({ id: 'CES' }),
                'slug': 'ces',
                'icon': 'home',
                'children': [
                    {
                        'id': 'SubNavAllActions',
                        'name': 'CES Dashboard',
                        'slug': 'ces-dashboard',
                        'hideSubNav': false,
                        'showItem': true
                    }
                ]
            },
            {
                'id': 'NavRateWizard',
                'name': intl.formatMessage({ id: 'Rate Wizard' }),
                'slug': 'rates',
                'icon': 'magic',
                'children': [
                    {
                        'name': intl.formatMessage({ id: 'Rate Wizard - Search' }),
                        'slug': 'wizard',
                        'hideSubNav': true,
                        'showItem': false
                    },
                ]
            },
            {
                'id': 'NavFunders',
                'name': intl.formatMessage({ id: 'Funders' }),
                'slug': 'funder-deals',
                'icon': 'tasks',
                'children': [
                    {
                        'id': 'SubnavOverview',
                        'name': intl.formatMessage({ id: 'Tasks' }),
                        'title': intl.formatMessage({ id: 'Funder Deals - Overview' }),
                        'slug': 'overview',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavManagement',
                        'name': intl.formatMessage({ id: 'Funders - Management' }),
                        'title': intl.formatMessage({ id: 'Funders Management' }),
                        'slug': 'management',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavReports',
                        'name': intl.formatMessage({ id: 'Funders Reports' }),
                        'title': intl.formatMessage({ id: 'Funders - Reports' }),
                        'slug': 'reports',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavStatistics',
                        'name': intl.formatMessage({ id: 'Statistics' }),
                        'title': intl.formatMessage({ id: 'Funders - Statistics' }),
                        'slug': 'statistics',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavTaskQueue',
                        'name': intl.formatMessage({ id: 'Task Queue' }),
                        'title': intl.formatMessage({ id: 'Funders - Task Queue' }),
                        'slug': 'task-queue',
                        'hideSubNav': false,
                        'showItem': true
                    }
                ],
            },
            {
                'id': 'NavReports',
                'name': intl.formatMessage({ id: 'Reports' }),
                'slug': 'reports',
                'icon': 'pencil',
                'children': [
                    {
                        'id': 'SubnavThinkerBonusReport',
                        'name': intl.formatMessage({ id: 'Thinker Bonus Report' }),
                        'title': intl.formatMessage({ id: 'Thinker Bonus Report' }),
                        'slug': 'thinker-bonus-report',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavFunderBonusReport',
                        'name': intl.formatMessage({ id: 'Funder Bonus Report' }),
                        'title': intl.formatMessage({ id: 'Funder Bonus Report' }),
                        'slug': 'funder-bonus-report',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavThinkerPodReport',
                        'name': intl.formatMessage({ id: 'Thinker Pod Report' }),
                        'title': intl.formatMessage({ id: 'Thinker Pod Report' }),
                        'slug': 'thinker-pod-report',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavFunderPipeline',
                        'name': intl.formatMessage({ id: 'Funder Pipeline' }),
                        'title': intl.formatMessage({ id: 'Funder Pipeline' }),
                        'slug': 'funder-pipeline',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavAgentRatingReport',
                        'name': intl.formatMessage({ id: 'Agent Rating Report' }),
                        'title': intl.formatMessage({ id: 'Agent Rating Report' }),
                        'slug': 'agent-rating-report',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavRequestForFunding',
                        'name': intl.formatMessage({ id: 'Request for Funding' }),
                        'title': intl.formatMessage({ id: 'Request for Funding' }),
                        'slug': 'request-for-funding',
                    },
                    {
                        'id': 'SubnavFunderDealReport',
                        'name': intl.formatMessage({ id: 'Funder Deal Report' }),
                        'title': intl.formatMessage({ id: 'Funder Deal Report' }),
                        'slug': 'funder-deal-report',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavCESReport',
                        'name': intl.formatMessage({ id: 'CES Report' }),
                        'title': intl.formatMessage({ id: 'CES Report' }),
                        'slug': 'ces-report',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'id': 'SubnavDeadNoContactLeadsReport',
                        'name': intl.formatMessage({ id: 'Dead No Contact Leads' }),
                        'title': intl.formatMessage({ id: 'Dead No Contact Leads' }),
                        'slug': 'dead-no-contact-leads-report',
                        'hideSubNav': false,
                        'showItem': true
                    }
                ]
            },
            {
                'id': 'NavDealSearch',
                'name': intl.formatMessage({ id: 'Deal Search' }),
                'slug': 'deals',
                'icon': 'search',
                'children': [
                    {
                        'name': intl.formatMessage({ id: 'Deal Search' }),
                        'slug': 'search',
                        'hideSubNav': true,
                        'showItem': false
                    }
                ]
            },
            {
                'id': 'NavManualCommitment',
                'name': intl.formatMessage({ id: 'Manual Commitment' }),
                'slug': 'manual-commitment',
                'icon': 'plus',
                'children': [
                    {
                        'name': intl.formatMessage({ id: 'Manual Commitment' }),
                        'slug': 'commitment',
                        'hideSubNav': true,
                        'showItem': false
                    }
                ]
            },
            {
                'id': 'NavVerbalers',
                'name': intl.formatMessage({ id: 'Verbalers' }),
                'slug': 'verbalers',
                'icon': 'file-alt',
                'children': [
                    {
                        'name': intl.formatMessage({ id: 'Verbaler Queue' }),
                        'slug': 'verbaler-overview',
                        'hideSubNav': false,
                        'showItem': true,
                    },
                    {
                        'name': intl.formatMessage({ id: 'Verbaler Companies' }),
                        'slug': 'verbaler-companies',
                        'hideSubNav': false,
                        'showItem': true,
                    },
                    {
                        'name': intl.formatMessage({ id: 'Completed Verbals' }),
                        'slug': 'completed-verbals',
                        'hideSubNav': false,
                        'showItem': true,
                    },
                    {
                        'name': intl.formatMessage({ id: 'Verbaler Draft' }),
                        'slug': 'draft',
                        'hideSubNav': true,
                        'showItem': false,
                        'href': '#/verbalers/draft',
                    },
                ],
            },
            {
                'id': 'NavAdmin',
                'name': intl.formatMessage({ id: 'Admin' }),
                'slug': 'admin',
                'icon': 'key',
                'children': [
                    {
                        'name': intl.formatMessage({ id: 'Users' }),
                        'title': intl.formatMessage({ id: 'Admin - Users' }),
                        'slug': 'users',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'name': intl.formatMessage({ id: 'Mailing Lists' }),
                        'title': intl.formatMessage({ id: 'Admin - Mailing Lists' }),
                        'slug': 'mailing_lists',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'name': intl.formatMessage({ id: 'Roles' }),
                        'slug': 'roles',
                        'hideSubNav': false,
                        'showItem': true
                    },
                    {
                        'name': intl.formatMessage({ id: 'Permission Tags' }),
                        'slug': 'tags',
                        'hideSubNav': false,
                        'showItem': true
                    },
                ]
            },
            {
                'slug': 'users',
                'hideItem': true,
                'children': [
                    {
                        'name': intl.formatMessage({ id: 'Security' }),
                        'slug': 'security',
                        'hideSubNav': true,
                        'showItem': false
                    }
                ]
            },
            {
                'id': 'NavUnsetPersona',
                'name': intl.formatMessage({ id: 'Unset Persona' }),
                'slug': 'admin',
                'icon': 'key',
                'children': [
                    {
                        'name': intl.formatMessage({ id: 'Unset Persona' }),
                        'slug': 'unset-persona',
                        'hideSubNav': true,
                        'showItem': false
                    }
                ]
            },
        ],
        bottomBar: {
            userImage,
            user: {
                name: session?.user?.name ?? '',
                role: session?.user?.role ?? '',
            },
            isAvailable: session?.isAvailable,
            items: [
                {
                    customChild: (
                        <span className="dark-mode-toggle-item">
                            <SwitchToggle defaultChecked={darkMode} onChange={() => setDarkMode(!darkMode)} />
                            <FormattedMessage id="Dark mode" />
                        </span>
                    )
                },
                {
                    text: intl.formatMessage({ id: 'Set yourself' })
                        + ' <strong>'
                        + (session?.isAvailable ? intl.formatMessage({ id: 'AWAY' }) : intl.formatMessage({ id: 'AVAILABLE' }))
                        + '</strong>',
                    icon: (session?.isAvailable ? 'eye-slash' : 'eye'),
                    onClick: () => {
                        authService.toggleAvailable(dispatch);
                    }
                },
                {
                    text: intl.formatMessage({ id: 'Security' }),
                    icon: 'lock',
                    navigateTo: '/users/security'
                },
                {
                    text: intl.formatMessage({ id: 'Settings' }),
                    icon: 'cog',
                    modal: {
                        elem: (<ContainerModal />),
                        loadProps: async () => ({
                            data: await authService.getUserSettignsForm(),
                            successMessage: intl.formatMessage({ id: 'User Settings updated' })
                        }),
                        header: intl.formatMessage({ id: 'User Settings' })
                    }
                },
                {
                    text: 'Sign Out',
                    icon: 'sign-out-alt',
                    onClick: () => {
                        authService.logOut(navigate);
                    }
                }
            ]
        }
    };

    const itemIds = session?.nav_items?.map(item => item.id) ?? [];
    config.items = config.items.filter(item => {
        if (item.id === 'NavUnsetPersona' && authService.impersonatedToken === null) {
            return false;
        }
        return !item.id || itemIds.length === 0 || itemIds.includes(item.id);
    });

    config.items.forEach(item => {
        const itemConfig = session?.nav_items?.find(navItem => navItem.id === item.id);
        if (itemConfig?.subnav.length ?? 0 > 0) {
            const subItemIds = itemConfig!.subnav.map(sub => sub.id);
            item.children = item.children?.filter(child => {
                return !child.id || subItemIds.length === 0 || subItemIds.includes(child.id);
            });
        }

        if (itemConfig?.visible === false) {
            item.hideItem = true;
        }
    });

    if (session?.initial_uri && session.initial_uri.length) {
        config.initialUri = session.initial_uri;
    }

    utils.nav.setPathsInItems(config);

    return config;
}

const selectSession = createSelector(
    (state: any) => state.session,
    (session: Session | null) => session
);

const Default = () => {
    const intl = useIntl();
    const session = useSelector<any, Session | null>((state) => selectSession(state));
    const [darkMode, setDarkMode] = useState(() => localStorage.getItem('useDarkMode') === 'true');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authService = utils.injection.useInjection<AuthService>(SERVICES.AuthService);
    const notificationService = utils.injection.useInjection<NotificationService>(SERVICES.NotificationService);
    const location = useLocation();
    const navConfig = useMemo(() => {
        return getPreviewConfiguration(session, authService, intl, dispatch, navigate, darkMode, setDarkMode);
    }, [session, authService, intl, dispatch, navigate, darkMode, setDarkMode]);

    useEffect(() => {
        dispatch(setDarkModeRedux(darkMode));
        localStorage.setItem('useDarkMode', darkMode.toString());
    }, [darkMode, dispatch]);

    useEffect(() => {
        authService.getSession(location).then(session => {
            if (session) {
                dispatch(setSession(session));
                notificationService.pushNotification();
            }
        });
    }, [authService, notificationService]);

    return navConfig ? (
        <NavLayout navConfig={navConfig}>
            <Route path="/submit-to-think/start" element={<Start />} />
            <Route path="/submit-to-think/cancel" element={<Cancel />} />
            <Route path="/submit-to-think/applicant-information" element={<ApplicantInformation />} />
            <Route path="/submit-to-think/mortgage-purpose" element={<MortgagePurpose />} />
            <Route path="/submit-to-think/switch" element={<Switch />} />
            <Route path="/submit-to-think/uninsured-uninsurable" element={<UninsuredUninsurable />} />
            <Route path="/submit-to-think/mortgage-default-insurance" element={<MortgageDefaultInsurance />} />
            <Route path="/submit-to-think/ltv" element={<LTV />} />
            <Route path="/submit-to-think/preferred-funder" element={<PreferredFunder />} />
            <Route path="/submit-to-think/include-heloc" element={<IncludeHeloc />} />
            <Route path="/submit-to-think/fixed-or-variable" element={<FixedOrVariable />} />
            <Route path="/submit-to-think/fixed-rate" element={<FixedRate />} />
            <Route path="/submit-to-think/fixed-term" element={<FixedTerm />} />
            <Route path="/submit-to-think/variable-rate" element={<VariableRate />} />
            <Route path="/submit-to-think/deal-costs" element={<DealCosts />} />
            <Route path="/submit-to-think/hedge-now" element={<HedgeNowStep />} />
            <Route path="/submit-to-think/submit" element={<SubmitStep />} />
            <Route path="/deals/overview" element={<DealsOverview />} />
            <Route path="/deals/details" element={<DealsDetails />} />
            <Route path="/deals/documents" element={<DealDocuments />} />
            <Route path="/deals/notes" element={<DealNotes />} />
            <Route path="/deals/funding" element={<DealFunding />} />
            <Route path="/deals/messages" element={<DealMessages />} />
            <Route path="/deals/rating" element={<DealRating />} />
            <Route path="/deals/lead" element={<DealLead />} />
            <Route path="/deals/conditions" element={<DealConditions/>} />
            <Route path="/deals/concierge" element={<DealConcierge />} />
            <Route path="/deals/history" element={<DealHistory />} />
            <Route path="/underwriting/manager-deals" element={<ManagerDeals />} />
            <Route path="/underwriting/concierge-manager-deals" element={<ManagerDeals isConcierge={true} />} />
            <Route path="/underwriting/overview" element={<Underwriting />} />
            <Route path="/underwriting/documents" element={<CloserDeals />} />
            <Route path="/underwriting/thinker-pods" element={<ThinkerPods />} />
            <Route path="/underwriting/conditions" element={<ThinkerConditions/>} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/deals/document-specialist-dashboard" element={<DocumentSpecialistDashboard />} />
            <Route path="/rates/wizard" element={<Search />} />
            <Route path="/funder-deals/overview" element={<FunderOverview />} />
            <Route path="/funder-deals/management" element={<FunderManagement />} />
            <Route path="/funder-deals/reports" element={<FunderReports />} />
            <Route path="/funder-deals/statistics" element={<FunderStatistics />} />
            <Route path="/funder-deals/task-queue" element={<FunderTaskQueue />} />
            <Route path="/reports/thinker-pod-report" element={<ThinkerPodReport />} />
            <Route path="/reports/funder-pipeline" element={<FunderPipeline />} />
            <Route path="/reports/thinker-bonus-report" element={<ThinkerBonusReport />} />
            <Route path="/reports/funder-bonus-report" element={<FunderBonusReport />} />
            <Route path="/reports/agent-rating-report" element={<AgentRatingReport />} />
            <Route path="/reports/request-for-funding" element={<RequestForFunding />} />
            <Route path="/reports/funder-deal-report" element={<FunderDealReport/>} />
            <Route path="/deals/all-actions" element={<AllNextActions />} />
            <Route path="/ces/ces-dashboard" element={<CesDashboard />} />
            <Route path="/reports/ces-report" element={<CesReport />} />
            <Route path="/reports/dead-no-contact-leads-report" element={<DeadNoContactLeadsReport />} />
            <Route path="/deals/search" element={<DealSearch />} />
            <Route path="/admin/users" element={<AdminUsers />} />
            <Route path="/admin/mailing_lists" element={<AdminMailingLists />} />
            <Route path="/admin/unset-persona" element={<UnsetPersona />} />
            <Route path="/admin/roles" element={<Roles />} />
            <Route path="/admin/tags" element={<Tags />} />
            <Route path="/users/security" element={<Security />} />
            <Route path="/manual-commitment/commitment" element={<Commitment />} />
            <Route path="*" element={navConfig.items.length > 0 ? <Navigate replace to={navConfig.initialUri} /> : <Navigate replace to="/login" />} />
            <Route path="/" element={navConfig.initialUri !== '/' && <Navigate replace to={navConfig.initialUri} /> } />
            <Route path="/deals/income-request" element={<VerbalerRequest />} />
            <Route path="/verbalers/verbaler-overview" element={<VerbalerOverview />} />
            <Route path="/verbalers/verbaler-companies" element={<VerbalerCompanies />} />
            <Route path="/verbalers/draft" element={<VerbalerDraft />} />
            <Route path="/verbalers/completed-verbals" element={<CompletedVerbals />} />
        </NavLayout>
    ) : null;
};

export default Default;
