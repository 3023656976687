import { HttpService } from './http.service';
import {Observable} from 'rxjs';
import {TableSchema} from '../models/schemas/table-schema';
export class ActionService extends HttpService {
    getAllActions(): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>('/next-actions/all-actions');
    }

    getTodaysActions(): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>('/next-actions/due-actions');
    }
}
