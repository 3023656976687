import { pushNotification, Timeline, TimelineDetail, utils } from '@truenorthmortgage/olympus';
import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SERVICES } from '../../../services';
import { DealHeader as DealHeaderModel } from '../../../models/deals/header';
import { DealService } from '../../../services/deal.service';
import DealHeader from '../../../components/deal-header/deal-header.component';
import { DealNotesTimeline } from '../../../models/deals/notes';
import CorrespondenceItem from '../../../components/correspondence-item/correspondence-item.component';
import { zip } from 'rxjs';

export interface DealMessagesProps {}

const DealMessages: FC<DealMessagesProps> = () => {
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const dispatch = useDispatch();
    const [dealHeader, setDealHeader] = useState<DealHeaderModel | null>(null);
    const [timeline, setTimeline] = useState<DealNotesTimeline | null>(null);
    const params = utils.nav.useAllPossibleParams();

    useEffect(() => {
        if (params.deal_id) {
            const subscription = zip(
                dealService.getHeader(params.deal_id),
                dealService.getDealMessages(params.deal_id)
            ).subscribe({
                next: ([dealHeader, timeline]) => {
                    setDealHeader(dealHeader);
                    setTimeline(timeline);
                },
                error: e => {
                    dispatch(pushNotification({ class: 'error', message: e.toString() }));
                }
            });

            return () => {
                subscription.unsubscribe();
            };
        }
    }, [params.deal_id, dealService, dispatch]);

    const itemButtons = useMemo(() => dealService.getTimelineItemButtons(timeline), [dealService, timeline]);

    return dealHeader && timeline && itemButtons ? (
        <>
            <DealHeader header={dealHeader} />
            <Timeline title={timeline.title} categoryLabel={timeline.category_label} bordered={timeline.settings.border}>
                {timeline.timeline_items.map((item, index) => (
                    <CorrespondenceItem
                        key={index}
                        correspondenceHeading={item.correspondence_heading}
                        correspondenceHeadingClasses={item.correspondence_heading_classes}
                        actionButtons={itemButtons[index]}
                        category={item.category}
                        dateTime={new Date(item.datetime)}
                        icon={item.icon}
                        headline={item.headline}
                        expanded={item.details.expanded}>
                        <TimelineDetail copy={item.details.copy} raw={true} />
                    </CorrespondenceItem>
                ))}
            </Timeline>
        </>
    ) : null;
};

export default DealMessages;
