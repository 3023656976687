import { utils } from '@truenorthmortgage/olympus';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import { SERVICES } from '../../services';
import { AuthService } from '../../services/auth.service';

export interface UnsetPersonaProps {}

const UnsetPersona: FC<UnsetPersonaProps> = () => {
    const authService = utils.injection.useInjection<AuthService>(SERVICES.AuthService);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    authService.unsetPersona(dispatch, navigate);
    return (
        <Navigate to="/" />
    );
};

export default UnsetPersona;
