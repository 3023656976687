import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TableHelper from '../../components/table-helper/table-helper.component';
import { TableSchema } from '../../models/schemas/table-schema';
import { SERVICES } from '../../services';
import { FunderService } from '../../services/funder.service';

const FunderReports = () => {
    const funderService = utils.injection.useInjection<FunderService>(SERVICES.FunderService);
    const dispatch = useDispatch();
    const [reports, setReports] = useState<TableSchema | null>(null);

    useEffect(() => {
        const subscription = funderService.getFunderReports().subscribe({
            next: setReports,
            error: e => {
                dispatch(pushNotification({ class: 'error', message: e.toString() }));
            }
        });
        
        return () => {
            subscription.unsubscribe();
        };
    }, [funderService, setReports]);
    
    return reports 
        ? <TableHelper schema={reports} includeHeader={true} />
        : null;
};

export default FunderReports;
