import { BasicModalHandle, utils } from '@truenorthmortgage/olympus';
import { FC, RefObject, useCallback } from 'react';
import FormHelper from '../components/form-helper/form-helper.component';
import { FormSchema } from '../models/schemas/form-schema';
import { SERVICES } from '../services';
import { UsersService } from '../services/users.service';

export type AddSecurityKeyProps = {
    schema: FormSchema,
    onSubmit: (data: any) => void,
    parentRef?: RefObject<BasicModalHandle>
};

const AddSecurityKey: FC<AddSecurityKeyProps> = ({ schema, onSubmit, parentRef }) => {
    const usersService = utils.injection.useInjection<UsersService>(SERVICES.UsersService);

    const onCustomPayload = useCallback(async (payload: Record<string, any>) => {
        const publicKey = Object.assign({}, schema.data, {user: Object.assign({}, schema.data.user)});
        publicKey.challenge = Uint8Array.from(
            window.atob(usersService.base64url2base64(publicKey.challenge)), 
            c => c.charCodeAt(0)
        );
        publicKey.user.id = Uint8Array.from(window.atob(publicKey.user.id), c => c.charCodeAt(0));
        if (publicKey.excludeCredentials) {
            publicKey.excludeCredentials = publicKey.excludeCredentials.map(function (data: any) {
                data.id = Uint8Array.from(window.atob(usersService.base64url2base64(data.id)), function (c) { return c.charCodeAt(0); });
                return data;
            });
        }
        
        const data: any = await navigator.credentials.create({ 'publicKey': publicKey });
        if (data) {
            const publicKeyCredential = {
                id: data.id,
                type: data.type,
                rawId: usersService.arrayToBase64String(new Uint8Array(data.rawId)),
                response: {
                    clientDataJSON: usersService.arrayToBase64String(new Uint8Array(data.response.clientDataJSON)),
                    attestationObject: usersService.arrayToBase64String(new Uint8Array(data.response.attestationObject))
                }
            };
    
            return {
                key_desc: payload.nickname,
                reg_data: btoa(JSON.stringify(publicKeyCredential))
            };
        }
        return payload;
    }, [schema, usersService]);

    return (<FormHelper schema={schema} onSubmit={onSubmit} parentRef={parentRef} onCustomPayload={onCustomPayload} />);
};

export default AddSecurityKey;
