import { Observable } from 'rxjs';
import { HistoryTimeline } from '../models/deals/history';
import { ActionHeaderSchema } from '../models/schemas/action-header';
import { TableSchema } from '../models/schemas/table-schema';
import { HttpService } from './http.service';
import { ComponentSchema, SelectComponent } from '../models/schemas/form-schema';

export class FunderService extends HttpService {
    getFunderOverview(): Observable<[ActionHeaderSchema, TableSchema, HistoryTimeline]> {
        return this.fetchJsonObservable<[ActionHeaderSchema, TableSchema, HistoryTimeline]>('/funder-deals/overview');
    }

    getFunderStatistics(period: string | null): Observable<[ActionHeaderSchema, TableSchema, ComponentSchema]> {
        if(!period) {
            period = new Date().getFullYear() + '-' + ('0'+(new Date().getMonth()+1)).slice(-2)+'-01';
        }
        return this.fetchJsonObservable<[ActionHeaderSchema, TableSchema, ComponentSchema]>(`/funder-deals/statistics?period=${period}`);
    }

    getFunderManagement(date_range: string | null): Observable<[ActionHeaderSchema, TableSchema, SelectComponent, TableSchema]> {
        const query = date_range ? '?date_range=' + date_range : '';
        return this.fetchJsonObservable<[ActionHeaderSchema, TableSchema, SelectComponent, TableSchema]>('/funder-deals/management' + query);
    }

    getFunderTaskQueue(user_id: string | null ): Observable<[ActionHeaderSchema, TableSchema, ComponentSchema]> {
        return this.fetchJsonObservable<[ActionHeaderSchema, TableSchema, ComponentSchema]>(`/funder-deals/task-queue?funder_id=${user_id}`);
    }

    getFunderReports(): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>('/funder-deals/reports');
    }

    setDocumentApproved(documentId: string, value: boolean): Observable<any> {
        return this.fetchJsonObservable<[ActionHeaderSchema, TableSchema]>(`/funder-deal-tasks/task-approve-document?status=${value}&document_id=${documentId}`, 'POST');
    }
}
