import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SERVICES } from '../../../services';
import { DealHeader as DealHeaderModel } from '../../../models/deals/header';
import { DealService } from '../../../services/deal.service';
import DealHeader from '../../../components/deal-header/deal-header.component';
import { zip } from 'rxjs';
import TableHelper from '../../../components/table-helper/table-helper.component';
import { TableSchema } from '../../../models/schemas/table-schema';

const DealHistory = () => {
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const dispatch = useDispatch();
    const [dealHeader, setDealHeader] = useState<DealHeaderModel | null>(null);
    const [dealHistory, setDealHistory] = useState<TableSchema| null>(null);
    const params = utils.nav.useAllPossibleParams();
    const loadData = useCallback(() => {
        if (params.deal_id) {
            const subscription = zip(
                dealService.getHeader(params.deal_id),
                dealService.getHistory(params.deal_id)
            ).subscribe({
                next: ([dealHeader, dealHistory]) => {
                    setDealHeader(dealHeader);
                    setDealHistory(dealHistory);
                },
                error: e => {
                    dispatch(pushNotification({ class: 'error', message: e.toString() }));
                }
            });

            return () => {
                subscription.unsubscribe();
            };
        }
    }, [params.deal_id, dealService, dispatch]);

    useEffect(loadData,[loadData]);

    return dealHeader && dealHistory ?
        <>
            <DealHeader header={dealHeader}/>
            <TableHelper schema={dealHistory} includeHeader={true}/>
        </>: null;
};

export default DealHistory;
