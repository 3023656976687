import { FileComponentValue, pushNotification } from '@truenorthmortgage/olympus';
import { inject, injectable } from 'inversify';
import { Dispatch } from 'react';
import { SERVICES } from '.';
import { ButtonComponent, FormSchema } from '../models/schemas/form-schema';
import { DealService } from './deal.service';
import { HttpService } from './http.service';
import { CustomizeSchema } from './interfaces/customize-schema';
import { Filters } from './report.service';

type RequestFormat = 'form-data' | 'json';

@injectable()
export class ComponentService {
    @inject(SERVICES.DealService) protected dealService: DealService | null = null;
    @inject(SERVICES.HttpService) protected httpService: HttpService | null = null;
    
    customizeSchema(schema: FormSchema): FormSchema {
        const customizers: CustomizeSchema[] = [
            this.dealService!
        ];
        customizers.forEach(c => c.customizeSchema(schema));
        return schema;
    }

    createFormData(payload: Record<string, string | FileComponentValue>): FormData {
        const formData = new FormData();

        Object.entries(payload).forEach(([key, value]) => {
            if (typeof value === 'object' && 'files' in value && value.files) {
                if (value.files.length === 1) {
                    formData.append(key, value.files[0], value.value);
                } else {
                    value.files.forEach(file => {
                        formData.append(`${key}[]`, file);
                    });
                }
            } else if (typeof value === 'string' || typeof value === 'number') {
                formData.append(key, value);
            }
        });

        return formData;
    }

    getFormat(payload: Record<string, string | FileComponentValue>): RequestFormat {
        if (Object.values(payload).some(value => typeof value === 'object' && value !== null && 'files' in value && value.files)) {
            return 'form-data';
        }
        return 'json';
    }

    createDownload(blob_data: string, fileName: string) {
        const link = document.createElement('a');
        link.href = blob_data;
        link.download = fileName;
        link.click();
    }

    addSubmitQueryParams(form: FormSchema, button: ButtonComponent) {
        if (button.submit_query_params === undefined) return;

        const queryParams: Filters = button.submit_query_params;
        const queryString = this.httpService!.formatQueryParams(queryParams, form.action.includes('?'));

        button.callback = (onSubmit) => {
            return onSubmit!({
                action: `${form.action}${queryString}`
            });
        };

        // makes sure that button is rendered with a callback, instead of as a typical submit
        const formTriggerIndex = button.classes?.indexOf('form-trigger');
        if (formTriggerIndex && formTriggerIndex !== -1) button.classes?.splice(formTriggerIndex, 1);
    }

    executeLinkTriggerClick(uri: string, dispatch: Dispatch<any>, onModalClose?: (data?: any) => void) {
        HttpService.subscribe(
            this.httpService!.fetchJsonObservable(uri),
            dispatch,
            (message: string) => {
                // only dispatch if message is a string. We cannot pass the response when the request is a 401 since message is not a string
                if (typeof message === 'string') {
                    dispatch(pushNotification({ class: 'success', message }));
                }
                
                if (onModalClose) {
                    onModalClose({ submit: true });
                }
            }
        );
    }
}
