import { Session } from '../models/auth/session';
import { injectable } from 'inversify';
import { HttpService } from './http.service';
import { Location } from 'history';
import { Dispatch } from '@reduxjs/toolkit';
import { setSession } from '../reducers/session.reducer';
import { NavigateFunction } from 'react-router';
import { FormSchema } from '../models/schemas/form-schema';
import { ValidationError } from '../errors/validation-error';

@injectable()
export class AuthService {
    private _token: string | null = null;
    private _impersonatedToken: string | null = null;
    private session: Session | null = null;

    get token() {
        const localStorageToken = localStorage.getItem('token');
        if (!this._token && localStorageToken) {
            this._token = localStorageToken;
        }
        return this._token;
    }

    set token(token: string | null) {
        this._token = token;
        if (token) {
            localStorage.setItem('token', token);
        } else {
            localStorage.removeItem('token');
        }
    }

    get impersonatedToken() {
        const localStorageToken = localStorage.getItem('impersonatedToken');
        if (!this._impersonatedToken && localStorageToken) {
            this._impersonatedToken = localStorageToken;
        }
        return this._impersonatedToken;
    }

    set impersonatedToken(token: string | null) {
        this._impersonatedToken = token;
        if (token) {
            localStorage.setItem('impersonatedToken', token);
        } else {
            localStorage.removeItem('impersonatedToken');
        }
    }

    async unsetPersona(dispatch: Dispatch<any>, navigate: NavigateFunction) {
        this.impersonatedToken = null;
        await this.requestSession();
        navigate('/');
        dispatch(setSession(this.session));
    }

    async logOut(navigate: NavigateFunction) {
        this.impersonatedToken = null;
        this.token = null;
        navigate('/');
    }

    async toggleAvailable(dispatch: Dispatch<any>) {
        if (this.session) {
            const availableParam = this.session.isAvailable ? '0' : '1';
            const [status, availability] = await HttpService.executeFetchRequest(`/users/set-user-availability?available=${availableParam}`, this.getAuthorizationHeaders());
            this.validateRequest(status, availability);
            this.session = Object.assign({}, this.session, {isAvailable: availability});
            dispatch(setSession(this.session));
        }
    }

    getAuthorizationHeaders({contentType}: {contentType: string | null} = {contentType: 'application/json'}): Record<string, string> {
        const headers: Record<string, string> = contentType ? {
            'Content-Type': contentType
        } : {};

        if (this.token) {
            headers['Authorization'] = `Bearer ${this.impersonatedToken ? this.impersonatedToken : this.token}`;
        }

        return headers;
    }

    async getSession(location: Location): Promise<Session> {
        if (!this.session && this.token) {
            await this.requestSession();
        } else if (!this.token && location.hash !== '#/login') {
            this.resetSession();
        }
        return this.session!;
    }

    getSessionSync(): Session | null {
        return this.session;
    }

    private async requestSession() {
        const [status, menuItems] = await HttpService.executeFetchRequest('/users/get-user-items', this.getAuthorizationHeaders());
        if (this.validateRequest(status, menuItems) === false) {
            return;
        }
        const [isAvailableStatus, isAvailable] = await HttpService.executeFetchRequest('/users/get-user-availability', this.getAuthorizationHeaders());
        this.validateRequest(isAvailableStatus, isAvailable);
        this.session = {
            nav_items: menuItems.nav_items,
            initial_uri: menuItems.initial_uri,
            isAvailable,
            rollbar_config: menuItems.rollbar_config,
            user: {
                id: menuItems.user.id,
                name: `${menuItems.user.first_name} ${menuItems.user.last_name}`,
                role: menuItems.user.role,
                email: menuItems.user.email
            }
        };
    }

    async getUserSettignsForm(): Promise<FormSchema> {
        const [status, settingsForm] = await HttpService.executeFetchRequest('/users/settings', this.getAuthorizationHeaders());
        this.validateRequest(status, settingsForm);
        return settingsForm;
    }

    validateRequest(status: number, json: any) {
        if (status === 401 || (status !== 200 && json.type === 'Firebase\\JWT\\ExpiredException')) {
            this.resetSession();
            return false;
        } else if (status === 422) {
            throw new ValidationError(json);
        } else if (status !== 200 && json.type !== 'yii\\web\\BadRequestHttpException' && json.type !== 'yii\\web\\NotFoundHttpException') {
            throw new Error(json.message);
        }
    }

    resetSession() {
        this.token = null;
        this.impersonatedToken = null;
        window.location.replace('#/login');
    }

    async logIn(token: string, dispatch: Dispatch<any>) {
        this.token = token;
        this.impersonatedToken = null;
        await this.requestSession();
        dispatch(setSession(this.session));
    }

    async getLogin2faOptions(token: string): Promise<any> {
        const [status, options] = await HttpService.executeFetchRequest('/login2fa',  {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
        this.validateRequest(status, options);
        return options;
    }

    async assumePersona(token: string, dispatch: Dispatch<any>, navigate: NavigateFunction) {
        this.impersonatedToken = token;
        await this.requestSession();
        navigate('/');
        dispatch(setSession(this.session));
    }
}
