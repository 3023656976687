import { BasicModalHandle, pushNotification, TextComponent, utils } from '@truenorthmortgage/olympus';
import { FC, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import FormHelper from '../components/form-helper/form-helper.component';
import TableHelper from '../components/table-helper/table-helper.component';
import { FormSchema, TextComponent as TextComponentSchema } from '../models/schemas/form-schema';
import { TableSchema } from '../models/schemas/table-schema';
import { SERVICES } from '../services';
import { ReportService } from '../services/report.service';

export type ThinkerBonusPeriodsProps = {
    schema: FormSchema
    onSubmit: (data: any) => void,
    parentRef?: RefObject<BasicModalHandle>
};

const ThinkerBonusPeriods: FC<ThinkerBonusPeriodsProps> = ({ schema, onSubmit, parentRef }) => {
    const reportService = utils.injection.useInjection<ReportService>(SERVICES.ReportService);
    const tableSchema = schema.components[0] as unknown as TableSchema;
    const [payload, setPayload] = useState<{ [id: string]: {[key:string]: string}}>({});
    const [hasError, setHasError] = useState<{ [key: string]: boolean}>({});
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const  dispatch = useDispatch();
    let timer: NodeJS.Timeout;

    const onCustomPayload = useCallback(async () => {
        return payload;
    }, [payload]);

    const validatePercentageSum = useCallback(() => {
        const allocatedBonusSum = Object.values(payload).reduce((sum, { bonus }) => sum + Number(bonus), 0);
        setSubmitDisabled(allocatedBonusSum.toFixed(2) !== '1.00');
    }, [payload, setSubmitDisabled]);

    useEffect(() => {
        validatePercentageSum();
    }, [validatePercentageSum]);

    const onChangeHanlder = useCallback((id: string | undefined, name: string | undefined, timer: NodeJS.Timeout) => (value: string | null) => {
        clearTimeout(timer);

        timer = setTimeout(async () => {
            if (id !== undefined && name !== undefined && value !== null) {
                if (name.includes('bonus') && (isNaN(Number(value)) || Number(value) < 0 || Number(value) > 1)) {
                    if (!hasError[name+id]) {
                        dispatch(pushNotification({ class: 'error', message: 'Please enter a valid positive number, that is less than 1.'}));
                        setHasError(prev => ({...prev, [name+id]: true}));
                        return;
                    }
                } else {
                    if (hasError[name+id]) {
                        setHasError(prev => ({...prev, [name+id]: false}));
                    }
                }
                
                if (!(id in payload) || !(name in payload[id]) || payload[id][name] !== value) {
                    const object = Object.assign({}, payload[id]);
                    object[name] = value;

                    if (name === 'bonus' && !isNaN(Number(value)) && Number(value) >= 0 && Number(value) <= 1 && tableSchema.settings) {
                        const amount = (value === '' || Number(value) === 0) ? '$0.00' : await reportService.getExtraBonusAmount({rate: value, thinker_pod_id: tableSchema.settings.thinker_pod_id ?? null, period: tableSchema.settings.period ?? null});
                        object['amount'] = amount;
                    }

                    setPayload(prev => ({...prev, [id]: object}));
                }
            }
        }, 500);
    }, [payload, hasError]);

    const customTableSchema = useMemo(() => {
        return Object.assign({}, tableSchema, {
            rows: tableSchema.rows.map(row => {
                return Object.assign({}, row, {
                    cells: row.cells.map(cell => {
                        if (cell.type === 'raw') {
                            const component = cell.value as TextComponentSchema;
                            const showError = (component.name !== undefined && component.label !== undefined) ? hasError[component.name + component.label] : false;

                            return {
                                type: 'custom-jsx',
                                element: (
                                    <TextComponent
                                        triggerOnLoad={component.value ? true : false}
                                        columnStyle={component.style ?? ''}
                                        error={showError}
                                        name={component.name}
                                        defaultValue={component.value ?? ''}
                                        onChange={onChangeHanlder(component.label, component.name, timer)}
                                    />
                                ),
                                className: cell.classes?.join(' '),
                                colspan: cell.colspan
                            };
                        } else if (cell.type === 'text' && cell.target && payload && payload[cell.target]) {
                            cell.value = payload[cell.target].amount ?? cell.value;
                        }

                        return cell;
                    })
                });
            })
        });
    }, [tableSchema, payload, hasError, onChangeHanlder]);

    const updatedFormSchema = useMemo(() => {
        return Object.assign({}, schema, {
            components: schema.components.map(component => {
                if (component.type === 'buttons') {
                    return Object.assign({}, component, {
                        buttons: component.buttons.map(button => {
                            if (button.class?.includes('form-trigger')) {
                                button.disabled = submitDisabled;
                            }

                            return button;
                        })
                    });
                }
                return component;
            })
        });
    }, [schema, submitDisabled]);

    return (
        <>
            <TableHelper schema={customTableSchema} includeHeader={true} />
            <FormHelper schema={updatedFormSchema} onSubmit={onSubmit} parentRef={parentRef} onCustomPayload={onCustomPayload} />
        </>
    );
};

export default ThinkerBonusPeriods;
