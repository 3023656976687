import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionButton, ActionButtonsCell, ActionOption, ActionsCell, BasicModal, BasicModalProps, BasicRow, BasicRowProps, CellHeader, Form, LinkCell, ModalActionButton, pushNotification, RateComponent, RawCell, SearchBox, SortCell, Table, TagCell, TextCell, utils, Widget } from '@truenorthmortgage/olympus';
import React, { Dispatch, FC, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ComponentSchema, FormSchema } from '../../models/schemas/form-schema';
import { CellExpandable, RowStatus, SchemaCell, SchemaRow, TableSchema } from '../../models/schemas/table-schema';
import { SERVICES } from '../../services';
import { ComponentService } from '../../services/component.service';
import { HttpService } from '../../services/http.service';
import ComponentHelper from '../component-helper/component-helper.component';
import ContainerModal from '../container-modal/container-modal.component';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import ActionButtonsHelper from '../action-buttons-helper/action-buttons-helper.component';

export interface TableHelperProps {
    schema: TableSchema;
    headerless?: boolean;
    includeHeader?: boolean;
    className?: string;
    headerIndex?: number;
    showTitleBar?: boolean;
    onModalClose?: (data?: any) => void;
    statusChangeCallback?: (data?: any) => void;
    onSearch?: (s: string) => void;
    filterModal?: {
        props: BasicModalProps;
        element: JSX.Element;
        onEnterPressed: (s: string) => void;
    };
    children?: ReactNode;
}

function renderTableSchema(
    rows: SchemaRow[],
    headerless: boolean,
    includeHeader: boolean,
    headerIndex: number,
    componentService: ComponentService,
    dispatch: Dispatch<any>,
    onModalClose: ((data?: any) => void) | undefined,
    rowStatuses: RowStatus[],
    setRowStatuses: (statuses: RowStatus[]) => void): [ReactElement<BasicRowProps>[], string | undefined] {

    const renderedSchema = rows.filter(row => row.type !== 'empty').flatMap((row, rowIndex) => {
        const rows = [];

        rows.push(!headerless && includeHeader && rowIndex === headerIndex ? (
            <CellHeader className={`${row.type} ${row.class}`} key={rowIndex}>
                {
                    row.cells.map((cell, index) => renderCell(cell, index, rowIndex, componentService, dispatch, onModalClose, rowStatuses, setRowStatuses))
                }
            </CellHeader>
        ) : (
            <BasicRow rowClasses={row.type ? [row.type, row.class] : [row.class]} key={rowIndex} hideRow={row.hideRow}>
                {
                    row.cells.map((cell, index) => renderCell(cell, index, rowIndex, componentService, dispatch, onModalClose, rowStatuses, setRowStatuses))
                }
            </BasicRow>
        ));

        if (rowStatuses[rowIndex].hasDetails && rowStatuses[rowIndex].isOpen) {
            rows.push(
                <BasicRow rowClasses={[row.row_details!.class]} key={rowIndex * 2}>
                    <RawCell colSpan={row.cells.length} html={row.row_details?.value}></RawCell>
                </BasicRow>
            );
        }

        return rows;
    });

    const emptyRow = rows.find(row => row.type === 'empty');

    return [renderedSchema, emptyRow?.content];
}

function renderCell(
    cell: SchemaCell,
    index: number,
    rowIndex: number,
    componentService: ComponentService,
    dispatch: Dispatch<any>,
    onModalClose: ((data?: any) => void) | undefined,
    rowStatuses: RowStatus[],
    setRowStatuses: (statuses: RowStatus[]) => void) {
    if (cell.type === 'raw') {
        if (cell.value.constructor === Array) {
            return (
                <RawCell key={index} className="faux-cell raw" sortData={cell.sort_data}>
                    <>
                        {cell.value.map((component: ComponentSchema, index: number) => {
                            if (component.type === 'react') {
                                return <React.Fragment key={index}>
                                    {component.element}
                                </React.Fragment>;
                            }
                            return <React.Fragment key={index}>
                                <ComponentHelper schema={component} />
                            </React.Fragment>;
                        })}
                    </>
                </RawCell>
            );
        } else if (typeof cell.value === 'object' && 'components' in cell.value) {
            return (
                <RawCell key={index} className="faux-cell raw" colSpan={cell.colspan ?? undefined} sortData={cell.sort_data}>
                    <Form>
                        {cell.value.components.map((component, index) => (
                            <React.Fragment key={index}>
                                <ComponentHelper schema={component} />
                            </React.Fragment>
                        ))}
                    </Form>
                </RawCell>
            );
        } else if (typeof cell.value === 'object' && 'type' in cell.value) {
            return (
                <RawCell key={index} colSpan={cell.colspan ?? undefined} className={cell.classes?.join(' ')} sortData={cell.sort_data}>
                    <ComponentHelper schema={cell.value} />
                </RawCell>
            );
        } else if (cell.rawHtml === true) {
            return (
                <RawCell key={index} sortData={cell.sort_data}>
                    <div dangerouslySetInnerHTML={{__html:cell.value.toString()}} />;
                </RawCell>
            );
        } else {
            return (
                <RawCell key={index} colSpan={cell.colspan ?? undefined} html={cell.value.toString()} className={cell.classes?.join(' ')} sortData={cell.sort_data}>
                    {cell.icon ? <FontAwesomeIcon icon={cell.icon as IconName} /> : null }
                    <span dangerouslySetInnerHTML={{__html:cell.value.toString()}} />
                </RawCell>
            );
        }
    } else if (cell.type === 'custom-jsx') {
        return (
            <RawCell key={index} className={cell.className} colSpan={cell.colspan ?? undefined} sortData={cell.sort_data}>
                {cell.element}
            </RawCell>
        );
    } else if (cell.type === 'component') {
        return (
            <RawCell key={index} className={`faux-cell component ${cell.classes?.join(' ')}`} colSpan={cell.colspan ?? undefined} sortData={cell.sort_data}>
                <ComponentHelper schema={cell.component} />
            </RawCell>
        );
    } else if (cell.type === 'text') {
        return (<TextCell key={index} className={cell.classes.join(' ')} colSpan={cell.colspan ?? undefined} sortData={cell.sort_data}>{cell.value}</TextCell>);
    } else if (cell.type === 'sort') {
        return (<SortCell key={index} value={cell.value} />);
    } else if (cell.type === 'tag-cell') {
        if (Array.isArray(cell.value)) {
            return (
                <TagCell key={index} tagClasses={cell.classes} sortData={cell.sort_data}>
                    {cell.value.map((tag, tagIndex) => <div key={tagIndex} className={cell.value.length > 1 ? cell.classes?.join(' ') + ' tag' : ''}>{tag.value}</div>)}
                </TagCell>
            );
        }
        return (<TagCell key={index} tagClasses={cell.classes} sortData={cell.sort_data}>{cell.value}</TagCell>);
    } else if (cell.type === 'link') {
        return <LinkCell key={index} href={cell.value_href} subValue={cell.value}></LinkCell>;
    } else if (cell.type === 'actions') {
        return (
            <ActionsCell key={index}>
                {
                    cell.options.map(({ label, uri, modal, disabled, uriCallback, clickCallback }, index) => {
                        const callback = clickCallback ? clickCallback : (uriCallback ? () => uriCallback(uri) : undefined);
                        return (<ActionOption key={index} url={uri} modal={modal} disabled={disabled} clickCallback={callback}>{label}</ActionOption>);
                    })
                }
            </ActionsCell>
        );
    } else if (cell.type === 'action_buttons') {
        return (
            <ActionButtonsCell key={index}>
                {
                    cell.value.map((button, index) => {
                        if (button.modal) {
                            return (<ModalActionButton
                                key={index}
                                text={button.label}
                                icon={button.icon}
                                disabled={button.disabled}
                                modalArgs={{
                                    modalHeader: button.modalHeader,
                                    loadProps: button.modalLoadProps,
                                    onClose: button.modalOnClose
                                }}
                                className={button.classes?.join(' ')}>
                                {button.modalElement}
                            </ModalActionButton>);
                        } else if (button.icon === 'download') {
                            return (<ActionButton
                                key={index}
                                icon={button.icon as IconName}
                                callback={button.callback}
                                className={button.classes?.join(' ')}>
                                {button.label}
                            </ActionButton>);
                        } else {
                            let callback = button.callback;
                            if (!callback && button.classes?.includes('link-trigger')) {
                                callback = () => {
                                    componentService.executeLinkTriggerClick(button.data_array && 'uri' in button.data_array ? button.data_array.uri : '', dispatch, onModalClose);
                                };
                            }
                            return (<ActionButton
                                key={index}
                                icon={button.icon as IconName}
                                redirectTo={button.uri}
                                disabled={button.disabled}
                                callback={callback}
                                className={button.classes?.join(' ')}>
                                {button.label}
                            </ActionButton>);
                        }
                    })
                }

            </ActionButtonsCell>
        );
    } else if (cell.type === 'expandable') {
        cell.createElements = () => createExpandableElement(cell, rowStatuses, rowIndex, setRowStatuses);

        return (
            <RawCell key={index} className="faux-cell raw" sortData={cell.sort_data}>
                {cell.createElements(rowStatuses, rowIndex, setRowStatuses)}
            </RawCell>
        );
    }
}

function addTableModals(rows: SchemaRow[], httpService: HttpService, componentService: ComponentService, dispatch: Dispatch<any>, onModalClose?: (data?: any) => void, statusChangeCallback?: () => void) {
    rows.forEach(row => {
        row.cells.forEach(cell => {
            if (cell.type === 'raw' && typeof cell.value === 'object' && 'type' in cell.value && cell.value.type === 'select') {
                cell.value.callback = ({ deal_id }: { deal_id: string }, value) => {
                    httpService.fetchJson<any>(`/thinker/update-deal-thinker-status?deal_id=${deal_id}&status=${value}`).then(statusChangeCallback);
                };
            } else if (cell.type === 'actions') {
                cell.options.forEach(option => {
                    if (option.type === 1) {
                        option.modal = {
                            element: (<ContainerModal />),
                            loadProps: async () => ({
                                data: await httpService.fetchJson<FormSchema | any>(option.uri).then((data) => {
                                    if ('status' in data && data.status !== 200) {
                                        dispatch(pushNotification({class: 'error', message: data.message}));
                                    }
                                    return data;
                                }),
                                successMessage: 'Deal modified successfully'
                            }),
                            header: option.label,
                            onClose: onModalClose,
                        };
                    } else if (option.type === 2 && !('uriCallback' in option)) {
                        option.clickCallback = () => {
                            httpService.fetchJson<any>(option.uri).then(statusChangeCallback);
                        };
                    } else if (option.type === 3) {
                        option.clickCallback = async () => {
                            const response = await httpService.fetchJson<any>(option.uri);
                            componentService.createDownload(response.__blob_data, option.data_array.filename);
                        };
                    }
                });
            } else if (cell.type === 'action_buttons') {
                cell.value.forEach(button => {
                    const url =
                        button.data_array && 'length' in button.data_array && button.data_array.length === 1
                            ? button.data_array[0]
                            : (button.data_array && 'uri' in button.data_array ? button.data_array.uri : button.uri ?? '');
                    if (button.modal) {
                        button.modalElement = (<ContainerModal />);
                        button.modalLoadProps = async () => ({ data: await httpService.fetchJson<FormSchema>(url) });
                        button.modalHeader = button.label;
                        button.modalOnClose = onModalClose;
                    } else {
                        if (button.icon === 'download') {
                            button.callback = async () => {
                                const response = await httpService.fetchJson<any>(button.uri as string);
                                componentService.createDownload(response.__blob_data, button.data_array && 'filename' in button.data_array ? button.data_array?.filename : '');
                            };
                        }
                    }
                });
            }
        });
    });
}

function createExpandableElement(cell: CellExpandable, rowStatuses: RowStatus[], rowIndex: number, setRowStatuses: (statuses: RowStatus[]) => void) {
    const showDetail = () => {
        const newStatuses = Array.from(rowStatuses);
        newStatuses[rowIndex].isOpen = !rowStatuses[rowIndex].isOpen;
        setRowStatuses(newStatuses);
    };
    return (
        <>
            <i className={`fa interactive ${rowStatuses[rowIndex].isOpen ? 'red' : 'green'}`} onClick={showDetail}>
                {
                    rowStatuses[rowIndex].isOpen
                        ? <FontAwesomeIcon icon={faMinusSquare} />
                        : <FontAwesomeIcon icon={faPlusSquare} />
                }
            </i>
            {' '}
            <span dangerouslySetInnerHTML={utils.html.doSanitize(cell.value.toString())} />
        </>
    );
}

const TableHelper: FC<TableHelperProps> = ({
    children, schema, headerless = false, includeHeader = false, className = '', headerIndex = 0, filterModal, statusChangeCallback, onSearch, onModalClose, showTitleBar,
}) => {
    const [rowStatuses, setRowStatuses] = useState<RowStatus[]>([]);
    const httpService = utils.injection.useInjection<HttpService>(SERVICES.HttpService);
    const componentService = utils.injection.useInjection<ComponentService>(SERVICES.ComponentService);
    const dispatch = useDispatch();

    const filteredRows = useMemo(() => {
        const rows = Array.from(schema.rows);
        if (!headerless && !includeHeader) {
            rows.shift();
        }

        addTableModals(rows, httpService, componentService, dispatch, onModalClose, statusChangeCallback);

        return rows;
    }, [schema.rows, headerless, includeHeader, httpService, statusChangeCallback, onModalClose]);

    useEffect(() => {
        setRowStatuses(filteredRows
            .filter(row => row.type !== 'empty')
            .map(row => ({ isOpen: false, hasDetails: !!row.row_details?.value }))
        );
    }, [schema, setRowStatuses]);

    const [rows, emptyText] = useMemo(() => {
        if (rowStatuses.length === filteredRows.filter(row => row.type !== 'empty').length) {
            return renderTableSchema(filteredRows, headerless, includeHeader, headerIndex, componentService, dispatch, onModalClose, rowStatuses, setRowStatuses);
        } else {
            return [[], undefined];
        }
    }, [schema, headerIndex, headerless, includeHeader, componentService, dispatch, onModalClose, rowStatuses]);

    return (
        <Widget className={`table ${className}`}>
            <div className="row">
                {schema.action_buttons ?
                    <ActionButtonsHelper schema={schema.action_buttons} onModalClose={onModalClose} />
                    : null}
            </div>

            <Table title={schema.title} isEmpty={rows.length <= (!headerless && includeHeader ? 1 : 0)} emptyText={emptyText} showTitleBar={showTitleBar}>
                {(filterModal) ?
                    <SearchBox onEnterPress={filterModal.onEnterPressed} fieldName="deal">
                        <BasicModal loadProps={filterModal.props.loadProps} modalHeader={filterModal.props.modalHeader}>
                            {filterModal.element}
                        </BasicModal>
                    </SearchBox>
                    : ((onSearch)
                        ? <SearchBox onEnterPress={(query) => onSearch(query)} fieldName='onsearch_box' />
                        : null
                    )
                }
                {children}
                {rows}
            </Table>
        </Widget>
    );
};

export default TableHelper;
