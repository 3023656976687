import {
    TextComponent,
    MoneyComponent,
    RadioComponent,
    RadioOption,
    Switch,
} from '@truenorthmortgage/olympus';
import { Card } from './card.component';
import { Frequency } from './frequency-component';
import * as _ from 'lodash';

export interface IncomeInfo {
    guaranteed_income: boolean;
    income_type: string | null;
    hourly_rate: number | null;
    hourly_hours: number | null;
    salary_pay: number | null;
    salary_frequency: number | null;
    rotation_on: number | null;
    rotation_off: number | null;
    rotation_hours: number | null;
    rotation_rate: number | null;
    income_other: string | null;
    [key: string]: IncomeInfo[keyof IncomeInfo];
}

interface VerbalIncomeCardProps {
    configData: [IncomeInfo, string];
    helpers: CallableFunction[];
}

const VerbalIncomeCard = ({
    configData: [income_info, guaranteed_output],
    helpers: [createUpdatedInfo],
}: VerbalIncomeCardProps) => {
    const {
        guaranteed_income: guaranteedIncome,
        income_type: incomeType,
        hourly_rate: hourlyRate,
        hourly_hours: hourlyHours,
        salary_pay: salaryPay,
        salary_frequency: salaryFrequency,
        rotation_on: rotationOn,
        rotation_off: rotationOff,
        rotation_hours: rotationHours,
        rotation_rate: rotationRate,
        income_other: incomeOther,
    } = income_info;

    return (
        <Card className='verbal-card' id='verbal-template-employer-info'>
            <h3>Guaranteed Income:</h3>
            <div className='vulcan-parent-div'>
                <div className='inline-horizontal'>
                    <label>Guaranteed Income:</label>
                    <div className='inline-horizontal'>
                        <label>No</label>
                        <Switch
                            id='verbal-guaranteed-income'
                            defaultChecked={guaranteedIncome}
                            onChange={() =>
                                createUpdatedInfo(
                                    !guaranteedIncome,
                                    'income_info',
                                    'guaranteed_income',
                                )
                            }
                        ></Switch>
                        <label>Yes</label>
                    </div>
                </div>
            </div>
            {guaranteedIncome ? (
                <div id='verbal-has-guaranteed-income' className='vulcan-parent-div'>
                    <div>
                        {/* using seperate label for spacing consistency */}
                        <label>Type of Income:</label>
                        <RadioComponent
                            name='verbal-income-type'
                            value={incomeType ?? ''}
                            onChange={(val) =>
                                createUpdatedInfo(val, 'income_info', 'income_type')
                            }
                            columnStyle='quarter'
                        >
                            <RadioOption label='Hourly' value='hourly' />
                            <RadioOption label='Salary' value='salary' />
                            <RadioOption label='Rotation' value='rotation' />
                            <RadioOption label='Other' value='other' />
                        </RadioComponent>
                    </div>
                    {incomeType === 'hourly' ? (
                        <div id='verbal-income-hourly' className='vulcan-parent-div'>
                            <div>
                                <label>Hourly Rate: </label>
                                <MoneyComponent
                                    cents={hourlyRate ?? undefined}
                                    onChangeCents={_.debounce((val) => createUpdatedInfo(val, 'income_info', 'hourly_rate'), 250)}
                                    name='verbal-guaranteed-income-hourly-rate'
                                />
                            </div>
                            <div>
                                <label>Hours Per Week: </label>
                                <TextComponent
                                    formData={hourlyHours?.toString()}
                                    onBlur={(val) =>
                                        createUpdatedInfo(
                                            val,
                                            'income_info',
                                            'hourly_hours',
                                            true,
                                        )
                                    }
                                    name='verbal-guaranteed-income-hourly-hours'
                                />
                            </div>
                        </div>
                    ) : null}

                    {incomeType === 'salary' ? (
                        <div id='verbal-income-salary' className='vulcan-parent-div'>
                            <Frequency
                                frequency={salaryFrequency ?? undefined}
                                onBlur={(val: any) =>
                                    createUpdatedInfo(
                                        val,
                                        'income_info',
                                        'salary_frequency',
                                        true,
                                    )
                                }
                            />
                            <div>
                                <label>Pay: </label>
                                <MoneyComponent
                                    defaultCents={salaryPay ?? undefined}
                                    onChangeCents={_.debounce((val) => createUpdatedInfo(val, 'income_info', 'salary_pay'), 250)}
                                    name='verbal-guaranteed-income-salary-pay'
                                />
                            </div>
                        </div>
                    ) : null}
                    {incomeType === 'rotation' ? (
                        <div id='verbal-income-rotation' className='vulcan-parent-div'>
                            <div>
                                <label>Days On: </label>
                                <TextComponent
                                    formData={rotationOn?.toString()}
                                    onBlur={(val) =>
                                        createUpdatedInfo(
                                            val,
                                            'income_info',
                                            'rotation_on',
                                            true,
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <label>Days Off: </label>
                                <TextComponent
                                    formData={rotationOff?.toString()}
                                    onBlur={(val) =>
                                        createUpdatedInfo(
                                            val,
                                            'income_info',
                                            'rotation_off',
                                            true,
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <label>Hours Per Day: </label>
                                <TextComponent
                                    formData={rotationHours?.toString()}
                                    onBlur={(val) =>
                                        createUpdatedInfo(
                                            val,
                                            'income_info',
                                            'rotation_hours',
                                            true,
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <label>Hourly Rate: </label>
                                <MoneyComponent
                                    cents={rotationRate ?? undefined}
                                    onChangeCents={_.debounce((val) => createUpdatedInfo( val, 'income_info', 'rotation_rate',), 250)}
                                    name='verbal-guaranteed-income-rotation-rate'
                                />
                            </div>
                        </div>
                    ) : null}
                    {incomeType == 'other' ? (
                        <div id='verbal-income-other' className='vulcan-parent-div'>
                            <div>
                                <label>Manual Input: </label>
                                <TextComponent
                                    formData={incomeOther ?? undefined}
                                    onBlur={(val) => createUpdatedInfo(val, 'income_info', 'income_other')}
                                    name='verbal-guaranteed-income-other-input'
                                />
                            </div>
                        </div>
                    ) : null}

                    <div id='verbal-income-output'>
                        <label>Guaranteed Output: </label>
                        <TextComponent formData={guaranteed_output} disabled />
                    </div>
                </div>
            ) : (
                <></>
            )}
        </Card>
    );
};
export default VerbalIncomeCard;
