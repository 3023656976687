import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { SERVICES } from '../../services';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TableHelper from '../../components/table-helper/table-helper.component';
import ComponentHelper from '../../components/component-helper/component-helper.component';
import { Filters, ReportService } from '../../services/report.service';
import { TableSchema } from '../../models/schemas/table-schema';
import { DateComponent, MultiSelectComponent, SelectComponent } from '../../models/schemas/form-schema';

const ThinkerPodReport = () => {
    const reportService = utils.injection.useInjection<ReportService>(SERVICES.ReportService);
    const [data, setData] = useState<[TableSchema, SelectComponent[] | DateComponent[]] | null>(null);
    const [filters, setFilters] = useState<Filters>({});
    
    const dispatch = useDispatch();

    /**
     * this syncs the data object with changes that we make on the frontend,
     * so we don't enter an infinite loop because of data mismatch
     */
    const onChangeHandler = useCallback((componentSchema: SelectComponent | DateComponent | MultiSelectComponent) => (value: string | null | []) => {
        if (componentSchema.name && value !== null && value !== undefined) {
            if (!(componentSchema.name in filters) || (filters[componentSchema.name] != value && JSON.stringify(filters[componentSchema.name]) != JSON.stringify(value) )) {
                setFilters(prev => ({...prev, [componentSchema.name as string]: value}));
                componentSchema.value = value;
            }
        }
    }, [filters]);

    const loadData = useCallback(() => {
        const subscription = reportService.getThinkerPodReport(filters).subscribe({
            next: setData,
            error: (e) => {
                dispatch(
                    pushNotification({class: 'error', message: e.toString()})
                );
            },
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [filters, reportService, dispatch, setData]);

    useEffect(loadData, [loadData]);

    return data ? (
        <>
            <div className={'widget'}>
                {
                    data[1].map((component: SelectComponent | DateComponent | MultiSelectComponent, key) =>
                        <ComponentHelper schema={component} key ={key} onChange={onChangeHandler(component)} triggerOnLoad={true} />
                    )
                }
            </div>
            <TableHelper schema={data[0]} includeHeader={true} headerless={false}/>
        </>)
        : null;
};

export default ThinkerPodReport;
