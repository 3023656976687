import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { zip } from 'rxjs';
import DealsQueue from '../../components/deals-queue/deals-queue.component';
import TableHelper from '../../components/table-helper/table-helper.component';
import { DealsQueueData } from '../../models/deals/deals-queue';
import { TableSchema } from '../../models/schemas/table-schema';
import { SERVICES } from '../../services';
import { DealService } from '../../services/deal.service';
import { UnderwritingService } from '../../services/underwriting.service';
import ComponentHelper from '../../components/component-helper/component-helper.component';
import { ComponentSchema } from '../../models/schemas/form-schema';
import { CloserDealsService } from '../../services/closer-deals.service';

export interface ManagerDealProps {
    isConcierge?: boolean
}

const ManagerDeals: FC<ManagerDealProps> = ({ isConcierge = false }: ManagerDealProps) => {
    const [dealsQueue, setDealsQueue] = useState<DealsQueueData | null>(null);
    const dealsService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const closerDealsService = utils.injection.useInjection<CloserDealsService>(SERVICES.CloserDealsService);
    const [dealsRequiringManagerReview, setDealsRequiringManagerReview] = useState<TableSchema | null>(null);
    const [rateChangeRequests, setRateChangeRequests] = useState<TableSchema | null>(null);
    const [selectedUnderwriterDeals, setSelectedUnderwriterDeals] = useState<TableSchema | null>(null);
    const [thinkerDropdown, setThinkerDropdown] = useState<ComponentSchema | null>(null);
    const [selectedUnderwriter, setSelectedUnderwriter] = useState<string | null>('');
    const [selectedCloserDeals, setSelectedCloserDeals] = useState<TableSchema | null>(null);
    const underwritingService = utils.injection.useInjection<UnderwritingService>(SERVICES.UnderwritingService);
    const dispatch = useDispatch();

    const loadData = useCallback(() => {
        const subscription = zip(
            dealsService.getDealsQueue(),
            underwritingService.getDealsRequiringManagerReview(isConcierge),
            underwritingService.getRateChangeRequests(isConcierge),
            underwritingService.getThinkersDropdown(isConcierge),
            underwritingService.getUnderwritingDeals(selectedUnderwriter),
            closerDealsService.getCloserDeals(selectedUnderwriter),
        ).subscribe({
            next: ([dealsQueue, dealsRequiringManagerReview, rateChangeRequests, thinkerDropdown, selectedUnderwriterDeals, selectedCloserDeals]) => {
                setDealsQueue(dealsQueue);
                setDealsRequiringManagerReview(dealsRequiringManagerReview);
                setRateChangeRequests(rateChangeRequests);
                setThinkerDropdown(thinkerDropdown);
                setSelectedUnderwriterDeals(selectedUnderwriterDeals);
                setSelectedCloserDeals(selectedCloserDeals);
            },
            error: e => {
                dispatch(pushNotification({ class: 'error', message: e.toString() }));
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [setDealsQueue, setDealsRequiringManagerReview, setRateChangeRequests, setThinkerDropdown, selectedUnderwriter, isConcierge]);

    const reloadData = useCallback((data?: { submit: boolean }) => {
        if (data?.submit) {
            loadData();
        }
    }, [loadData]);

    useEffect(loadData, [loadData]);

    return dealsQueue && dealsRequiringManagerReview && rateChangeRequests && thinkerDropdown && selectedUnderwriterDeals && selectedCloserDeals ? (
        <>
            <DealsQueue status={dealsQueue} />
            <TableHelper schema={dealsRequiringManagerReview} includeHeader={true} onModalClose={reloadData} />
            <TableHelper schema={rateChangeRequests} includeHeader={true} onModalClose={reloadData} />
            <div className='widget no-margin'>
                <ComponentHelper schema={thinkerDropdown} onChange={setSelectedUnderwriter} />
            </div>
            <TableHelper schema={selectedUnderwriterDeals} includeHeader={true} onModalClose={reloadData} />
            <TableHelper schema={selectedCloserDeals} includeHeader={true} onModalClose={reloadData} />
        </>
    ) : null;
};

export default ManagerDeals;
