import { Observable } from 'rxjs';
import { FormSchema } from '../models/schemas/form-schema';
import { HttpService } from './http.service';

export class SubmitToThinkService extends HttpService {
    getStart(): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>('/submit-to-think/start');
    }

    getCancel(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/cancel?deal_key=${dealKey}`);
    }

    postStart(): Promise<{uri: string}> {
        return this.fetchJson<{uri: string}>('/submit-to-think/start', 'POST');
    }

    getApplicantInformation(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/applicant-information?deal_key=${dealKey}`);
    }
    
    postApplicantInformation(dealKey: string, payload: Record<string, string>): Promise<any> {
        return this.fetchJson<any>(`/submit-to-think/applicant-information?deal_key=${dealKey}`, 'POST', payload);
    }

    getMortgagePurpose(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/mortgage-purpose?deal_key=${dealKey}`);
    }
    
    postMortgagePurpose(dealKey: string, payload: Record<string, string>): Promise<any> {
        return this.fetchJson<any>(`/submit-to-think/mortgage-purpose?deal_key=${dealKey}`, 'POST', payload);
    }

    getSwitch(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/switch?deal_key=${dealKey}`);
    }

    getUninsurable(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/uninsured-uninsurable?deal_key=${dealKey}`);
    }

    getLTV(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/ltv?deal_key=${dealKey}`);
    }
    
    postLTV(dealKey: string, payload: Record<string, string>): Promise<any> {
        return this.fetchJson<any>(`/submit-to-think/ltv?deal_key=${dealKey}`, 'POST', payload);
    }

    getMortgageDefaultInsurance(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/mortgage-default-insurance?deal_key=${dealKey}`);
    }
    
    postMortgageDefaultInsurance(dealKey: string, payload: Record<string, string>): Promise<any> {
        return this.fetchJson<any>(`/submit-to-think/mortgage-default-insurance?deal_key=${dealKey}`, 'POST', payload);
    }

    getIncludeHeloc(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/include-heloc?deal_key=${dealKey}`);
    }
    
    postIncludeHeloc(dealKey: string, payload: Record<string, string>): Promise<any> {
        return this.fetchJson<any>(`/submit-to-think/include-heloc?deal_key=${dealKey}`, 'POST', payload);
    }

    getPreferredFunder(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/preferred-funder?deal_key=${dealKey}`);
    }
    
    postPreferredFunder(dealKey: string, payload: Record<string, string>): Promise<any> {
        return this.fetchJson<any>(`/submit-to-think/preferred-funder?deal_key=${dealKey}`, 'POST', payload);
    }

    getFixedOrVariable(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/fixed-or-variable?deal_key=${dealKey}`);
    }
    
    postFixedOrVariable(dealKey: string, payload: Record<string, string>): Promise<any> {
        return this.fetchJson<any>(`/submit-to-think/fixed-or-variable?deal_key=${dealKey}`, 'POST', payload);
    }

    getFixedTerm(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/fixed-term?deal_key=${dealKey}`);
    }
    
    postFixedTerm(dealKey: string, payload: Record<string, string>): Promise<any> {
        return this.fetchJson<any>(`/submit-to-think/fixed-term?deal_key=${dealKey}`, 'POST', payload);
    }

    getFixedRate(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/fixed-rate?deal_key=${dealKey}`);
    }
    
    postFixedRate(dealKey: string, payload: Record<string, string>): Promise<any> {
        return this.fetchJson<any>(`/submit-to-think/fixed-rate?deal_key=${dealKey}`, 'POST', payload);
    }

    getVariableRate(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/variable-rate?deal_key=${dealKey}`);
    }
    
    postVariableRate(dealKey: string, payload: Record<string, string>): Promise<any> {
        return this.fetchJson<any>(`/submit-to-think/variable-rate?deal_key=${dealKey}`, 'POST', payload);
    }

    getDealCosts(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/deal-costs?deal_key=${dealKey}`);
    }
    
    postDealCosts(dealKey: string, payload: Record<string, string>): Promise<any> {
        return this.fetchJson<any>(`/submit-to-think/deal-costs?deal_key=${dealKey}`, 'POST', payload);
    }

    getHedgeNowStep(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/hedge-now?deal_key=${dealKey}`);
    }
    
    postHedgeNowStep(dealKey: string, payload: Record<string, string>): Promise<any> {
        return this.fetchJson<any>(`/submit-to-think/hedge-now?deal_key=${dealKey}`, 'POST', payload);
    }

    getSubmitStep(dealKey: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>(`/submit-to-think/submit?deal_key=${dealKey}`);
    }
    
    postSubmitStep(dealKey: string, payload: Record<string, string>): Promise<any> {
        return this.fetchJson<any>(`/submit-to-think/submit?deal_key=${dealKey}`, 'POST', payload);
    }
}
