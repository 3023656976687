import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import ComponentHelper from '../../components/component-helper/component-helper.component';
import TableHelper from '../../components/table-helper/table-helper.component';
import { ComponentSchema } from '../../models/schemas/form-schema';
import { TableSchema } from '../../models/schemas/table-schema';
import { SERVICES } from '../../services';
import { AdminService } from '../../services/admin.service';
import { HttpService } from '../../services/http.service';
import { zip, tap } from 'rxjs';

export interface AdminUsersProps {}

const AdminUsers: FC<AdminUsersProps> = () => {
    const adminService = utils.injection.useInjection<AdminService>(SERVICES.AdminService);
    const [users, setUsers] = useState<TableSchema | null>(null);
    const [actions, setActions] = useState<ComponentSchema | null>(null);
    const [filter, setFilter] = useState<boolean>(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loadData = useCallback(() => {
        const  [actionsComponent, usersTable]= adminService.getUsers(dispatch, navigate, filter);

        return zip(
            usersTable,
            actionsComponent
        ).pipe(
            tap({
                next: ([users, actions]) => {
                    setUsers(users);
                    setActions(actions);
                }, 
                error: e => {
                    dispatch(pushNotification({ class: 'error', message: e.toString() }));
                }
            })
        );
    }, [adminService, dispatch, navigate, filter]);

    useEffect(() => {
        const subscription = HttpService.subscribe(loadData(), dispatch);

        return () => {
            subscription.unsubscribe();
        };
    }, [loadData, dispatch]);
    
    const modalClosed = (data?: { submit: boolean }) => {
        if (data?.submit) {
            loadData();
        }
    };

    const onChangeHandler = useCallback((value: string | null) => {
        const localValue = (value && value === 'true') ? true : false;
        setFilter(localValue);
    }, [filter]);

    return users && actions ? (
        <>

            <div className='single-filter'>
                <ComponentHelper schema={actions} onChange={onChangeHandler} />
            </div>

            <TableHelper schema={users} includeHeader={true} onModalClose={modalClosed} />
        </>
    ) : null;
};

export default AdminUsers;
