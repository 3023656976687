import { ModalActionButton, pushNotification, utils } from '@truenorthmortgage/olympus';
import { SERVICES } from '../../services';
import { useCallback, useEffect, useState } from 'react';
import { ActionHeaderSchema } from '../../models/schemas/action-header';
import { TableSchema } from '../../models/schemas/table-schema';
import ActionHeaderHelper from '../../components/action-header-helper/action-header-helper.component';
import TableHelper from '../../components/table-helper/table-helper.component';
import { useDispatch } from 'react-redux';
import { UnderwritingService } from '../../services/underwriting.service';
import { FormSchema } from '../../models/schemas/form-schema';
import ContainerModal from '../../components/container-modal/container-modal.component';
import { HttpService } from '../../services/http.service';
import { ActionButton } from '../../models/accordions/action-button';

const ThinkerConditions = () => {
    const underwriterService = utils.injection.useInjection<UnderwritingService>(SERVICES.UnderwritingService);
    const httpService = utils.injection.useInjection<HttpService>(SERVICES.HttpService);

    const [data, setData] = useState<[ActionHeaderSchema, TableSchema, ActionButton] | null>(null);
    const dispatch = useDispatch();

    const loadData = useCallback(() => {
        const subscription = underwriterService.getUnderwriterConditions().subscribe({
            next: setData,
            error: (e) => {
                dispatch(
                    pushNotification({class: 'error', message: e.toString()})
                );
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [underwriterService, dispatch]);

    useEffect(loadData, [setData]);

    const modalClosed = (data?: { submit: boolean }) => {
        if (data?.submit) {
            loadData();
        }
    };

    return data ?
        <>
            <ActionHeaderHelper schema={data[0]}/>
            <div className={'widget'}>
                <ModalActionButton
                    text={data[2].label}
                    className={data[2].classes}
                    disabled={data[2].disabled}
                    modalArgs={{
                        loadProps: async () => ({
                            data: await httpService.fetchJson<FormSchema>(data[2].uri)
                        }),
                        onClose: modalClosed
                    }}>
                    <ContainerModal/>
                </ModalActionButton>
            </div>
            <TableHelper schema={data[1]} includeHeader={true} onModalClose={modalClosed}/>
        </> : null;
};

export default ThinkerConditions;
