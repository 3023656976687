import { BasicModalHandle, Column } from '@truenorthmortgage/olympus';
import { FC, RefObject, useCallback, useState } from 'react';
import FormHelper from '../components/form-helper/form-helper.component';
import { FormSchema } from '../models/schemas/form-schema';
import { useMemo } from 'react';

export interface PropertyEditProps {
    schema: FormSchema,
    onSubmit: (data: any) => void,
    parentRef?: RefObject<BasicModalHandle>
}

const PropertyEdit: FC<PropertyEditProps> = ({ schema, onSubmit, parentRef }) => {
    const [formSchema, setFormSchema] = useState<FormSchema>(schema);
    const [deleted, setDeleted] = useState<boolean>(false);

    const removeImageHandler = useCallback(() => {
        setFormSchema(prev => ({...prev, components: customSchema.components.filter(x => x.type !== 'image' && x.type !== 'react')}));
        setDeleted(true);
    }, [formSchema]);

    const onCustomPayload = useCallback(async () => {
        return {deleted: deleted};
    }, [deleted]);

    const customSchema = useMemo(() => {
        return Object.assign({}, formSchema, {
            components: formSchema.components.map(component => {
                if (component.type === 'buttons' && 'name' in component && component.name === 'delete') {
                    return {
                        type: 'react',
                        element: (
                            <Column>
                                <button className='button red' onClick={removeImageHandler}>
                                    Delete
                                </button>
                            </Column>
                        )
                    };
                }
                return component;
            })
        });
    }, [formSchema]);

    return customSchema ? 
        <FormHelper schema={customSchema} onSubmit={onSubmit} onCustomPayload={onCustomPayload} parentRef={parentRef} />
        : null;
};

export default PropertyEdit;
