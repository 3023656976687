import { pushNotification, Timeline, TimelineDetail, utils } from '@truenorthmortgage/olympus';
import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SERVICES } from '../../../services';
import { DealHeader as DealHeaderModel } from '../../../models/deals/header';
import { DealService } from '../../../services/deal.service';
import DealHeader from '../../../components/deal-header/deal-header.component';
import { FundingData } from '../../../models/deals/funding';
import TableHelper from '../../../components/table-helper/table-helper.component';
import { CorrespondenceItemData } from '../../../models/deals/notes';
import CorrespondenceItem from '../../../components/correspondence-item/correspondence-item.component';
import AccordionHelper from '../../../components/accordion-helper/accordion-helper.component';
import { zip } from 'rxjs';

export interface DealFundingProps { }

const DealFunding: FC<DealFundingProps> = () => {
    const dealService = utils.injection.useInjection<DealService>(SERVICES.DealService);
    const dispatch = useDispatch();
    const [dealHeader, setDealHeader] = useState<DealHeaderModel | null>(null);
    const [funding, setFunding] = useState<FundingData | null>(null);
    const [reload, setReload] = useState<boolean>(false);
    const params = utils.nav.useAllPossibleParams();

    useEffect(() => {
        if (params.deal_id) {
            const subscription = zip(
                dealService.getHeader(params.deal_id),
                dealService.getFunding(params.deal_id)
            ).subscribe({
                next: ([dealHeader, funding]) => {
                    setDealHeader(dealHeader);
                    setFunding(funding);
                },
                error: e => {
                    dispatch(pushNotification({ class: 'error', message: e.toString() }));
                }
            });

            return () => {
                subscription.unsubscribe();
            };
        }
    }, [params.deal_id, reload, dealService, dispatch]);
    const timelineData = funding ? funding.funderCorrespondenceTimeline : null;
    const itemButtons = useMemo(() => timelineData ? dealService.getTimelineItemButtons(timelineData) : null, [dealService, timelineData]);

    return dealHeader ? (
        <>
            <DealHeader header={dealHeader} />
            {funding && funding.funderDealAccordion ?
                <AccordionHelper accordion={funding.funderDealAccordion} onModalClose={() => setReload(prev => !prev)} />
                : null}
            {timelineData ?
                <Timeline title={timelineData.title} categoryLabel={timelineData.category_label} bordered={timelineData.settings.border}>
                    {timelineData.timeline_items.map((item: CorrespondenceItemData, index: number) => (
                        <CorrespondenceItem
                            key={index}
                            correspondenceHeading={item.correspondence_heading}
                            correspondenceHeadingClasses={item.correspondence_heading_classes}
                            actionButtons={itemButtons![index]}
                            category={item.category}
                            dateTime={new Date(item.datetime)}
                            icon={item.icon}
                            headline={item.headline}
                            expanded={item.details.expanded}>
                            <TimelineDetail copy={item.details.copy} raw={true} />
                        </CorrespondenceItem>
                    ))}
                </Timeline>
                : null}
            {funding !== null && funding.funderDealTasksTable ?
                <TableHelper schema={funding.funderDealTasksTable} includeHeader={true} className={funding!.funderDealTasksTable.border ? 'bordered' : ''} />
                : null}
        </>
    ) : null;
};

export default DealFunding;
