import 'reflect-metadata';
import React from 'react';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore, utils, routerDOM, InversifyProvider, IntlProvider } from '@truenorthmortgage/olympus';
import { Container } from 'inversify';
import { AuthService } from './services/auth.service';
import { SERVICES } from './services';
import { DealService } from './services/deal.service';
import { faArrowLeft, faBell, faEdit, faDownload, faCheck, faExclamationTriangle, faInbox, faStar, faReply, faComment, faComments, faArrowCircleRight, faGavel, faHome, faKey, faLock, faMagic, faShare, faSignOutAlt, faStickyNote, faTasks, faTimes, faUsers, faEye, faEyeSlash, faCog, faCloudDownloadAlt, faCircle } from '@fortawesome/free-solid-svg-icons';
import { Icon, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import translations from './tranlations';
import { LoginService } from './services/login-page.service';
import { UnderwritingService } from './services/underwriting.service';
import { CloserDealsService } from './services/closer-deals.service';
import { FunderService } from './services/funder.service';
import { AdminService } from './services/admin.service';
import { UsersService } from './services/users.service';
import { SubmitToThinkService } from './services/submit-to-think.service';
import { ModalService } from './services/modal.service';
import sessionReducer from './reducers/session.reducer';
import { HttpService } from './services/http.service';
import RollbarProvider from './providers/RollbarProdivder.component';
import { ComponentService } from './services/component.service';
import { DocumentSpecialistService } from './services/document-specialist.service';
import { NotificationService } from './services/notification.service';
import { createRoot } from 'react-dom/client';
import { ReportService } from './services/report.service';
import { ActionService } from './services/action.service';
import { CesService } from './services/ces.service';
import { CommitmentService } from './services/commitment.service';
import { VerbalerService } from './services/verbaler.service';

declare global {
  interface Window {
    REACT_APP_SITE_URL_ZEUS: string;
  }
}

const faCommentO = Object.assign({}, faComment, { iconName: 'comment-o' });
const faCommentsO = Object.assign({}, faComments, { iconName: 'comments-o' });
const faCloudDownload = Object.assign({}, faCloudDownloadAlt, { iconName: 'cloud-download' });

utils.font.setFontAwesome([
    faCheck as IconDefinition,
    faTimes as IconDefinition,
    faSignOutAlt as IconDefinition,
    faGavel as IconDefinition,
    faArrowLeft as IconDefinition,
    faDownload as IconDefinition,
    faExclamationTriangle as IconDefinition,
    faHome as IconDefinition,
    faEdit as IconDefinition,
    faMagic as IconDefinition,
    faTasks as IconDefinition,
    faStickyNote as IconDefinition,
    faComment as IconDefinition,
    faCommentO as IconDefinition,
    faComments as IconDefinition,
    faCommentsO as IconDefinition,
    faArrowCircleRight as IconDefinition,
    faUsers as IconDefinition,
    faStar as IconDefinition,
    faKey as IconDefinition,
    faLock as IconDefinition,
    faShare as IconDefinition,
    faBell as IconDefinition,
    faInbox as IconDefinition,
    faReply as IconDefinition,
    faEye as IconDefinition,
    faEyeSlash as IconDefinition,
    faCog as IconDefinition,
    faCloudDownload as IconDefinition,
    faCircle as IconDefinition
]);

const container = new Container();
container.bind<HttpService>(SERVICES.HttpService).to(HttpService).inSingletonScope();
container.bind<AuthService>(SERVICES.AuthService).to(AuthService).inSingletonScope();
container.bind<ActionService>(SERVICES.ActionService).to(ActionService).inSingletonScope();
container.bind<DealService>(SERVICES.DealService).to(DealService).inSingletonScope();
container.bind<LoginService>(SERVICES.LoginService).to(LoginService).inSingletonScope();
container.bind<UnderwritingService>(SERVICES.UnderwritingService).to(UnderwritingService).inSingletonScope();
container.bind<ReportService>(SERVICES.ReportService).to(ReportService).inSingletonScope();
container.bind<CloserDealsService>(SERVICES.CloserDealsService).to(CloserDealsService).inSingletonScope();
container.bind<FunderService>(SERVICES.FunderService).to(FunderService).inSingletonScope();
container.bind<AdminService>(SERVICES.AdminService).to(AdminService).inSingletonScope();
container.bind<UsersService>(SERVICES.UsersService).to(UsersService).inSingletonScope();
container.bind<CommitmentService>(SERVICES.CommitmentService).to(CommitmentService).inSingletonScope();
container.bind<SubmitToThinkService>(SERVICES.SubmitToThinkService).to(SubmitToThinkService).inSingletonScope();
container.bind<ModalService>(SERVICES.ModalService).to(ModalService).inSingletonScope();
container.bind<ComponentService>(SERVICES.ComponentService).to(ComponentService).inSingletonScope();
container.bind<DocumentSpecialistService>(SERVICES.DocumentSpecialistService).to(DocumentSpecialistService).inSingletonScope();
container.bind<NotificationService>(SERVICES.NotificationService).to(NotificationService).inSingletonScope();
container.bind<CesService>(SERVICES.CesService).to(CesService).inSingletonScope();
container.bind<VerbalerService>(SERVICES.VerbalerService).to(VerbalerService).inSingletonScope();

const store = createStore({ session: sessionReducer });

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!); // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
        <routerDOM.HashRouter>
            <InversifyProvider container={container}>
                <Provider store={store}>
                    <IntlProvider messages={translations}>
                        <RollbarProvider>
                            <App />
                        </RollbarProvider>
                    </IntlProvider>
                </Provider>
            </InversifyProvider>
        </routerDOM.HashRouter>
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
