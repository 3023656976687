import { ActionButton, ActionButtons, ModalActionButton, utils } from '@truenorthmortgage/olympus';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FC, useEffect, useState } from 'react';
import { ButtonComponent, FormSchema } from '../../models/schemas/form-schema';
import { SERVICES } from '../../services';
import { HttpService } from '../../services/http.service';
import ContainerModal from '../container-modal/container-modal.component';
import { ComponentService } from '../../services/component.service';
import { useDispatch } from 'react-redux';
import { ActionButton as ActionButtonType } from '../../models/accordions/action-button';

export interface ActionButtonsHelperProps {
    schema: ButtonComponent[] | ActionButtonType[];
    onModalClose?: (data?: any) => void
    classes?: string;
}

const ActionButtonsHelper: FC<ActionButtonsHelperProps> = ({schema, onModalClose, classes}) => {
    const httpService = utils.injection.useInjection<HttpService>(SERVICES.HttpService);
    const componentService = utils.injection.useInjection<ComponentService>(SERVICES.ComponentService);
    const dispatch = useDispatch();
    const [callbacks, setCallbacks] = useState<Array< (() => void)>>([]);

    // callback binding
    useEffect(() => {
        setCallbacks(
            schema.map((button) => {
                if (button.classes?.includes('link-trigger')) {
                    return () => {
                        componentService.executeLinkTriggerClick(button.data_array && 'uri' in button.data_array ? button.data_array.uri : '', dispatch, onModalClose);
                    };
                }
                return () => {return;};
            })
        );
    }, [schema, setCallbacks]);

    return (
        // allows for styling of container too
        <ActionButtons className={classes ?? ''}>
            {schema.map((button, index) => button.modal ? (
                <ModalActionButton
                    key={index}
                    icon={button.icon ?? undefined}
                    text={button.label}
                    disabled={button.disabled ?? false}
                    className={button.class ?? (Array.isArray(button.classes) ? button.classes?.join(' ') : button.classes)} 
                    modalArgs={button.uri ? {
                        loadProps: async () => ({
                            data: await httpService.fetchJson<FormSchema>(button.uri!)
                        }),
                        onClose: onModalClose
                    } : undefined}>
                    <ContainerModal />
                </ModalActionButton>
            ) : (
                button.download ? (<ActionButton
                    key={index}
                    icon={button.icon as IconName}
                    callback={async () => {
                        const response = await httpService.fetchJson<any>(button.uri as string);
                        componentService.createDownload(response.__blob_data, button.data_array && 'filename' in button.data_array ? button.data_array?.filename : '');
                    }}
                    className={button.class ?? (Array.isArray(button.classes) ? button.classes?.join(' ') : button.classes)}
                >
                    {button.label}
                </ActionButton>) :
                    ( <ActionButton
                        key={index}
                        icon={button.icon as IconName}
                        disabled={button.disabled ?? false}
                        className={button.class ?? (Array.isArray(button.classes) ? button.classes?.join(' ') : button.classes)} 
                        redirectTo={button.uri}
                        openNewTab={button.new_window}
                        callback={callbacks[index]}>
                        {button.label}
                    </ActionButton>)
            ))}
        </ActionButtons>
    );
};

export default ActionButtonsHelper;
