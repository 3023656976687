import { pushNotification, utils } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SERVICES } from '../../services';
import { useNavigate } from 'react-router';
import { FormSchema, SelectComponent } from '../../models/schemas/form-schema';
import { CommitmentService } from '../../services/commitment.service';
import FormHelper from '../../components/form-helper/form-helper.component';
import ComponentHelper from '../../components/component-helper/component-helper.component';
import { zip } from 'rxjs';

const Commitment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loanType, setLoanType] = useState<string>('none');
    const [template, setTemplate] = useState<string>('fixed-commitment-letter-en');
    const [loanTypeComponent, setLoanTypeComponent] = useState<SelectComponent | null>(null);
    const [templateComponent, setTemplateComponent] = useState<SelectComponent | null>(null);
    const [commitmentForm, setCommitmentForm] = useState<FormSchema | null>(null);
    const commitmentService = utils.injection.useInjection<CommitmentService>(SERVICES.CommitmentService);

    const loadData = useCallback(() => {
        const subscription = zip(
            commitmentService.getCommitmentForm(template, loanType),
            commitmentService.getLoanTypeComponent(loanType),
            commitmentService.getTemplateComponent(template)
        ).subscribe({
            next: ([commitmentForm, loanTypeComponent, templateComponent]) => {
                setCommitmentForm(commitmentForm);
                setLoanTypeComponent(loanTypeComponent);
                setTemplateComponent(templateComponent);
            },
            error: (e) => {
                dispatch(
                    pushNotification({ class: 'error', message: e.toString() })
                );
            },
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [
        dispatch, setCommitmentForm, commitmentService, loanType, template, setLoanType, setTemplate
    ]);

    useEffect(loadData, [loadData]);

    const onSubmit = useCallback(({ url }: { url: string }) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }, [navigate]);

    const handleNewTemplate = useCallback((new_template: string | null) => {
        // empty means general commitment
        if (new_template || new_template === '') {
            setTemplate(new_template);
        }
    }, []);

    const handleNewLoanType = useCallback((new_loan_type: string | null) => {
        if (new_loan_type) {
            setLoanType(new_loan_type);
        }
    }, []);

    return commitmentForm && loanTypeComponent && templateComponent ? (
        <>
            <div style={{ padding: '16px', display: 'flex' }}>
                <div className='widget no-margin no-padding'>
                    <ComponentHelper schema={templateComponent} onChange={handleNewTemplate} />
                </div>
                <div className='widget no-margin no-padding'>
                    <ComponentHelper schema={loanTypeComponent} onChange={handleNewLoanType} />
                </div>
            </div>
            <div className='widget'>
                <FormHelper schema={commitmentForm} onSubmit={onSubmit} />
            </div>
        </>
    ) : null;
};

export default Commitment;
