import { Observable } from 'rxjs';
import { HttpService  } from './http.service';
import { FormSchema, SelectComponent} from '../models/schemas/form-schema';

export class CommitmentService extends HttpService {
    getCommitmentForm(template: string, loan_type: string): Observable<FormSchema> {
        return this.fetchJsonObservable<FormSchema>('/manual-commitment/commitment?template=' + template + '&loan_type=' + loan_type);
    }

    getLoanTypeComponent(loan_type: string): Observable<SelectComponent> {
        return this.fetchJsonObservable<SelectComponent>('/manual-commitment/loan-type?loan_type=' + loan_type);
    }

    getTemplateComponent(template: string): Observable<SelectComponent> {
        return this.fetchJsonObservable<SelectComponent>('/manual-commitment/template?template=' + template);
    }
}
