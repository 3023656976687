import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { utils } from '@truenorthmortgage/olympus';
import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Navigate, useLocation } from 'react-router';
import logo from '../../resources/images/think-logo-stacked.svg';
import { SERVICES } from '../../services';
import { AuthService } from '../../services/auth.service';
import { UsersService } from '../../services/users.service';

export interface Login2faProps {
    options: Record<string, any>;
    token: string;
    onLogout: () => void;
}

const Login2fa: FC<Login2faProps> = ({ options, token, onLogout }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [errors, setErrors] = useState<string[]>();
    const userService = utils.injection.useInjection<UsersService>(SERVICES.UsersService);
    const authService = utils.injection.useInjection<AuthService>(SERVICES.AuthService);
    const dispatch = useDispatch();
    const location = useLocation();

    const onClick = useCallback(() => {
        userService.post2faAuth(options, token)
            .then(result => {
                if (result) {
                    authService.logIn(token, dispatch);
                    setLoggedIn(true);
                }
            })
            .catch(e => {
                setErrors([e.toString()]);
            });
    }, [userService, options]);

    return !loggedIn ? (
        <div id="wrapper">
            <div id="login">
                <div className="login-inner">
                    <div className="columns">
                        <div className="column">
                            <img src={logo} alt="Logo" className="img-responsive" />
                        </div>
                        {errors ? errors.map((error, index) => (
                            <p className="column" key={index}>{error}</p>
                        )) : null}
                        <div className="column">
                            <div id="logo-2fa">
                                <span className="fa-stack fa-3x">
                                    <i className="fa-stack-2x">
                                        <FontAwesomeIcon icon="circle" />
                                    </i>
                                    <i className="fa-stack-1x fa-inverse">
                                        <FontAwesomeIcon icon="lock" />
                                    </i>
                                </span>
                            </div>  
                        </div>
                        <div className="column">
                            <p>
                                <FormattedMessage id="When you have your security key ready, press the button below to verify your login." />
                            </p>
                        </div>

                        <div className="column">
                            <div className="submit">
                                <button type="submit" className="button primary full-width" autoFocus onClick={onClick}>
                                    <FormattedMessage id="I'm Ready" />
                                </button>
                            </div>
                        </div>

                        <div className="column">
                            <p style={{ textAlign: 'center' }}>
                                <button type="submit" className="button full-width" onClick={onLogout}>
                                    <FormattedMessage id="Logout" />
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (<Navigate to="/" />);
};

export default Login2fa;
