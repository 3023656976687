import {useState, useEffect} from 'react';
import { TextComponent } from '@truenorthmortgage/olympus';

type FrequencyProps = {
    frequency: number | undefined;
    onBlur: (val: any) => any;
};

export function Frequency({frequency, onBlur}: FrequencyProps) {
    const [localFrequency, setLocalFrequency] = useState<string | undefined>(frequency?.toString());

    useEffect(() => {
        if (localFrequency) {
            let freq: number;
            if (localFrequency.toLowerCase() === 'sm' || localFrequency.toLowerCase() === 'semi-monthly') {
                freq = 24;
            } else if (localFrequency.toLowerCase() === 'bw' || localFrequency.toLowerCase() === 'bi-weekly') {
                freq = 26;
            } else if (localFrequency.toLowerCase() === 'm' || localFrequency.toLowerCase() === 'monthly') {
                freq = 12;
            } else if (localFrequency.toLowerCase() === 'y' || localFrequency.toLowerCase() === 'anually' || localFrequency.toLowerCase() === 'yearly') {
                freq = 1;
            } else if (localFrequency.toLowerCase() === 'w' || localFrequency.toLowerCase() === 'weekly') {
                freq = 52;
            } else {
                freq = parseFloat(localFrequency);
            }
            onBlur(freq);
        }    
    }, [localFrequency]);

    return (
        <div>
            <label>Pay Frequency: </label>
            <TextComponent placeholder='sm/semi-monthly=24 | bw/bi-weekly=26 | m/monthly=12 | y/yearly/anually=1 | w/weekly=52 | or enter number' formData={localFrequency} onBlur={(val) => setLocalFrequency(val ?? '')} />
        </div>
    );
}
