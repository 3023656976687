import { ActionButton, TextComponent, utils } from '@truenorthmortgage/olympus';
import DropdownSearch, { DropdownData } from './dropdown-search.component';
import { Card } from './card.component';
import { useCallback, useEffect, useState } from 'react';
import { VerbalerService } from '../../services/verbaler.service';
import { SERVICES } from '../../services';

interface VerbalCompanyCardProps {
    configData: CompanyInfo;
    companyData: CompanyData[];
    emailInfo: EmailInfo;
    helpers: CallableFunction[];
    errors: string[];
}

export interface CompanyInfo {
    company_name: string | null;
    company_website: string | null;
    company_phone: string | null;
    company_email: string | null;
    company_verifier: string | null;
    company_verifier_position: string | null;
    company_directory: string | null;
    company_extension: string | null;
    company_notes: string | null;
    [key: string]: CompanyInfo[keyof CompanyInfo];
}

interface EmailInfo {
    loan_number: number;
    client_name: string | null;
}

export interface CompanyData {
    uuid: string;
    name: string;
    consent: number;
    allows_verbal: number;
    consent_notes: string;
}

function validatePhoneNumber(enteredNumber: string | number | null | undefined) {
    if (!enteredNumber) return false;

    enteredNumber = enteredNumber.toString().replace(/\D/g,'');
    const matches = enteredNumber.match(
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
    );
    return matches ? matches.slice(1).join('') : false;
}

function validateEmail(email: string | null | undefined) {
    if (!email) return false;

    const matches = email.match(
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|.('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
    return matches != null;
}

const VerbalCompanyCard = ({
    configData: companyInfo,
    companyData: companyData,
    emailInfo: emailInfo,
    helpers: [createUpdatedInfo],
    errors: errors,
}: VerbalCompanyCardProps) => {
    const verbalerService = utils.injection.useInjection<VerbalerService>(SERVICES.VerbalerService);

    const [companyNamesData, setCompanyNamesData] = useState<DropdownData>([{}] as DropdownData);
    const [isValidEmail, setIsValidEmail] = useState<boolean>(validateEmail(companyInfo.company_email ?? ''));
    const [isValidPhone, setIsValidPhone] = useState<boolean>(Boolean(validatePhoneNumber(companyInfo.company_phone ?? '')));
    const [mailtoLink, setMailtoLink] = useState<string>('');

    const updatePhoneNumber = (number: string | null | undefined) => {
        const phoneNumber = validatePhoneNumber(number);
        setIsValidPhone(Boolean(phoneNumber));
        createUpdatedInfo(phoneNumber, 'company_info', 'company_phone');
        return;
    };

    const updateEmail = (email: string | null | undefined) => {
        const localIsValidEmail = validateEmail(email);
        createUpdatedInfo(email, 'company_info', 'company_email');
        setIsValidEmail(localIsValidEmail);
        return;
    };

    useEffect(() => {
        const filteredCompanyNamesData = companyData.map((company) => ({
            uuid: company.uuid,
            consent: company.consent,
            allows_verbal: company.allows_verbal,
            name: company.name,
        }));
        setCompanyNamesData(filteredCompanyNamesData);
    }, [companyData]);

    const populateCompanyInfo = useCallback(async (uuid: string) => {
        createUpdatedInfo(companyInfo, 'company_info', null, false, uuid);
    }, [companyData, companyInfo, createUpdatedInfo]);

    // helps w sites with or without the protocal infront
    const formatWebsite = useCallback(() => {
        const url = companyInfo.company_website ?? '';
        const matches = url.match('^https?://(.+)');
        const formattedUrl = !matches ? `http://${url}` : url;
        return formattedUrl;
    }, [companyInfo.company_website]);

    const searchInfo = (search_content: string | null | undefined) => {
        return `https://www.google.com/search?q=${encodeURIComponent(search_content ?? '')}`;
    };

    useEffect(() => {
        const clientName = emailInfo.client_name ?? '';
        const loanNumber = emailInfo.loan_number ?? 0;
        const email = companyInfo.company_email ?? '';
        verbalerService.getMailtoLink(clientName, loanNumber, email).then((link: string) => setMailtoLink(link));
    }, [emailInfo, companyInfo]);

    return (
        <Card className="verbal-card" id="verbal-template-employer-info">
            <h3>Company Information:</h3>
            <div className="vulcan-parent-div">
                <div className="verbal-input-1b">
                    <label>Company Name:</label>
                    <DropdownSearch
                        initial_value={companyInfo.company_name ?? ''}
                        data={companyNamesData}
                        onItemClick={populateCompanyInfo}
                        helpers={[
                            (val: string) =>
                                createUpdatedInfo(val, 'company_info', 'company_name'),
                        ]}
                    />
                    {companyInfo.company_name && companyInfo.company_name !== '' ? (
                        <ActionButton
                            className="primary"
                            openNewTab
                            redirectTo={searchInfo(companyInfo.company_name)}
                        >
                            Verify Company
                        </ActionButton>
                    ) : (
                        <ActionButton disabled>Verify Company</ActionButton>
                    )}
                </div>
                <div className="verbal-input-1b">
                    <label>Website:</label>
                    <TextComponent
                        formData={companyInfo.company_website ?? ''}
                        onBlur={(val) =>
                            createUpdatedInfo(val, 'company_info', 'company_website')
                        }
                        name="verbal-employer-website"
                    />
                    {companyInfo.company_website && companyInfo.company_website !== '' ? (
                        <ActionButton
                            className="primary"
                            openNewTab
                            redirectTo={formatWebsite()}
                        >
                            Open Website
                        </ActionButton>
                    ) : (
                        <ActionButton disabled>Open Website</ActionButton>
                    )}
                </div>
                <div className="verbal-input-2b">
                    <label>Phone Number:</label>
                    <TextComponent
                        formData={utils.string.prettifyPhoneNumber(
                            companyInfo.company_phone ?? '',
                        )}
                        onBlur={(val) => updatePhoneNumber(val)}
                        name="verbal-employer-number"
                        error={errors.includes('company_phone')}
                    />
                    {isValidPhone ? (
                        <>
                            <ActionButton
                                className="primary"
                                openNewTab
                                redirectTo={searchInfo(companyInfo.company_phone)}
                            >
                                Verify Phone Number
                            </ActionButton>
                            <ActionButton
                                className="primary"
                                redirectTo={`tel::${companyInfo.company_phone}`}
                            >
                                Phone Employer
                            </ActionButton>
                        </>
                    ) : (
                        <>
                            <ActionButton disabled>Verify Phone Number</ActionButton>
                            <ActionButton disabled>Phone Employer</ActionButton>
                        </>
                    )}
                </div>
                <div className="verbal-input-2b">
                    <label>Email:</label>
                    <TextComponent
                        formData={companyInfo.company_email ?? ''}
                        onBlur={(val) => updateEmail(val)}
                        name="verbal-employer-email"
                        error={errors.includes('company_email')}
                    />
                    {isValidEmail ? (
                        <>
                            <ActionButton
                                className="primary"
                                openNewTab
                                redirectTo={searchInfo(companyInfo.company_email)}
                            >
                                Verify Email
                            </ActionButton>
                            {emailInfo.client_name && emailInfo.client_name !== '' ? (
                                <ActionButton
                                    disabled={false}
                                    className="primary"
                                    openNewTab
                                    redirectTo={mailtoLink}
                                >
                                    Email Employer
                                </ActionButton>
                            ) : (
                                <ActionButton disabled>Email Employer</ActionButton>
                            )}
                        </>
                    ) : (
                        <>
                            <ActionButton disabled>Verify Email</ActionButton>
                            <ActionButton disabled>Email Employer</ActionButton>
                        </>
                    )}
                </div>
                <div>
                    <label>Verifier Name:</label>
                    <TextComponent
                        formData={companyInfo.company_verifier ?? ''}
                        onBlur={(val) =>
                            createUpdatedInfo(val, 'company_info', 'company_verifier')
                        }
                        name="verbal-verifier-name"
                    />
                </div>
                <div>
                    <label>Verifier Position:</label>
                    <TextComponent
                        formData={companyInfo.company_verifier_position ?? ''}
                        onBlur={(val) =>
                            createUpdatedInfo(val, 'company_info', 'company_verifier_position')
                        }
                        name="verbal-verifier-position"
                    />
                </div>
                <div>
                    <label>Directory:</label>
                    <TextComponent
                        formData={companyInfo.company_directory ?? ''}
                        onBlur={(val) =>
                            createUpdatedInfo(val, 'company_info', 'company_directory')
                        }
                        name="verbal-company-directory"
                    />
                </div>
                <div>
                    <label>Extension:</label>
                    <TextComponent
                        formData={companyInfo.company_extension ?? ''}
                        onBlur={(val) =>
                            createUpdatedInfo(val, 'company_info', 'company_extension')
                        }
                        name="verbal-company-extension"
                    />
                </div>
                <div>
                    <label>Notes:</label>
                    <TextComponent
                        formData={companyInfo.company_notes ?? ''}
                        onBlur={(val) =>
                            createUpdatedInfo(val, 'company_info', 'company_notes')
                        }
                        name="verbal-company-notes"
                    />
                </div>
            </div>
        </Card>
    );
};

export default VerbalCompanyCard;
