import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { CloserQueueData, DealsQueueData } from '../../models/deals/deals-queue';

export interface CloserQueueProps {
    closer: CloserQueueData[];
    status: DealsQueueData;
}

const CloserQueue: FC<CloserQueueProps> = ({ closer, status }) => {
    return (
        <div className="closer-queue-wrapper">
            <h2>Closer Queue ({status.unassigned_closer_deals} deals in queue)</h2>
            <div className="queue-status">
                <div>Need Review: </div>
                <div>
                    <i><FontAwesomeIcon icon="arrow-left" /></i>
                </div>
                <div className="queue-deals" id="closer-queue">
                    {closer && closer.length > 0 ? (
                        closer.map((closer, index) => (
                            <Link key={index} className={`spaced ${status.tracker_user_id.toString() === closer.user_id ? 'yours' : ''}`}
                                to={closer.user_id || status.click_unassigned_closer_deal ? `/deals/documents?deal_id=${closer.deal_id}` : ''}>
                                {closer.is_premier === '1' ? (<><FontAwesomeIcon icon={faStar} />{' '}</>) : null}
                                {closer.user_initials ? (
                                    <>
                                        {closer.user_initials}
                                        {closer.oldest_document_shared_timestamp_corrected}
                                    </>
                                ) : closer.oldest_document_shared_timestamp_corrected}
                            </Link>
                        ))
                    ) : (
                        <span className="empty">Queue Empty</span>
                    )}
                </div>
            </div>
        </div>


    );
};

export default CloserQueue;
