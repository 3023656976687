import { FieldGroupComponent, ActionButton, pushNotification, utils, Widget } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SelectComponent } from '../../../models/schemas/form-schema';
import { SERVICES } from '../../../services';
import { CesService } from '../../../services/ces.service';
import { TableSchema } from '../../../models/schemas/table-schema';
import TableHelper from '../../../components/table-helper/table-helper.component';
import { FormattedMessage } from 'react-intl';
import { HttpService } from '../../../services/http.service';
import { switchMap, tap, timer } from 'rxjs';

const CesDashboard = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [assignLeadInProgress, setAssignLeadInProgress] = useState<boolean>(false);
    const [reportData, setReportData] = useState<[TableSchema, SelectComponent[] | undefined]>();
    const [leadCount, setLeadCount] = useState<number | null>(null);
    const [intervalId, setIntervalId] = useState<number>(0);
    const dispatch = useDispatch();
    const cesService = utils.injection.useInjection<CesService>(SERVICES.CesService);

    const getLeadCount = useCallback(() => {
        return cesService.getLeadCount().pipe(
            tap({
                next: setLeadCount,
                error: (e) => {
                    dispatch(
                        pushNotification({ class: 'error', message: e.toString() })
                    );
                }
            }));
    }, [leadCount]);
    
    const loadData = useCallback(() => {
        const refetchSubscription = HttpService.subscribe(timer(0, 60000).pipe(switchMap(getLeadCount)), dispatch);
        const subscription = cesService.getDeals().subscribe({
            next: (data) => {
                setReportData(data);
                setIsLoading(false);
            },
            error: (e) => {
                setIsLoading(false);
                dispatch(
                    pushNotification({ class: 'error', message: e.toString() })
                );
            }
        });

        return () => {
            subscription.unsubscribe();
            refetchSubscription.unsubscribe();
        };
    }, [cesService, setReportData, dispatch]);

    useEffect(loadData, [loadData]);

    const onSubmit = useCallback(() => {
        setAssignLeadInProgress(true);
        cesService.assignDeal().subscribe({
            next: setReportData,
            error: (e) => {
                dispatch(
                    pushNotification({ class: 'error', message: e.toString() })
                );
            }
        });
    }, [cesService]);

    const reloadData = useCallback((data?: {submit: boolean}) => {
        if (data?.submit) {
            setReportData(undefined);
            setAssignLeadInProgress(false);
        }
    }, [loadData]);

    const getContent = useCallback(() => {
        if (isLoading) {
            return null;
        } else {
            if (reportData) {
                return (
                    <div id="results">
                        <TableHelper schema={reportData[0]} headerless={true} onModalClose={reloadData} />
                        {
                            reportData?.[1] ? <TableHelper schema={reportData?.[1] as any} headerless={true} onModalClose={reloadData} /> : null
                        }
                    </div>
                );
            } else {
                return (
                    <Widget>
                        <FieldGroupComponent>
                            <div className="column third buttons">
                                <ActionButton className="button primary form-trigger" callback={onSubmit} disabled={assignLeadInProgress}>
                                    <FormattedMessage id={`Grab a Lead (In Queue:${leadCount || ''})`} />
                                </ActionButton>
                            </div>
                        </FieldGroupComponent>
                    </Widget>
                );
            }
        }
    },[reportData, isLoading, assignLeadInProgress, leadCount]);

    return (
        <>
            <div id="results-container">
                {getContent()}
            </div>
        </>
    );
};

export default CesDashboard;

